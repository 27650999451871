import { useMemo } from 'react'
import { useAppStore } from '@/stores/app'

export const useLangHelp = () => {
  const language = useAppStore((state) => state.language)
  /**
   * 前端的语言key转换成后端的语言key
   * @returns
   */
  const switchLangKey = (lang: string) => {
    if (lang === 'zh-CN') {
      return 'CN'
    } else if (lang === 'ja-JP') {
      return 'JA'
    } else if (lang === 'en-US') {
      return 'EN'
    }
  }

  // 后端的语言key
  const backLangKey = useMemo(() => {
    return switchLangKey(language)
  }, [])

  return {
    switchLangKey,
    backLangKey
  }
}
