import { useState, useEffect, useRef, memo } from 'react'
import { cn, getScrollWidth } from '@/lib/utils'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { track } from '@/hooks/useTrack'
import Config from '@/config/base'
import { Layout } from 'antd'
import { useTranslation } from '@/lib/locales/client'
import { useRouter, usePathname } from 'next/navigation'
import styles from '../_common_css/header.module.scss'
import { useShallow } from 'zustand/react/shallow'
// *com
import AvatarPopover from '@/components/layout/desktop/header/avatarPopover'
import HeaderAccountMoney from './accountMoney'
import HeaderNav from './headerNav'
const { Header } = Layout

const PCHeader = () => {
  const { t } = useTranslation()
  const { globalPublickKey, language, updateCmsTemplate, setIsShowAddToHome } = useAppStore(
    useShallow((state) => ({
      language: state.language,
      globalPublickKey: state.globalPublickKey,
      updateCmsTemplate: state.updateCmsTemplate,
      setIsShowAddToHome: state.setIsShowAddToHome
      // setIsShowSideNav: state.setIsShowSideNav
    }))
  )
  const isLogin = useUserStore((state) => state.isLogin)
  const { openLogin, openRegister, logRegVisible, logRegType } = useAuthStore(
    useShallow((state) => ({
      openLogin: state.openLogin,
      openRegister: state.openRegister,
      logRegVisible: state.logRegVisible,
      logRegType: state.logRegType
    }))
  )
  const router = useRouter()
  const pathName = usePathname()
  const rawPath = pathName.replace(/en|ja(\/)?/g, '')
  const scrollWidth = useRef(0)
  const [logRegActive, setLogRegActive] = useState(false)
  const observerClass = () => {
    let targetNode = document.getElementsByTagName('body')[0]
    let config = { attributes: true }
    let observer = new MutationObserver(() => {
      if (targetNode.className.indexOf('ant-scrolling-effect') > -1) {
        setLogRegActive(true)
      } else {
        setLogRegActive(false)
      }
    })
    observer.observe(targetNode, config)
  }

  useEffect(() => {
    scrollWidth.current = getScrollWidth()
    observerClass()
  }, [])

  // const [headerBgData, setHeaderBgData] = useState([])
  const [headerBgUrl, setHeaderBgUrl] = useState('')
  const [headerLogoBgUrl, setHeaderLogoBgUrl] = useState('')
  const [redirectLink, setRedirectLink] = useState<any>('/')

  function onGetCMSData() {
    let params = {
      moduleCodeList: ['headerBg'],
      themeCode: Config.themeCode
    }
    return updateCmsTemplate(params).then((data) => {
      // console.log('读取首页数据', data)
      const dataList = data.filter((item) => item.moduleCode === 'headerBg')
      const filterList = dataList[0] || {}
      // 待观察 换了写法
      // setHeaderBgData(data.filter((item) => item.moduleCode === 'headerBg'))
      // const arr = headerBgData[0]
      // arr &&
      //   arr.mediaLibrary.forEach((el) => {
      //     if (el.resourceType === 1) {
      //       setHeaderBgUrl(el.fullFilePath)
      //     } else if (el.resourceType === 2) {
      //       setHeaderLogoBgUrl(el.fullFilePath)
      //     }
      //   })
      for (let arrItem of filterList?.mediaLibrary) {
        if (arrItem.resourceType === 1) {
          setHeaderBgUrl(arrItem.fullFilePath)
        } else if (arrItem.resourceType === 2) {
          setHeaderLogoBgUrl(arrItem.fullFilePath)
        }
      }
    })
  }
  const onOpenAddToHome = () => {
    setIsShowAddToHome(true)
  }

  useEffect(() => {
    if (globalPublickKey) {
      onGetCMSData()
    }
  }, [globalPublickKey])

  const openDesopit = () => {
    track('RechargeClick')
    router.push('/account/deposit')
  }

  const handleClick = (type) => {
    if (type === 'login') {
      openLogin()
    } else {
      openRegister(true)
    }
  }

  useEffect(() => {
    if (logRegVisible) {
      try {
        let link = rawPath // route.matched[0].path  // ---  待处理
        if (link.includes(':id')) {
          setRedirectLink(rawPath)
        } else {
          setRedirectLink(link !== '/register' && link !== '/accountDisabled' ? link : '/')
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setRedirectLink(rawPath)

      // --- 待处理
      //  const redirect = _this.$route.query.redirect
      // if(redirect) {
      // _this.$router.push({
      //   path: redirect,
      //   query: {
      //     ..._this.$route.query,
      //     redirect: undefined
      //   }
      // })
      // } else {
      //   router.push(redirectLink)
      // }
    }
  }, [logRegVisible])

  // 大坑 浪费我一下午 待处理，看什么场景下会使用
  // useEffect(() => {
  //   if (logRegType === 'login') {
  //     // console.log('登录??', logRegType, redirectLink)
  //     router.push(redirectLink)
  //   }
  // }, [logRegType])

  {
    /* {!$route.meta.hideHeader && <Header />} 有些页面读取header有hide值时，隐藏header*/
  }
  return (
    <Header
      className={styles['header_box']}
      // className={cn(
      //   'header_box',
      //   styles[isBigScreen ? 'pc' : 'h5'],
      //   styles['new-version-head'],
      //   'fixed top-0 z-[999] w-full backdrop-blur-[50px]'
      // )}
      style={{
        width: logRegActive ? `calc(100% - ${scrollWidth.current}px)` : '100%'
      }}
    >
      <div
        className={cn('pc_header_box', isLogin && 'login', language)}
        style={{
          backgroundImage: 'url(' + headerBgUrl + ')',
          backgroundSize: '100% 60px',
          backgroundRepeat: 'no-repeat'
        }}
      >
        {headerLogoBgUrl ? (
          <div
            className={cn('logo', language)}
            onClick={() => router.push('/')}
            style={{
              backgroundImage: 'url(' + headerLogoBgUrl + ')',
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat'
            }}
          />
        ) : (
          <div className={cn('logo', language)} onClick={() => router.push('/')} />
        )}
        <nav className={'pc_header_left_box'}>
          <HeaderNav />
          {/* <GameProvideSearch className={'game-provide-search'} />
          </HeaderNav> */}
        </nav>
        <div className={'header-auth-model'}>
          {!isLogin ? (
            <>
              <img
                src="/static/images/header/icon_none.png"
                alt=""
                className={'dowload-icon'}
                onClick={onOpenAddToHome}
              />
              <div className={'btn-pc-warp'}>
                <a onClick={() => handleClick('register')}>
                  <span>{t('user.uRegister')}</span>
                </a>
                <a onClick={() => handleClick('login')}>
                  <span>{t('user.uLogin')}</span>
                </a>
              </div>
            </>
          ) : (
            <div className={'logined-modal'}>
              <img
                src="/static/images/header/icon_none.png"
                alt=""
                className={'dowload-icon'}
                onClick={onOpenAddToHome}
              />
              {isLogin && rawPath !== '/account' && <HeaderAccountMoney />}
              <div onClick={openDesopit} className={'to-deposit'}>
                {t('user.headUser.toDeposit')}
              </div>
              <AvatarPopover />
            </div>
          )}
        </div>
      </div>
    </Header>
  )
}

export default memo(PCHeader)
