'use client'
import { memo, ReactNode } from 'react'
import './layout.scss'
import { useAppStore } from '@/stores/app'
/** com */
import Desktop from './desktop'
import Mobile from './mobile'

const QCLayout = ({ deviceType, children }: { deviceType: string; children: ReactNode }) => {
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  console.log('deivce?', isBigScreen, deviceType)
  return <>{deviceType === 'desktop' ? <Desktop>{children} </Desktop> : <Mobile>{children} </Mobile>}</>
  // return <>{isBigScreen ? <Desktop>{children} </Desktop> : <Mobile>{children} </Mobile>}</>
}

export default memo(QCLayout)
