import { useState, FC, useEffect, memo } from 'react'
import { Modal } from 'antd'
import { useTranslation } from '@/lib/locales/client'
import { px2Scalepx } from '@/hooks/useRem'
import styles from './_common_css/lost2faPopup.module.scss'

type IProps = {
  updateValue: (val: boolean) => void
  visible: boolean // false
}

const Lost2Fa: FC<IProps> = (props) => {
  const { t } = useTranslation()
  // const [dialogFlag, setDialogFlag] = useState(false)
  // const emits = defineEmits(["update:visible"])
  useEffect(() => {
    // props.updateValue(props.visible)
  }, [props.visible])

  function contactCS() {
    window.Intercom('show')
  }

  return (
    <Modal
      open={props.visible}
      centered
      footer={null}
      className={styles['lost2faPopup']}
      keyboard={false}
      width={px2Scalepx(360) + 'px'} // 待处理
      z-index="5001"
    >
      <h4>{t('user.lostPop.phone_register_8')}</h4>
      <ul className="items">
        <li>1. {t('user.lostPop.phone_register_11')}</li>
        <li>2. {t('user.lostPop.phone_register_12')}</li>
      </ul>
      <p className="contactUs">
        {t('user.lostPop.phone_register_13')}
        <strong className="u-font-1" onClick={contactCS}>
          {t('user.lostPop.phone_register_30')}
        </strong>
        {t('user.lostPop.phone_register_31')}
      </p>
    </Modal>
  )
}

export default memo(Lost2Fa)
