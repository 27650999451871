import { useState, useEffect, useMemo, useLayoutEffect, useRef, memo } from 'react'
import { useClickAway } from 'ahooks'
import { cn, formatTime, getCurrencySymbol, decimalFormat, isNullObject, getBonusName } from '@/lib/utils'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { useTranslation } from '@/lib/locales/client'
import { Switch, Popover } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { getExchangeRate } from '@/api/wallet'
import { CSSTransition } from 'react-transition-group'
import styles from '../_common_css/accountMoney.module.scss'
import { useShallow } from 'zustand/react/shallow'
/**com */
import FreeSpin from '@/components/base/notification/freeSpin'

const HeaderAccountMoney = () => {
  const { t } = useTranslation()
  const language = useAppStore((state) => state.language)
  // const { isBigScreen, language } = useAppStore(
  //   useShallow((state) => ({
  //     isBigScreen: state.isBigScreen,
  //     language: state.language
  //   }))
  // )
  const {
    currencyDisplay,
    hideZero,
    currencyRate,
    wallets,
    bonusAndTask,
    userInfo,
    currency,
    freeSpinWallet,
    moneyTypeSelectIndex,
    bonusCouponItem,
    getBonusTaskList,
    SET_CURRENCY,
    SET_FREESPIN_WALLET,
    SET_ACTIVITY_CURRENCY,
    getExchangeRateFn,
    SET_BONUS_COUPON_ITEM,
    SET_CURRENCY_DISPLAY,
    getCurrencyRate,
    SET_HIDE_ZERO,
    SET_MONEY_TYPE_SELECT_INDEX
  } = useUserStore(
    useShallow((state) => ({
      currencyDisplay: state.currencyDisplay,
      hideZero: state.hideZero,
      currencyRate: state.currencyRate,
      wallets: state.wallets,
      bonusAndTask: state.bonusAndTask,
      userInfo: state.userInfo,
      currency: state.currency,
      freeSpinWallet: state.freeSpinWallet,
      moneyTypeSelectIndex: state.moneyTypeSelectIndex,
      bonusCouponItem: state.bonusCouponItem,
      getBonusTaskList: state.getBonusTaskList,
      SET_CURRENCY: state.SET_CURRENCY,
      SET_FREESPIN_WALLET: state.SET_FREESPIN_WALLET,
      SET_ACTIVITY_CURRENCY: state.SET_ACTIVITY_CURRENCY,
      getExchangeRateFn: state.getExchangeRateFn,
      SET_BONUS_COUPON_ITEM: state.SET_BONUS_COUPON_ITEM,
      SET_CURRENCY_DISPLAY: state.SET_CURRENCY_DISPLAY,
      getCurrencyRate: state.getCurrencyRate,
      SET_HIDE_ZERO: state.SET_HIDE_ZERO,
      SET_MONEY_TYPE_SELECT_INDEX: state.SET_MONEY_TYPE_SELECT_INDEX
    }))
  )
  const { currency: currencyType } = currency || {}
  // const [hideZero, setHideZero] = useState(false)
  const [currencySwicth, setCurrencySwicth] = useState('')
  const [isShowOptions, setIsShowOptions] = useState(false)
  const [showCurrencyDisplay, setShowCurrencyDisplay] = useState(currencyDisplay !== '')
  const [showChooseFiat, setShowChooseFiat] = useState(false)

  useEffect(() => {
    // setHideZero(hideZero)
    setCurrencySwicth(currencyDisplay)
  }, [])

  // 待处理
  const walletCurrency = useMemo(() => {
    return moneyTypeSelectIndex === 2 && !isNullObject(freeSpinWallet)
      ? freeSpinWallet
      : moneyTypeSelectIndex === 1 && bonusCouponItem.currency && bonusCouponSummary.length > 0
        ? bonusCouponItem
        : currency
  }, [])

  const openWallet = () => {
    if (!isShowOptions) {
      getBonusTaskList()
    }
    setIsShowOptions(!isShowOptions)
  }

  const bonusCouponSummary = useMemo(() => {
    return bonusAndTask.filter(
      (item) =>
        (item.bonusStatus === 3 || item.bonusStatus === 5 || item.bonusStatus === 10) &&
        Number(item.amount) > 0 &&
        item.bonusType !== 5
    )
  }, [])

  //监听获取 当前币种兑换日元
  useEffect(() => {
    if (currencyType) {
      getCurrencyFn()
    }
  }, [currencyType])

  function chooseFreeSpin(val) {
    SET_CURRENCY(val)
    SET_FREESPIN_WALLET(val)
  }

  function getCurrencyFn() {
    // const { currency } = currency || {}
    if (currency?.currency === 'JPY') return

    getExchangeRate({
      tradeType: '01',
      targetCurrency: 'JPY',
      sourceCurrency: currency?.currency,
      isFindByCache: 0
    }).then((res: any) => {
      const { data } = res.data || {}
      if (res.success && data && data.length > 0) {
        // activityCurrency = data[0].realRate
        SET_ACTIVITY_CURRENCY(data[0].realRate)
      }
    })
  }

  const getDisplayCurrency = (amount, currency) => {
    if (
      currencyDisplay &&
      currency !== 'JPY' &&
      currency !== 'USD' &&
      getRateCurrency(amount, currency) !== false
    ) {
      return (
        getCurrencySymbol(currencyDisplay) + decimalFormat(getRateCurrency(amount, currency), currencyDisplay)
      )
    } else {
      return getCurrencySymbol(currency) + decimalFormat(amount, currency)
    }
  }

  const openDisplay = (flag) => {
    if (flag) {
      setShowChooseFiat(true)
    } else {
      SET_CURRENCY_DISPLAY('')
    }
  }

  const goBack = () => {
    setShowCurrencyDisplay(false)
    setShowChooseFiat(false)
  }
  const chooseFiat = () => {
    if (currencySwicth && currencySwicth.length > 0) {
      SET_CURRENCY_DISPLAY(currencySwicth)
      setIsShowOptions(false)
      setShowChooseFiat(false)
      getCurrencyRate()
    } else {
      goBack()
    }
  }
  const switchHidezero = (flag) => {
    SET_HIDE_ZERO(flag)
  }
  const changeFiatCurrency = (flag) => {
    // currencySwicth.value = flag
    setCurrencySwicth(flag)
  }
  const getRateCurrency = (amount, currency) => {
    if (!currencyRate || !currency) return false
    const item = currencyRate.filter((el) => currency === el.sourceCurrency)
    if (item[0]) {
      return item[0].realRate * amount
    } else {
      return false
    }
  }
  const getImg = (currency) => {
    try {
      return `/static/images/header/wallets/currencies/${currency}.png`
    } catch (error) {
      return ''
    }
  }
  const walletClick = (item) => {
    SET_CURRENCY(item)
    setIsShowOptions(false)
    // 更改顶部币种时读取汇率在首页底部展示;切换币种为JPY时1:1展示；
    getExchangeRateFn({
      tradeType: '01',
      targetCurrency: item.currency === 'JPY' ? 'JPY' : 'USD',
      sourceCurrency: item.currency,
      isFindByCache: 0
    })
  }

  const bonusCouponClick = (item) => {
    SET_BONUS_COUPON_ITEM(item)
    // 原nexttick  --- 待处理
    // useLayoutEffect(() => {
    //   setIsShowOptions(false)
    // }, [])
  }

  // 待处理
  const headerInputRef = useRef<HTMLButtonElement>(null)
  useClickAway(() => {
    setIsShowOptions(false)
  }, headerInputRef)
  //  挂在根标签 ref={headerInputRef}

  // 待处理
  useEffect(() => {
    setTimeout(() => {
      getCurrencyFn()
    }, 1000)
  }, [])

  return (
    <>
      <div
        // className={cn('header-money', isBigScreen ? 'pc' : 'h5')}
        className={styles['header-money']}
        onClick={() => setIsShowOptions(false)}
      >
        <div
          className={cn('header-money-input', 'new-head')}
          // className={cn('header-money-input', isNewVer ? 'new-head' : 'old-head')}
          onClick={openWallet}
        >
          <div className={'currency-warp'}>
            <img
              className={cn(walletCurrency?.currency, 'currency')}
              src={getImg(walletCurrency?.currency) || ''}
            />
          </div>
          <div className={'money'}>
            {getDisplayCurrency(walletCurrency?.amount, walletCurrency?.currency)}
          </div>
          <a className={'money-btn'}></a>
        </div>

        <CSSTransition timeout={300} classNames={'fade'}>
          <>
            {isShowOptions && (
              <div className={'money-box-warp'}>
                {!showChooseFiat && (
                  <div>
                    <div className={'maney-box-content'}>
                      <ul className={'head-tab'}>
                        <li
                          className={cn(moneyTypeSelectIndex === 0 && 'active')}
                          onClick={() => SET_MONEY_TYPE_SELECT_INDEX(0)}
                        >
                          <i></i>
                          <span>{t('home.heard.wallet')}</span>
                        </li>
                        <li
                          className={cn(moneyTypeSelectIndex === 1 && 'active')}
                          onClick={() => SET_MONEY_TYPE_SELECT_INDEX(1)}
                        >
                          <i></i>
                          <span>{t('home.heard.coupon')}</span>
                        </li>
                        <li
                          className={cn(moneyTypeSelectIndex === 2 && 'active')}
                          onClick={() => SET_MONEY_TYPE_SELECT_INDEX(2)}
                        >
                          <i></i>
                          <span>{t('home.heard.freeSqin')}</span>
                        </li>
                      </ul>
                    </div>

                    {moneyTypeSelectIndex === 0 && (
                      <div className={'real-money'}>
                        <div className={cn('money-box', wallets.length > 12 && 'scroll')}>
                          {wallets.map((item, index) => (
                            <>
                              {!(hideZero && Number(item.amount) === 0) && (
                                <div key={index} className={'money-item'} onClick={() => walletClick(item)}>
                                  <div className={'currency-warp'}>
                                    <img
                                      className={cn('currency', item.currency)}
                                      src={getImg(item.currency)}
                                    />
                                    <span>{item.currency}</span>
                                  </div>
                                  <div className={'money'}>
                                    {getDisplayCurrency(item.amount, item.currency)}
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    )}

                    {moneyTypeSelectIndex === 1 && (
                      <div className={'coupon-money'} v-no-result="bonusCouponSummary.length === 0">
                        {bonusCouponSummary.map((item, index) => (
                          <div
                            key={index}
                            className={cn(
                              'voucher',
                              bonusCouponItem && bonusCouponItem.requestId === item.requestId && 'select',
                              bonusCouponSummary.length === 1 && 'oneItem'
                            )}
                            onClick={() => bonusCouponClick(item)}
                          >
                            <div className={'coupon-left'}>
                              <h4 className={'title'}>{getBonusName(item, language)}</h4>
                              <div>
                                <img
                                  className={'currency'}
                                  src={getImg(item.fetchCurrency || item.bonusCurrency)}
                                />
                                <span className={'symbol'}>{item.fetchCurrency || item.bonusCurrency}</span>
                                <span className={'num'}>
                                  {getDisplayCurrency(item.amount, item.fetchCurrency || item.bonusCurrency)}
                                </span>
                              </div>
                            </div>
                            <div className={'coupon-right'}>
                              {Number(item.betAmount) >= 0 && (
                                <div>
                                  <span>
                                    {Math.min(
                                      (Number(item.betAmount) / Number(item.requiredBetAmount)) * 100,
                                      100
                                    ).toFixed(2)}
                                    %
                                  </span>

                                  {item.requestId === bonusCouponItem.requestId && (
                                    <img src="/static/images/header/wallets/yes1.png" />
                                  )}
                                </div>
                              )}

                              <p>
                                {t('home.heard.expire')} {formatTime(item.usedExpiredDate)}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {moneyTypeSelectIndex === 2 && (
                      <div
                        className={'freespin-warp'}
                        v-no-result="
                          (!userInfo.freeSpinWalletList || userInfo.freeSpinWalletList.length === 0) &&
                          freeSpinList.length === 0
                        "
                      >
                        <Popover
                          overlayClassName={'header-sp'}
                          placement="bottomRight"
                          trigger="click"
                          content={
                            <>
                              {userInfo.freeSpinWalletList.map((item) => (
                                <div key={item.currency} onClick={() => chooseFreeSpin(item)}>
                                  <img
                                    className={'currency_icon'}
                                    src={`/static/images/header/wallets/currencies/${item.currency}.png`}
                                  />
                                  <span className={'currency'}>{item.currency}</span>
                                  <span className={'money'}>{decimalFormat(item.amount, item.currency)}</span>
                                </div>
                              ))}
                            </>
                          }
                        >
                          {!isNullObject(freeSpinWallet) && (
                            <div className={'sp_box'}>
                              <img src={'/static/images/header/wallets/sp.png'} />
                              <span className={'txt'}>{t('bonus.wallet')}</span>

                              <img
                                className={'money_icon'}
                                src={`/static/images/header/wallets/currencies/${freeSpinWallet.currency}.png`}
                              />
                              <span className={'money'}>
                                {decimalFormat(freeSpinWallet.amount, freeSpinWallet.currency)}
                              </span>
                              <DownOutlined className={'icon'} />
                            </div>
                          )}
                        </Popover>
                        <FreeSpin className={'scrollbar'} />
                      </div>
                    )}

                    {moneyTypeSelectIndex !== 2 && (
                      <div className={'display-switch'}>
                        <div>
                          {t('home.heard.display')}
                          {currencyDisplay && <span>{currencyDisplay}</span>}
                        </div>
                        <Switch checked={showCurrencyDisplay} onChange={openDisplay} />
                      </div>
                    )}

                    {moneyTypeSelectIndex === 0 && (
                      <div className={'hide-switch'}>
                        <p>{t('home.heard.hide')}</p>
                        <Switch checked={hideZero} onChange={switchHidezero} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            {showChooseFiat && (
              <div className={'choose-fiat-warp'}>
                <div className={'choose-fiat-head'}>
                  <img src="/static/images/header/wallets/down-2.png" onClick={goBack} />
                  <p>{t('home.heard.display')}</p>
                </div>
                <ul>
                  {['JPY', 'USD'].map((item, index) => (
                    <li key={index} onClick={() => changeFiatCurrency(item)}>
                      {item === currencySwicth ? (
                        <img src="/static/images/header/wallets/yes.png" />
                      ) : (
                        <img src="/static/images/header/wallets/no.png" />
                      )}

                      <span>{item}</span>
                      <img src={getImg(item)} />
                    </li>
                  ))}
                </ul>
                <a onClick={chooseFiat}>{t('home.heard.btn')}</a>
                <p style={{ lineHeight: 1.5 }}>{t('home.heard.text')}</p>
              </div>
            )}
          </>
        </CSSTransition>
      </div>
    </>
  )
}

export default memo(HeaderAccountMoney)
