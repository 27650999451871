'use client'
import { useState, useEffect, useMemo, useRef, useLayoutEffect, memo } from 'react'
import { useSetState } from 'ahooks'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { Form } from 'antd'
import Validtor from '@/hooks/validtor'
import html2canvas from 'html2canvas'
/* com */
import BaseDialog from '@/components/base/baseDialog'
import VerifyBaseDialog from '@/components/account/verify/components/VerifyBaseDialog'
import ACInput from '@/components/base/acInput'
import { formatUpdateApi } from '@/api/account'
import { getUserInfo, logApi, newRegEditCallback } from '@/api/auth'
import styles from './_common_css/registerSuccess.module.scss'

// type IProps = {
//   name: any
//   className: string
// }

const RegisterSuccess = () => {
  const { t } = useTranslation()
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  const authStore = useAuthStore()
  const userStore = useUserStore()

  const [form] = Form.useForm()
  const formRef = useRef(null)
  //获取用户信息
  const [userInfo, setUserInfo] = useSetState({
    userNamePwdStatus: 0,
    loginName: '',
    id: ''
  })
  function getUserInfoFn() {
    const webToken = userStore.webToken
    getUserInfo({ loginName: webToken.loginName, searchFreeSpinWallet: true }).then((res) => {
      setUserInfo(res.data)
    })
  }
  /* init */
  const [formState, setFormState] = useSetState({
    username: '',
    password: '',
    rmCode: ''
  })
  const [isShowSave, setIsShowSave] = useState(false)
  const [downLoadImg, setDownLoadImg] = useState(null)
  const [showDownLoad, ShowDownLoad] = useState(false)
  const [isFromThird, setIsFromThird] = useState(false) // 来自三方注册的才提示用户保存图片
  const [recOnlyRead, setRecOnlyRead] = useState(false)

  useEffect(() => {
    getUserInfoFn()
    // --- 待处理 原项目用nextTick
    useLayoutEffect(() => {
      /* 提取　QC　前缀 */
      let { loginName = '', originalPwd, showPwEdit } = authStore.regSucInfo || {}
      setFormState({ username: loginName?.slice(2) })
      if (showPwEdit) {
        setFormState({ password: originalPwd })
        setIsFromThird(true)
      }
      getAgentInfo()
    })
  }, [])

  const btnDisable = useMemo(() => {
    const { username, password } = formState
    let _pw = authStore.regSucInfo.showPwEdit ? Validtor.password(password) : true
    return Validtor.username(username) && _pw
  }, [])

  /* 表单校验 */
  let userName = async (_rule, value) => {
    let _name = formState.username
    if (!_name) {
      return Promise.reject(t('common.validtor.usenameEpty'))
    }
    if (!Validtor.username(_name)) {
      return Promise.reject(t('common.validtor.nameErr'))
    }

    return Promise.resolve()
  }
  let password = async (_rule, value) => {
    let _pw = formState.password
    if (!_pw) {
      return Promise.reject(t('common.validtor.passwordEpty'))
    }
    if (!Validtor.password(_pw)) {
      return Promise.reject(t('common.validtor.passwordError'))
    }
    return Promise.resolve()
  }

  function onClose() {
    authStore.SET_REG_SUC_INFO({ flag: false })
    setIsShowSave(false)
  }
  function handlClose(type = 1) {
    authStore.regSucInfo.flag = false
    localStorage.removeItem('userStore')
    userStore.setWebToken('')
    // if (isFromThird.value) {
    //   /*  */
    //   showSaveImg(type)
    // } else {
    //   authStore.SET_REG_SUC_INFO({ flag: false })
    // setIsShowSave(false)
    //   if (type === 2) {
    //     /* 弹窗关闭-回调后台 */
    //     newRegEditCallback()
    //   }
    // }
  }
  /*  */
  function showSaveImg(type) {
    setIsShowSave(true)
    if (type === 2) {
      /* 弹窗关闭-回调后台 */
      newRegEditCallback()
    }
  }

  // --- 待处理 原项目用nextTick 放在downIMG内
  useLayoutEffect(() => {
    async function getDom() {
      let _dom = downLoadImg.value
      await html2canvas(_dom, {
        logging: false,
        allowTaint: true,
        scale: window.devicePixelRatio,
        scrollY: 0,
        scrollX: 0,
        useCORS: true,
        backgroundColor: '#ffffff'
      }).then(function (canvas) {
        let imgUrl = canvas.toDataURL('responsible/png')
        let tempLink = document.createElement('a') // 创建一个a标签
        tempLink.style.display = 'none'
        tempLink.href = imgUrl
        tempLink.setAttribute('download', 'QC') // 给a标签添加下载属性
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank')
        }
        document.body.appendChild(tempLink) // 将a标签添加到body当中
        tempLink.click() // 启动下载
        document.body.removeChild(tempLink) // 下载完毕删除a标签
        window.URL.revokeObjectURL(imgUrl)
      })
      /* reset */
      ShowDownLoad(false)
      setIsShowSave(false)
      authStore.SET_REG_SUC_INFO({ flag: false })
    }
    getDom()
  }, [])

  /* down-img */
  function downIMG() {
    ShowDownLoad(true)
  }
  function onSubmit() {
    if (!btnDisable) return
    formRef.current.validate().then(() => {
      editInfo()
    })
  }
  async function editInfo() {
    const _name = removeAllSpace(formState.username)
    const _pwd = removeAllSpace(formState.password)

    const res: any = await formatUpdateApi({
      modifyType: Number(userInfo.userNamePwdStatus === 0 ? 5 : 6),
      lastUpdatedBy: userInfo.loginName.toLocaleLowerCase() || '',
      modifyLoginName: 'qc' + _name.toLocaleLowerCase(),
      newPassword: _pwd,
      id: userInfo.id
    })

    if (res && res.success) {
      let params = {
        grantType: 'refresh_token',
        refresh_token: userStore.webToken.refresh_token
      }
      logApi(params).then((res) => {
        userStore.setWebToken(res.data)
        userStore.updateUserInfo(res.data.loginName, 1)
        authStore.regSucInfo.flag = false
        window.dataLayer.push({
          UsernameID: res.data.loginName,
          SocialChannelID: '',
          event: 'register.success'
        })
      })
    }
  }
  function removeAllSpace(str) {
    if (str) {
      return str.replace(/\s+/g, '')
    }
    return ''
  }
  /* 提取代理信息 */
  function getAgentInfo() {
    try {
      /*  */
      let _code = localStorage.getItem('agent-info-local') || userStore.thirdRmCode || ''
      // --- 待处理
      useLayoutEffect(() => {
        if (_code) {
          setFormState({ rmCode: _code })
          setRecOnlyRead(true)
        }
      }, [])
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <VerifyBaseDialog
        visible
        zIndex={5000}
        width={343}
        closeFn={() => handlClose(2)}
        type={'registerSuccess'}
        isFullScreen={!isBigScreen}
      >
        {!isShowSave ? (
          <div className={styles['reg-success-modal']}>
            {/* close */}
            <p className={'reg-suc-title'}>{t('user.regSuc.newWelcome')}</p>
            {/* form */}
            <Form ref={formRef} form={form} autoComplete="off">
              <Form.Item
                className={'mb-16'}
                name="userName"
                rules={[{ required: true, validator: userName, validateTrigger: ['blur', 'change'] }]}
              >
                <p className={'name-tips'}>
                  <img src="/static/images/user/nm-tips.png" className={'tips-img'} />
                  {t('user.regSuc.editOnce')}
                </p>

                <ACInput
                  value={formState.username}
                  placeholder={t('user.regSuc.newUNPlac')}
                  maxLength={10}
                  notSpecial
                  noChinese
                  prefix={<div className={'qc-prefix'}>QC</div>}
                ></ACInput>
                <p className={'common-tips'}>{t('user.regSuc.unTips')}</p>
              </Form.Item>

              {authStore.regSucInfo.showPwEdit && (
                <Form.Item
                  name="password"
                  rules={[{ required: true, validator: password, validateTrigger: ['blur', 'change'] }]}
                >
                  <ACInput
                    value={formState.password}
                    placeholder={t('user.regSuc.nwPWPlac')}
                    maxLength={32}
                    noChinese
                    inputType={'password'}
                  ></ACInput>
                  <p className={'common-tips'}>{t('user.regSuc.pwTips')}</p>
                </Form.Item>
              )}

              {formState.rmCode && (
                <Form.Item name="rmCode">
                  <ACInput
                    value={formState.rmCode}
                    placeholder={t('user.authDialog.refCodePlace')}
                    maxLength={20}
                    noChinese
                    readonly={recOnlyRead}
                    needClear={false}
                  ></ACInput>
                </Form.Item>
              )}
            </Form>
            {/* 按钮提交 */}
            <div className={cn(btnDisable && 'btn-active', 'auth-btn-submit')} onClick={onSubmit}>
              {t('user.confirm')}
            </div>
          </div>
        ) : (
          <div className={styles['save-img-check']}>
            <div className={'save-img-content flex-d-c'}>
              <img src="/static/images/saveAccount/close-grey.png" className={'close'} onClick={onClose} />
              {/* down-load */}
              <p className={cn(!isBigScreen && 'h5', 'save-title')}>{t('user.saveAccount.save')}</p>
              <div onClick={downIMG} className={'save-btn'}>
                {t('user.confirm')}
              </div>
              {/* down-load */}

              {showDownLoad && (
                <div ref="downLoadImg" className={'down-load flex-d-c'}>
                  <img src="/static/images/saveAccount/new-suc-log.png" className={'suc-log'} />
                  <p className={'well-com'}>{t('user.regSuc.newWelcome')}</p>
                  <div className={'info-box'}>
                    <div className={'info-com'}>
                      <img src="/static/images/saveAccount/use.png" className={'user'} />
                      <span className={'m-8'}>{t('user.saveAccount.useName')}</span>
                      <span className={'red'}>QC</span>
                      <span className={'black'}>{formState.username || ''}</span>
                    </div>
                    <div className={'info-com'}>
                      <img src="/static/images/saveAccount/pwd.png" className={'user'} />
                      <span className={'m-8'}>{t('user.saveAccount.password')}</span>
                      <span className={'black'}>{formState.password || ''}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </VerifyBaseDialog>
    </>
  )
}

export default memo(RegisterSuccess)
