'use client'
import { useState, useEffect, useMemo, memo } from 'react'
import { cn, onHandleAlt } from '@/lib/utils'
import { Flex } from 'antd'
import { useTranslation } from '@/lib/locales/client'
import { usePathname } from 'next/navigation'
import Config from '@/constants/followUs'
import Configbase from '@/config/base'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { jumpURL } from '@/lib/jumpURL'
import { numThFilter } from '@/lib/filter'
// import ConfigCurrency from '@/config/currency'
import styles from '../_common_css/footer.module.scss'
import { useShallow } from 'zustand/react/shallow'
/**com */
import QueryLang from '@/components/base/queryLang'
import FootPayment from './footPayment'
import FooterLinks from './footerLinks'

const FooterBase = () => {
  const { t } = useTranslation()
  const { globalPublickKey, language, updateCmsTemplate } = useAppStore(
    useShallow((state) => ({
      globalPublickKey: state.globalPublickKey,
      language: state.language,
      updateCmsTemplate: state.updateCmsTemplate
    }))
  )
  const { currencyRateFooter, currency, jpyToUsd } = useUserStore(
    useShallow((state) => ({
      currencyRateFooter: state.currencyRateFooter,
      currency: state.currency,
      jpyToUsd: state.jpyToUsd
    }))
  )
  const [ambassadorImg, setAmbassadorImg] = useState('')
  const [jumpLink, setJumpLink] = useState('')
  const [ambassadorObj, setAmbassadorObj] = useState(null)
  const pathName = usePathname()
  const rawPath = pathName.replace(/en|ja(\/)?/g, '')
  const { currency: currencyType } = currency || {}

  const Licenses = [
    {
      icon: 'gamcare',
      path: 'https://www.gamcare.org.uk/'
    },
    {
      icon: 'ga',
      path: 'https://www.gamblersanonymous.org.uk/'
    },
    {
      icon: '18'
    },
    {
      icon: 'gpwa',
      path: 'https://www.gpwa.org/affiliate-program/og-affiliate'
    }
  ]
  const FollowUsObj = Config.FollowUs

  // 待处理 pc会有样式穿
  // const addClass = useMemo(() => {
  //   return !isBigScreen ? 'mobile' : 'pc'
  // }, [isBigScreen])

  const currencyRateFmt = useMemo(() => {
    // currency: 当前币种为法币时，展示法币符号；为虚拟币时，对应展示USD
    const { targetAmount, sourceCurrency } = currencyRateFooter || {}
    if (currencyType === 'JPY') {
      return `1 JPY = ${numThFilter(jpyToUsd, 5)} USD`
    } else if (currencyType === 'USD') {
      return `1 USD = ${numThFilter(1 / jpyToUsd, 2)} JPY`
    } else {
      const num = currencyType === 'SHIB' ? 5 : 2
      return `1 ${sourceCurrency} = ${numThFilter(targetAmount / jpyToUsd || 0, num)} JPY`
    }
  }, [])

  // 获取当前年份
  const curYear = useMemo(() => {
    return new Date().getFullYear()
  }, [])

  useEffect(() => {
    if (globalPublickKey) {
      getData()
    }
    let script = document.createElement('script')
    script.src = 'https://67775a69-38c7-41dd-81de-2d3e0690fbc0.seals.dlagglobal.com/dlag-seal.js'
    script.async = true
    document.getElementsByTagName('body')[0].appendChild(script)
  }, [])

  useEffect(() => {
    if (globalPublickKey) {
      getData()
    }
  }, [globalPublickKey])

  const getData = () => {
    return updateCmsTemplate({
      moduleCodeList: ['footerAmbassador'],
      themeCode: Configbase.themeCode
    }).then((res: any) => {
      // console.log('数据', res)
      setAmbassadorImg(res[0]?.mediaLibrary[0]?.fullFilePath || '')
      setJumpLink(res[0]?.templateAction || '')
      setAmbassadorObj(res[0])
    })
  }

  return (
    <Flex>
      <div
        className={cn(
          // styles[isBigScreen ? 'pc' : 'mobile'],
          styles[rawPath === '/account/notice' && 'hide'],
          styles['ac-footer']
        )}
      >
        <div className={'up-box'}>
          {/* <article className={cn('left-content', 'full-page')}> */}
          {/* <article className={cn('left-content', !isBigScreen && 'full-page')}> */}
          {/* </article> */}
          <article className={cn('right-content', 'h5')}>
            <>
              <FootPayment />
              <FooterLinks />
            </>
            {ambassadorImg && (
              <div className={'ambass-box'}>
                <img
                  src={ambassadorImg}
                  className={cn('ambass-img', 'mobile')}
                  onClick={() => jumpURL(jumpLink)}
                  alt={onHandleAlt(ambassadorObj)}
                />
              </div>
            )}
            <article className={'footer-social'}>
              <div className={cn('spcoal-btn-list', language)}>
                <div onClick={() => jumpURL('https://blog.queen-casino.com/')} className={'spcoal-btn'}>
                  <img className={'img1'} src="/static/images/footer/blog.svg" alt="" />
                  {t('common.footer.blog')}
                </div>
                <div onClick={() => jumpURL('/videoList')} className={'spcoal-btn'}>
                  <img className={'img1'} src="/static/images/footer/msg.svg" alt="" />
                  {t('common.footer.streams')}
                </div>
                <div onClick={() => jumpURL('https://queen-casino.info/')} className={'spcoal-btn'}>
                  <img className={'img2'} src="/static/images/footer/news.svg" alt="" />
                  {t('common.footer.news')}
                </div>
              </div>
              <div>
                {FollowUsObj.map((item, key) => (
                  <a target="_blank" key={key} href={item.path} title={item.name}>
                    {item.name && (
                      <img src={`/static/images/footer/${item.name.toLowerCase()}.svg`} alt={item.name} />
                    )}
                    <span>{item.name}</span>
                  </a>
                ))}
              </div>
            </article>
            <div className={'licenses-box'}>
              <div
                id="dlag-67775a69-38c7-41dd-81de-2d3e0690fbc0"
                data-dlag-seal-id="67775a69-38c7-41dd-81de-2d3e0690fbc0"
                data-dlag-image-size="128"
                data-dlag-image-type="basic-small"
                className={'paperwork'}
              />
              {Licenses.map((item, key) => (
                <a key={key} href={item.path} target="_blank" rel="nofollow">
                  <img src={`/static/images/footer/${item.icon.toLowerCase()}-logo.svg`} alt="item.icon" />
                </a>
              ))}
              {/* 语言切换 */}
              <QueryLang className={'lang'} />
            </div>

            {/* 币种额度  */}
            <div className={'currency-trans-wrap'}>
              {currencyRateFooter.sourceCurrency && <p className={'currency-trans'}>{currencyRateFmt}</p>}
            </div>
          </article>
        </div>

        {/* 最底部内容  addClass 待处理 */}
        <div className={cn('footer-credits', 'mobile')}>
          <div className={'footer-credits-license'}>
            <p className={'footer-credits-copyrights'}>{t('common.footer.copyrights', { 0: curYear })}</p>
            <div dangerouslySetInnerHTML={{ __html: t('common.footerHtml.text') }}></div>
            <div
              className={'support'}
              dangerouslySetInnerHTML={{ __html: t('common.footerHtml.support') }}
            ></div>
          </div>
        </div>
      </div>
    </Flex>
  )
}

export default memo(FooterBase)
