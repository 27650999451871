'use client'
import { useState, useEffect, useRef, memo } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import styles from './_common_css/globalLoading.module.scss'
import Portal from '@/components/base/portal'

const GlobalLoading = () => {
  const { t } = useTranslation()
  const [barStart, setBarStart] = useState(false)
  const timer = useRef(null)

  useEffect(() => {
    timer.current = setTimeout(() => {
      setBarStart(true)
    }, 50)
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  return (
    <Portal>
      <div className={styles['glob-loading-modal']}>
        <img src="/static/images/common/loading-icon.png" className={styles['loading-logo']} />
        <div className={cn(styles[barStart && 'active'], styles['progress-warp'])}></div>
        <p className={styles['loading-text']}>{t('common.loading')}</p>
      </div>
    </Portal>
  )
}

export default memo(GlobalLoading)
