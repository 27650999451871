import { useState, useEffect, useMemo, memo } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { useRouter, usePathname } from 'next/navigation'
import { Layout } from 'antd'
import Config from '@/config/base'
import { useNotice } from '@/hooks/useNotice'
import { webpHandle } from '@/hooks/aboutWebp'
import styles from '../_common_css/header.module.scss'
import { useShallow } from 'zustand/react/shallow'
// com
import HeaderAccountMoney from './accountMoney'
const { Header } = Layout

const H5Header = () => {
  const { t } = useTranslation()
  const { isNewVer, language, globalPublickKey, setIsShowSideNav, updateCmsTemplate } = useAppStore(
    useShallow((state) => ({
      isNewVer: state.isNewVer,
      language: state.language,
      globalPublickKey: state.globalPublickKey,
      setIsShowSideNav: state.setIsShowSideNav,
      updateCmsTemplate: state.updateCmsTemplate
    }))
  )
  const { openLogin, openRegister } = useAuthStore(
    useShallow((state) => ({
      openLogin: state.openLogin,
      openRegister: state.openRegister
    }))
  )
  const { setWebToken, isLogin } = useUserStore(
    useShallow((state) => ({
      setWebToken: state.setWebToken,
      isLogin: state.isLogin
    }))
  )
  const router = useRouter()
  const pathName = usePathname()
  const rawPath = pathName.replace(/en|ja(\/)?/g, '')
  const { noticeList } = useNotice()
  const [headerBgUrl, setHeaderBgUrl] = useState('')
  const [headerBgUrlNew, setHeaderBgUrlNew] = useState('') // 新版顶部背景
  const [headerLogo, setHeaderLogo] = useState('')

  // 未读数量
  const unReadNoticeLength = useMemo(() => {
    const arr = noticeList.filter((item) => !item.isRead || item.isRead == 0)
    return arr.length > 99 ? 99 : arr.length // 大于99均展示99
  }, [])

  /*  fuc */
  const handleClick = (type) => {
    localStorage.removeItem('userStore')
    setWebToken('')
    setIsShowSideNav(false)
    // if (!isBigScreen) setIsShowSideNav(false)
    if (type === 'login') {
      openLogin()
    } else {
      openRegister(true)
    }
  }

  const topBgStyle = useMemo(() => {
    const url = isNewVer ? headerBgUrlNew : headerBgUrl
    return url
      ? {
          backgroundImage: `url('${url}')`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat'
        }
      : {}
  }, [])

  function onGetCMSData() {
    let params = {
      moduleCodeList: ['headerBg', 'headerBgNew', 'headerLogo'],
      themeCode: Config.themeCode
    }
    return updateCmsTemplate(params).then((data) => {
      const headerBgData = data.filter((item) => item.moduleCode === 'headerBg') || []
      if (headerBgData.length > 0) {
        setHeaderBgUrl(headerBgData[0].mediaLibrary[0].fullFilePath)
      }
      setHeaderLogo(data.filter((item) => item.moduleCode === 'headerLogo')[0]?.mediaLibrary[0]?.fullFilePath)
      const headerBgDataNew = data.filter((item) => item.moduleCode === 'headerBgNew') || []
      if (headerBgDataNew.length > 0) {
        setHeaderBgUrlNew(headerBgDataNew[0]?.mediaLibrary[0]?.fullFilePath)
      }
    })
  }

  useEffect(() => {
    if (globalPublickKey) {
      onGetCMSData()
    }
  }, [globalPublickKey])

  return (
    <>
      {/* {!$route.meta.hideHeader && <Header />} 有些页面读取header有hide值时，隐藏header*/}
      <Header
        className={styles['header_box']}
        // className={cn(
        //   'header_box',
        //   styles[isBigScreen ? 'pc' : 'h5'],
        //   styles['new-version-head'],
        //   'fixed top-0 z-[999] w-full backdrop-blur-[50px]'
        // )}
      >
        <div className={cn('h5-header-left-box', rawPath !== '/' && 'bg', language)} style={topBgStyle}>
          {/* logo */}
          <div className={'logo-box'} onClick={() => router.push('/')}>
            <img className={'logo'} src="/static/images/header/queen.svg" alt="" />
            {headerLogo && <img className={'logo-activity'} src={webpHandle(headerLogo)} alt="" />}
          </div>
          {!isLogin ? (
            <div className={'h5-header-btns'}>
              <a onClick={() => handleClick('login')}>
                <span>{isNewVer ? t('user.login') : t('user.uLogin')}</span>
              </a>
              <a onClick={() => handleClick('register')}>
                <span>{isNewVer ? t('user.signup') : t('user.uRegister')}</span>
              </a>
            </div>
          ) : (
            <div className={'amount-message'}>
              {rawPath !== '/account' && <HeaderAccountMoney />}
              {/* 消息中心 */}
              <div className={'top-message'}>
                {Boolean(unReadNoticeLength) && <i className={'count'}>{unReadNoticeLength}</i>}
                <img
                  className={'message'}
                  src="/static/images/header/ld.png"
                  alt=""
                  onClick={() => router.push('/account/notice')}
                />
              </div>
            </div>
          )}
        </div>
      </Header>
    </>
  )
}

export default memo(H5Header)
