'use client'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { cn } from '@/lib/utils'
import { useRouter } from 'next/navigation'
import { useAuthStore } from '@/stores/authControl'
import { useAppStore } from '@/stores/app'
// import { useUser } from '@/store/user'
// import Forms from './model/data-register'
import { px2rem } from '@/hooks/useRem'
/* com */
import BaseDialog from '@/components/base/baseDialog'
import Login from './_components/login'
import Register from './_components/register'
import SupportThird from '../_components/supportThird'
import { useShallow } from 'zustand/react/shallow'
import styles from './_common_css/page.module.scss'
// import './_common_css/layout.scss'

export default function AuthMobileLayout() {
  const { t } = useTranslation()
  // const isBigScreen = useAppStore((state) => state.isBigScreen)
  const { closeModal, logRegVisible, logRegType, openRegister, openLogin } = useAuthStore(
    useShallow((state) => ({
      closeModal: state.closeModal,
      logRegVisible: state.logRegVisible,
      logRegType: state.logRegType,
      openRegister: state.openRegister,
      openLogin: state.openLogin
    }))
  )
  // const { setWebToken, updateUserInfo, setSeonSession, userInfo, fingerPrintID } = useUserStore()
  const router = useRouter()
  const lineTimer = useRef(null)
  // const lineTimer = useState(null)
  // useEffect(() => {
  // props.updateValue(props.modelValue)
  // }, [props.modelValue])
  const handlClose = () => {
    closeModal()
    clearInterval(lineTimer.current)
    lineTimer.current = null
    // if (!isBigScreen) {
    router.push('/')
    // }
  }

  // const dialogWidth = useMemo(() => {
  //   return isBigScreen ? px2rem(792, isBigScreen) : '100%'
  // }, [])

  // const isFullScreen = useMemo(() => {
  //   return !isBigScreen
  // }, [])

  return (
    <>
      <BaseDialog visible={logRegVisible} width={'100%'} isFullScreen>
        {/* , !isBigScreen && 'is-h5' */}
        <div className={cn(styles['user-auth-model'], styles['is-h5'])} style={{ width: '100%' }}>
          <div className={cn('right-auth', logRegType)}>
            {!(logRegType === 'register') && (
              <div className={'righ-top'}>
                <span
                  className={cn(logRegType === 'register' && 'top-active', 'top-sign')}
                  onClick={() => openRegister(true)}
                >
                  {t('user.authDialog.dReg')}
                </span>
                <span className={cn(logRegType === 'login' && 'top-active', 'top-sign')} onClick={openLogin}>
                  {t('user.authDialog.dLogin')}
                </span>
              </div>
            )}
            {/* h5-logo */}
            <>
              {logRegType !== 'register' && <div className={'h5-auth-logo'}></div>}
              {logRegType !== 'register' && <div className={'mask-bg'}></div>}
            </>
            {logRegType === 'login' ? <Login /> : <Register />}
            {/* to-register */}
            {logRegType === 'login' && (
              <p className={cn('to-register', 'mb-[24px]')}>
                {t('user.authDialog.toRegist1')}
                <span className={'blue-color'} onClick={() => openRegister(true)}>
                  {t('user.authDialog.toRegist2')}
                </span>
              </p>
            )}
            {/* 第三方登录 */}
            <SupportThird />
          </div>
          {logRegType === 'register' ? (
            <img
              src="/static/images/saveAccount/close.svg"
              alt=""
              onClick={handlClose}
              className={'auth-close'}
            />
          ) : (
            <img
              src="/static/images/saveAccount/close-grey.png"
              onClick={handlClose}
              className={'auth-close'}
            />
          )}
        </div>
      </BaseDialog>
    </>
  )
}
