import { useState, useEffect, useMemo } from 'react'
import { useSetState } from 'ahooks'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { useRouter } from 'next/navigation'
import { useTranslation } from '@/lib/locales/client'
import { NoticeListProps, NoticeImgMap, NoticeTabProps } from '@/types/notice'
import { Pagination } from '@/types/common'
import { useLangHelp } from '@/hooks/useLangHelp'
import { batchUpdateStationMsgRecordFrontApi, getSysContent } from '@/api/notice'
import { addDays, subDays } from 'date-fns'
import { message as AntdMessage } from '@/components/base/antdNotice'

const getPreviewMsgContent = (msgContent: string) => {
  const { t } = useTranslation()
  if (!msgContent) return
  const text = msgContent // 去除所有html表情
    .replace(/<(img).*?>/g, '')

  if (text.length > 0) {
    return text
  } else {
    return t('notice.detailBtn')
  }
}

const getPreviewMsgContentText = (msgContent: string) => {
  const { t } = useTranslation()
  if (!msgContent) return
  const text = msgContent // 去除所有html表情
    .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
    .replace(/<[^>]+?>/g, '')
    .replace(/\s+/g, ' ')
    .replace(/ /g, ' ')
    .replace(/>/g, ' ')
    .trim()
  if (text.length > 0) {
    return text
  } else {
    return t('notice.detailBtn')
  }
}

/**
 * 设置消息已读
 * @param ids
 */
const readySysContent = (msgs: Array<any>) => {
  const ids = msgs
    .filter((item) => {
      return item.isRead === 0
    })
    .map((item) => {
      return item.id
    })

  if (ids && ids.length > 0) {
    batchUpdateStationMsgRecordFrontApi({ idArr: ids, isRead: 1 }).then((res: any) => {
      // _this.$success(_this.$t("bonus.success"))
    })
  }
}

export const useNotice = () => {
  const isLogin = useUserStore((state) => state.isLogin)
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  const { t } = useTranslation()
  const router = useRouter()
  const { backLangKey } = useLangHelp()
  const [activeMarket, setActiveMarket] = useState(null)
  const [activeTab, setActiveTab] = useState<number>(1)
  const [deleteIsVisible, setDeleteIsVisible] = useState<boolean>(false)
  const [drawerIsVisible, setDrawerIsVisible] = useState<boolean>(false)
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
  const [listenScroll, setListenScroll] = useState<boolean>(true)
  const [beforeScroll, setBeforeScroll] = useState<boolean>(true)
  const [afterScroll, setAfterScroll] = useState<boolean>(true)
  const [curDeleteData, setCurDeleteData] = useState<Array<any>>([])
  const [curReadData, setCurReadData] = useState<Array<any>>([])
  const [msgTypes, setMsgTypes] = useState<Array<any>>([])
  const [noticeDetailDialogVisible, setNoticeDetailDialogVisible] = useState<boolean>(false)
  const [curMsgContent, setCurMsgContent] = useState<string>('')
  const [curMsgTitle, setCurMsgTitle] = useState<string>('')
  const [curMsgDate, setCurMsgDate] = useState<string>('')
  const [curMsgPicLink, setCurMsgPicLink] = useState<string>('')
  const [curMsgJumpLink, setCurMsgJumpLink] = useState<string>('')
  const [curTabIndex, setCurTabIndex] = useState<number>(0)
  const [noticePageSize, setNoticePageSize] = useState<number>(7)
  const [noticePageCurrent, setNoticePageCurrent] = useState<number>(1)
  const [noticeTotalPage, setNoticeTotalPage] = useState(null)
  const [marketPageSize, setMarketPageSize] = useState<number>(7)
  const [marketPageCurrent, setMarketPageCurrent] = useState<number>(1)
  const [marketTotalPage, setMarketTotalPage] = useState(null)
  const [deleteOnlyOne, setDeleteOnlyOne] = useState<boolean>(false) // 删除单条数据
  const [marketVisible, setMarketVisible] = useState<boolean>(false)
  const [marketPagination, setMarketPagination] = useSetState<Pagination>({
    total: 1,
    current: 1,
    pageSize: 8
  })

  const [notificationPagination, setNotificationPagination] = useSetState<Pagination>({
    total: 1,
    current: 1,
    pageSize: 8
  })
  const tabData = [
    {
      text: t('misc.notice.message'),
      param: ['STATION_MESSAGE']
    },
    {
      text: t('misc.notice.notification'),
      param: ['SYSTEM_NOTIFICATION']
    },
    {
      text: t('misc.notice.announcement'),
      param: ['POP_UP_ANNOUNCEMENT']
    }
  ]
  const tabDataPC = [
    {
      text: t('misc.notice.all'),
      param: ['STATION_MESSAGE', 'SYSTEM_NOTIFICATION', 'POP_UP_ANNOUNCEMENT']
    },
    ...tabData
  ]

  const [tabs, setTabs] = useSetState<Array<NoticeTabProps>>([
    { label: t('misc.notice.tab1'), isHide: true },
    { label: t('common.account.notice') }
  ])
  const pagination = useMemo<Pagination>(() => {
    if (activeTab === 1) {
      return notificationPagination
    } else {
      return marketPagination
    }
  }, [activeTab])

  // 消息类型,SYSTEM_NOTIFICATION-系统通知,STATION_MESSAGE-站内信,MARQUEE-跑马灯

  const getImg = (msgType: 'SYSTEM_NOTIFICATION' | 'STATION_MESSAGE' | undefined) => {
    const imgMap: NoticeImgMap = {
      STATION_MESSAGE: '/static/images/myAccount/common/msg-type-2.png',
      SYSTEM_NOTIFICATION: '/static/images/myAccount/common/msg-type-1.png',
      POP_UP_ANNOUNCEMENT: '/static/images/myAccount/common/msg-type-4.png',
      MARKET_MESSAGE: '/static/images/myAccount/common/msg-type-3.png'
    }
    if (msgType) {
      return imgMap[msgType]
    }
  }

  const [noticeList, setNoticeList] = useState<Array<any>>([])
  const [marketList, setMarketList] = useState<Array<any>>([])

  //   消息类型,SYSTEM_NOTIFICATION-系统通知,STATION_MESSAGE-站内信,MARQUEE-跑马灯
  const getNoticeDate = (queries = {}, config = { hideLoading: false }, type = '1') => {
    if (!isBigScreen && noticeTotalPage && noticePageCurrent >= noticeTotalPage && type === 'more') {
      setListenScroll(false)
      setBeforeScroll(false)
      setAfterScroll(false)
      return
    }

    if (
      !isBigScreen &&
      noticeTotalPage &&
      noticeTotalPage &&
      type === 'more' &&
      noticePageCurrent < noticeTotalPage
    ) {
      setNoticePageCurrent(noticePageCurrent + 1)
    }

    const data = {
      pageSize: isBigScreen ? pagination.pageSize : noticePageSize,
      pageNo: isBigScreen ? pagination.current : noticePageCurrent,
      batchId: -1, //-1 批量人工发送，-1系统自动发送
      isDeleted: 0,
      createdDateBegin: subDays(new Date(), 7).toISOString(),
      createdDateEnd: new Date().toISOString()
    }
    if (!isLogin) return
    getSysContent(data, config).then((res: any) => {
      if (res.success) {
        let noticeListInit = []
        noticeListInit = res.data.data.map((item: any) => {
          return {
            ...item,
            img: getImg(item.msgType),
            onClick() {
              // -- 待处理
              // router.push({
              //   path: "/account/notice-detail",
              //   query: {
              //     id: item.recordId
              //   }
              // })
            },
            preMsgContent: getPreviewMsgContent(item.msgContent),
            preMsgContentText: getPreviewMsgContentText(item.msgContent)
          }
        })

        setNoticeList(isBigScreen ? noticeListInit : noticeList.concat(noticeListInit))
        setNotificationPagination({ total: pagination.pageSize * res.data.totalPage })
        setNoticeTotalPage(res.data.totalPage)
      } else {
        setNoticeList([])
        setNotificationPagination({ total: 1 })
      }
    })
  }

  const getMarketDate = (queries = {}, config = { hideLoading: false }, type = '1') => {
    if (!isBigScreen && marketTotalPage && marketPageCurrent >= marketTotalPage && type === 'more') {
      setListenScroll(false)
      setBeforeScroll(false)
      setAfterScroll(false)
      return
    }

    if (!isBigScreen && marketTotalPage && type === 'more' && marketPageCurrent < marketTotalPage) {
      setMarketPageCurrent(marketPageCurrent + 1)
    }

    const data = {
      size: isBigScreen ? pagination.pageSize : marketPageSize,
      current: isBigScreen ? pagination.current : marketPageCurrent,
      queries: {
        ...queries,
        msgTypes: [3],
        sendClients: ['MAIN_WEBSITE']
      },
      asc: [],
      desc: [],
      orders: []
    }
    if (!isLogin) return
    getSysContent(data, config).then((res: any) => {
      if (res.success) {
        console.log('营销信息', res)
        let marketListInit = []
        marketListInit = res.data.records.map((item: any) => {
          return {
            ...item,
            img: getImg(item.msgType),
            onClick() {
              setActiveMarket(item)
              setMarketVisible(true)
              console.log('点击营销信息')
            },
            preMsgContent: getPreviewMsgContent(item.msgContent),
            preMsgContentText: getPreviewMsgContentText(item.msgContent)
          }
        })

        setMarketList(isBigScreen ? marketListInit : marketList.concat(marketListInit))
        setMarketPagination({ total: res.data.total / 1 })
        marketTotalPage.value = res.data.totalPage
      } else {
        setMarketList([])
        setMarketPagination({ total: 1 })
      }
    })
  }

  const changePage = (cPagination: any) => {
    if (activeTab === 1) {
      setNotificationPagination({ current: cPagination.current })
      getNoticeDate({}, { hideLoading: true })
    } else {
      setMarketPagination({ current: cPagination.current })
      getMarketDate({}, { hideLoading: true })
    }
  }

  //点击站内信显示详情 并 调用接口 设置为已读
  const clickNews = (item: NoticeListProps) => {
    setCurMsgContent(item && item.content)
    setNoticeDetailDialogVisible(true)
    setCurMsgTitle(item && item.subject)
    setCurMsgDate(item && item.createdDate)
    setCurMsgPicLink(item && item.picLink)
    setCurMsgJumpLink(item && item.jumpLink)

    const arr = []
    arr.push(item)
    readySysContent(arr)

    if (activeTab === 1) {
      const tempNotice = [...noticeList]
      tempNotice.forEach((list) => {
        if (list.id === item.id) {
          list.isRead = 1
        }
      })
      setNoticeList(tempNotice)
    } else {
      const tempMarketList = [...marketList]
      tempMarketList.forEach((list) => {
        if (list.recordId === item.recordId) {
          list.readStatus = 'HAVE_READ'
        }
      })
      setMarketList(tempMarketList)
    }
  }
  function contentImgClick() {
    // -- 待处理
    // router.push({
    //   path: curMsgJumpLink,
    // })
  }

  useEffect(() => {
    if (activeTab === 1) {
      if (!noticeList.length) {
        getNoticeDate({}, { hideLoading: true })
      }
    } else {
      if (!marketList.length) {
        getMarketDate({}, { hideLoading: true })
      }
    }
  }, [activeTab])

  useEffect(() => {
    getNoticeDate({}, { hideLoading: false })
  }, [])

  // 一键删除
  const onDeleteAll = () => {
    setDeleteIsVisible(true)
    setCurDeleteData([])
    setDeleteOnlyOne(false)
  }

  // 删除单条数据
  const onDelete = (data: any) => {
    setDeleteIsVisible(true)
    setCurDeleteData([])

    // const pushOneRecord = curDeleteData.push(data)
    setCurDeleteData([...curDeleteData, data])

    setDeleteOnlyOne(true)
  }

  const showDrawer = () => {
    setDrawerIsVisible(true)
  }

  const onCloseDrawer = () => {
    setDrawerIsVisible(false)
  }

  const onDeleteFn = () => {
    setDeleteLoading(true)
    if (deleteOnlyOne) {
      onDeleteSysContent(curDeleteData)
    } else {
      onDeleteSysContent(noticeList)
    }
  }

  const onCloseDialog = () => {
    setDeleteIsVisible(false)
  }

  // 删除消息
  const onDeleteSysContent = (msgs: Array<any>) => {
    const ids = msgs.map((item) => {
      return item.id
    })

    if (ids && ids.length > 0) {
      batchUpdateStationMsgRecordFrontApi({
        idArr: ids,
        isDeleted: 1,
        isRead: 1
      }).then((res: any) => {
        AntdMessage.success(t('notice.deletedSuccessfully'))
        setDeleteLoading(false)
        setDeleteIsVisible(false)

        if (activeTab === 1) {
          setNoticeList([])
          setNoticePageCurrent(1)
          pagination.current = 1 // --- 待处理
          getNoticeDate({}, { hideLoading: true })
        } else {
          setMarketList([])
          setMarketPageCurrent(1)
          pagination.current = 1
          getMarketDate({}, { hideLoading: true })
        }
      })
    }
  }

  // 一键已读
  const onReadAll = () => {
    if (activeTab === 1) {
      setCurReadData(noticeList)
    } else {
      setCurReadData(marketList)
    }

    const params = {
      allRead: true
    }

    batchUpdateStationMsgRecordFrontApi(params).then((res: any) => {
      // console.log()
      AntdMessage.success(t('bonus.success'))
    })

    if (activeTab === 1) {
      const tempNotice = [...noticeList]
      tempNotice.forEach((list) => {
        list.isRead = 1
      })
      setNoticeList(tempNotice)
    } else {
      const tempMarketList = [...marketList]
      tempMarketList.forEach((list) => {
        list.readStatus = 'HAVE_READ'
      })
      setMarketList(tempMarketList)
    }
  }

  // H5 删除单条
  const onDeleteH5 = (data: any) => {
    setCurDeleteData([])
    setCurDeleteData([...curDeleteData, data])
    setDeleteOnlyOne(true)
    onDeleteSysContent(curDeleteData)
  }

  // 筛选消息类型
  const onGetMsgTypesData = (val: any, i: number) => {
    if (val && val.length) {
      setMsgTypes(val)
    }
    if (i || i === 0) {
      setCurTabIndex(i)
    }
    setNoticeList([])
    setNoticePageCurrent(1)
    getNoticeDate({}, { hideLoading: true })
    setDrawerIsVisible(false)
  }

  // H5 站内信 重置
  const onGetAllMsgTypesData = () => {
    setMsgTypes(['STATION_MESSAGE', 'SYSTEM_NOTIFICATION', 'POP_UP_ANNOUNCEMENT'])
    setNoticeList([])
    setNoticePageCurrent(1)
    getNoticeDate({}, { hideLoading: true })
    setDrawerIsVisible(false)
  }

  // H5 选择需要筛选的站内信类型
  const onChooseTabH5 = (val: any, i: number) => {
    setMsgTypes(val)
    setCurTabIndex(i)
  }

  return {
    getNoticeDate,
    pagination,
    changePage,
    noticeList,
    marketList,
    marketVisible,
    activeTab,
    clickNews,
    activeMarket,
    tabs,
    onDelete,
    deleteIsVisible,
    showDrawer,
    drawerIsVisible,
    onCloseDrawer,
    deleteLoading,
    onDeleteFn,
    onCloseDialog,
    listenScroll,
    beforeScroll,
    afterScroll,
    curDeleteData,
    onDeleteAll,
    curReadData,
    onReadAll,
    onGetMsgTypesData,
    msgTypes,
    onChooseTabH5,
    noticeDetailDialogVisible,
    curMsgContent,
    tabData,
    tabDataPC,
    curTabIndex,
    onDeleteH5,
    noticePageCurrent,
    noticeTotalPage,
    marketPageCurrent,
    marketTotalPage,
    onGetAllMsgTypesData,
    curMsgTitle,
    curMsgDate,
    curMsgPicLink,
    curMsgJumpLink,
    contentImgClick
  }
}
