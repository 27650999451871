'use client'
import { useState, useEffect, useMemo, memo } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { usePathname } from 'next/navigation'
import Config from '@/constants/followUs'
import Configbase from '@/config/base'
import { useAppStore } from '@/stores/app'
import { jumpURL } from '@/lib/jumpURL'
import { onHandleAlt } from '@/lib/utils'
import { useShallow } from 'zustand/react/shallow'
import styles from '../_common_css/footer.module.scss'
/**com */
import QueryLang from '@/components/base/queryLang'
import FooterLinks from './footerLinks'
import FootPayment from './footPayment'

const Footer = () => {
  const { t } = useTranslation()
  const { updateCmsTemplate, globalPublickKey } = useAppStore(
    useShallow((state) => ({
      globalPublickKey: state.globalPublickKey,
      updateCmsTemplate: state.updateCmsTemplate
    }))
  )
  const pathName = usePathname()
  // const rawPath = pathName.replace(/en|ja(\/)?/g, '')

  const Licenses = [
    {
      icon: 'gamcare',
      path: 'https://www.gamcare.org.uk/'
    },
    {
      icon: 'ga',
      path: 'https://www.gamblersanonymous.org.uk/'
    },
    {
      icon: '18'
    },
    {
      icon: 'gpwa',
      path: 'https://www.gpwa.org/affiliate-program/og-affiliate'
    }
  ]
  const FollowUsObj = Config.FollowUs
  const curYear = useMemo(() => new Date().getFullYear(), [])

  const [ambassadorImg, setAmbassadorImg] = useState('')
  const [jumpLink, setJumpLink] = useState('')
  const [ambassadorObj, setAmbassadorObj] = useState(null)

  const getData = () => {
    return updateCmsTemplate({
      moduleCodeList: ['footerAmbassador'],
      themeCode: Configbase.themeCode
    }).then((res: any) => {
      setAmbassadorImg(res[0]?.mediaLibrary[0]?.fullFilePath || '')
      setJumpLink(res[0]?.templateAction || '')
      setAmbassadorObj(res[0])
    })
  }

  useEffect(() => {
    if (globalPublickKey) {
      getData()
    }
    let script = document.createElement('script')
    script.src = 'https://67775a69-38c7-41dd-81de-2d3e0690fbc0.seals.dlagglobal.com/dlag-seal.js'
    script.async = true
    document.getElementsByTagName('body')[0].appendChild(script)
  }, [])

  useEffect(() => {
    if (globalPublickKey) {
      getData()
    }
  }, [globalPublickKey])

  return (
    <>
      <div
        // styles['pc']
        className={styles['ac-footer']}
      >
        <div className={'up-box'}>
          <article className={cn('left-content')}>
            <>
              <FooterLinks />
              <div className={'pc-licenses-box'}>
                <QueryLang />
                <div className={'licenses-box'}>
                  <div
                    id="dlag-67775a69-38c7-41dd-81de-2d3e0690fbc0"
                    data-dlag-seal-id="67775a69-38c7-41dd-81de-2d3e0690fbc0"
                    data-dlag-image-size="128"
                    data-dlag-image-type="basic-small"
                    className={'paperwork'}
                  />
                  {Licenses.map((item, key) => (
                    <a key={key} href={item.path} target="_blank" rel="nofollow">
                      <img
                        src={`/static/images/footer/${item.icon.toLowerCase()}-logo-pc.svg`}
                        alt="item.icon"
                      />
                    </a>
                  ))}
                </div>
              </div>
            </>
          </article>

          <article className={cn('right-content')}>
            {ambassadorImg && (
              <div className={'ambass-box'}>
                <img
                  src={ambassadorImg}
                  className={'ambass-img'}
                  onClick={() => jumpURL(jumpLink)}
                  alt={onHandleAlt(ambassadorObj)}
                />
              </div>
            )}
            <FootPayment />
          </article>
        </div>

        <div className={cn('footer-credits', 'is-pc')}>
          <div className={'footer-credits-license'}>
            <p className={'footer-credits-copyrights'}>{t('common.footer.copyrights', { 0: curYear })}</p>

            <div dangerouslySetInnerHTML={{ __html: t('common.footerHtml.text') }}></div>
            <div
              dangerouslySetInnerHTML={{ __html: t('common.footerHtml.support') }}
              className={'support'}
            ></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(Footer)
