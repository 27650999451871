'use client'
import { useState, useEffect, useMemo, useImperativeHandle, forwardRef, useRef, memo } from 'react'
import Validtor from '@/hooks/validtor'
import CountrySelect from '@/components/base/countrySelect'
import useCountDown from '@/hooks/useCountDown'
import { cn } from '@/lib/utils'
import { useClickAway } from 'ahooks'
import { Input } from 'antd'
import type { InputRef } from 'antd'
import { useTranslation } from '@/lib/locales/client'
import { CSSTransition } from 'react-transition-group'
import styles from './_common_css/acInput.module.scss'

type IProps = {
  value: string
  placeholder?: string
  type?: string
  isClick?: boolean // 是否增加输入口后点击按钮
  maxLength?: number // 最大输入长度
  inputType?: string
  needCountry?: boolean
  needEmail?: boolean
  needClear?: boolean
  showPhoneTips?: boolean
  isLightModel?: boolean
  disabled?: boolean
  readonly?: boolean
  autoComplete?: string
  // canChooseCountry?: boolean
  notSpecial?: boolean
  notSpace?: boolean
  onlyEn?: boolean
  noChinese?: boolean
  isAllow?: boolean
  onlyNumber?: boolean
  onlyNumberMaxNum?: number
  //金额 ，包好小数点
  onlyCurrency?: boolean
  //小数点位数
  decimal?: number // 2
  hideContact?: boolean
  codeSend?: any
  className?: string
  children?: any
  prefix?: React.ReactNode
  onChange?: (e: any) => void
  showInput?: (value?: any) => void
  closeEmail?: (value?: any) => void
  input?: (value?: any) => void
  blur?: (value?: any) => void
  focus?: (value?: any) => void
  updateValue?: (value?: any) => void
}

//  登录和注册不再使用这个组件
const ACInput = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const { needClear = true, autoComplete = 'off', onlyNumberMaxNum = 0, decimal = 2 } = props
  const { t } = useTranslation()
  const inputRef = useRef<InputRef>(null)
  const selectAreaRef = useRef(null) // 选择国家搜索
  const [inputValue, setInputValue] = useState(props.value)

  // 对输入内容 进行格式化处理
  useEffect(() => {
    console.log('输入内容', props.value)
    setInputValue(valueFormat(props.value))
    props.updateValue(inputValue)
    // 待观察
    // emits('update:value', inputValue.value)
    // }, [props.initialValues])
  }, [props.value])

  const [isOpenCountry, setIsOpenCountry] = useState(false)

  // 监听地区选择器 开关
  useEffect(() => {
    isOpenCountry ? selectAreaRef.current?.focus() : selectAreaRef.current?.blur()
  }, [isOpenCountry])

  // 点地区选择器面板以外的事件
  useClickAway(
    () => {
      // 当选中地区选择面板以外时，关闭该面板
      setIsOpenCountry(false)
    },
    () => document.getElementById('country-select')
  )

  const { textRef, start, flag } = useCountDown(60, t('user.authDialog.send'), '')
  const [showEye, setShowEye] = useState(false)

  //  邮箱添加自动补全后缀
  const [complateEmailEndList] = useState(['@gmail.com', '@outlook.com', '@yahoo.com', '@icloud.com'])

  const completeList = useMemo(() => {
    const emailValue = inputValue
    // console.log('email 值', emailValue)
    if (emailValue) {
      let list: any = complateEmailEndList
      list = list
        .map((e) => {
          let autoCompleteEmail =
            emailValue.indexOf('@') != -1
              ? `${emailValue.slice(0, emailValue.indexOf('@'))}${e}`
              : `${emailValue}${e}`

          if (autoCompleteEmail.includes(emailValue)) {
            return {
              html: `<span style="color: #fc005b">${emailValue}</span>${autoCompleteEmail.replace(emailValue, '')}`,
              value: autoCompleteEmail
            }
          } else {
            return {
              html: '',
              value: ''
            }
          }
        })
        .filter((e) => e.value !== '' && e.value !== emailValue)
      return list
    } else {
      return []
    }
  }, [inputValue])

  /* format */
  const valueFormat = (str) => {
    if (str === '' || !str) return
    /* 无需特殊字符 */
    if (props.notSpecial) {
      str = str.replace(Validtor.specialSymbol(), '')
    }
    if (props.notSpace) {
      str = str.replace(/\s/, '')
    }
    if (props.onlyEn) {
      str = str.replace(/[\u4E00-\u9FFF\u3040-\u309F\u30A0-\u30FF\uAC00-\uD7AF]/g, '')
    }
    /* 无需中文 */
    if (props.noChinese) {
      str = str.replace(/[\u4E00-\u9FA5]/g, '')
    }
    /* 纯数字 */
    if (props.onlyNumber) {
      str = str.replace(/\D/gi, '')
      // if (!props.isAllow) {
      //   str = str.replace(/^0/g, "")
      // }
    }

    if (props.onlyCurrency) {
      //金额 ，包好小数点
      str = str.replace(/[^\d.]/g, '') // 只保留数字和小数点
      str = str.replace(/^\./g, '') // 第一位不能为小数点
      str = str.replace(/\.{2,}/g, '.') // 不能出现多个小数点
      str = str.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.') // 只保留第一个小数点
      // str = str.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3") // 只保留小数点后两位
      let repeatD = ''
      for (var i = 0; i < decimal; i++) {
        repeatD += '\\d'
      }
      str = str.replace(new RegExp(`^(-)*(\\d+)\\.(${repeatD}).*$`), '$1$2.$3') // 只保留小数点后两位
      if (onlyNumberMaxNum > 0) {
        str = onlyNumberMaxNum > Number(str) ? str : onlyNumberMaxNum + ''
      }
    }

    return str
  }

  // input 输入事件
  const changeHandle = (event) => {
    // console.log('input event', event)
    const value = event.target.value
    console.log('输入框内容!!', value)
    props.updateValue(value)
    props.showInput && props.showInput(value)
    /* format-处理  待观察*/
    // emits('update:value', value)
    // emits('showInput', value)
  }
  const setInputEmail = (event) => {
    const value = event.target.value
    props.updateValue(value)
    props.closeEmail && props.closeEmail()
    /* format-处理  待观察*/
    // emits('update:value', event)
    // emits('closeEmail')
  }
  const inputHandle = (event) => {
    const value = event.target.value
    console.log('input 输入', event, value)

    props.input && props.input(value)
    /* format-处理  待观察*/
    // emits('input', value)
  }

  const blurHandle = (event) => {
    const value = event.target.value
    props.blur && props.blur(value)
    // console.log('input 失焦', event)
    /* format-处理  待观察*/
    // emits('blur', value)
  }
  const focusHandle = (event) => {
    console.log('输入聚焦', event)
    // const value = event.target.value
    // console.log('打开 focus', value)

    // 获取焦点时 判断地区选择是否有打开
    isOpenCountry ? selectAreaRef.current?.focus() : selectAreaRef.current?.blur()
  }

  const sendCode = (evt) => {
    evt.stopPropagation()
    // console.log('发送验证码', evt, flag)
    if (!flag) return
    props.codeSend()
    /* format-处理  待观察*/
    // emits('codeSend')
  }

  const contactCS = () => {
    window.Intercom('show')
  }

  // 导出事件 只用于inputRef
  useImperativeHandle(ref, (): any => {
    return {
      // sendCode() {
      //   console.log('出发手机号', inputRef.current)
      //   inputRef.current.sendCode()
      // },
      startCount() {
        start()
      },
      // 输入事件
      //
      // onChange: (evt) => {
      //   inputRef.current.onChange()
      // },
      input: (evt) => {
        const value = evt.target.value
        console.log('ac input 输入', evt, value)
      }
    }
  }, [])

  return (
    <>
      <div className={cn(styles['input-box'], props.needCountry && styles['hasCountry'], styles[props.type])}>
        {/* <div className={cn(props.needCountry && styles['input-box'], styles['hasCountry'], styles[props.type])}> */}
        <Input
          ref={inputRef}
          value={inputValue}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          type={showEye ? 'text' : props.onlyNumber ? 'tel' : props.inputType}
          disabled={props.disabled}
          readOnly={props.readonly}
          allowClear={needClear}
          autoComplete={autoComplete}
          onChange={changeHandle}
          onInput={inputHandle}
          onBlur={blurHandle}
          onFocus={focusHandle}
          className={props.className}
          prefix={
            <>
              {props.needCountry ? (
                <CountrySelect
                  domId={'country-select'}
                  ref={selectAreaRef}
                  isLightModel={props.isLightModel}
                  // canChooseCountry={canChooseCountry}
                  isOpenCountry={isOpenCountry}
                  setIsOpenCountry={setIsOpenCountry}
                />
              ) : (
                // slot 插槽实例， 实现在注册 email上的QC字样 嵌入, 传入的是<div>标签
                props.prefix
              )}
              {/* <slot name="prefix"></slot> */}
            </>
          }
          suffix={
            <>
              {/* seed code */}
              {props.isClick && (
                <div className={'right-send'} onClick={sendCode}>
                  {textRef}
                </div>
              )}
              {/* password */}
              {props.inputType === 'password' && (
                <div className={cn(showEye && 'show', 'eye-com')} onClick={() => setShowEye(!showEye)}></div>
              )}
              {/* clear */}
              {props.value && props.value.length > 0 && needClear && !props.disabled && (
                <div onClick={() => props.updateValue('')} className={'ac-clear-img'} />
              )}
              {/* <slot name="suffix"></slot> */}
            </>
          }
        />
        {/* 未收到验证码-联系客服 */}
        {props.isClick && !props.hideContact && (
          <p className={'send-tips'} onClick={contactCS}>
            {t('user.authDialog.sendTips')}
          </p>
        )}

        <CSSTransition
          timeout={220}
          unmountOnExit
          in={props.needEmail && completeList.length > 0}
          classNames={'fade'}
        >
          <div className={'email-drop-dwon'}>
            {completeList.map((item) => (
              <div
                className={'chose-item'}
                key={item.value}
                onClick={() => setInputEmail(item.value)}
                dangerouslySetInnerHTML={{ __html: item.html }}
              ></div>
            ))}
          </div>
        </CSSTransition>
      </div>
    </>
  )
})

export default memo(ACInput)
