'use client'
import { ReactNode, memo } from 'react'
import { Layout, FloatButton } from 'antd'
import { useAppStore } from '@/stores/app'
import { cn } from '@/lib/utils'
import styles from './_common_css/index.module.scss'
// *com
import Header from './header'
import Footer from './footer'
import Tabbar from './tabbar'
import Navbar from './sidebar'

const MobileLayout = ({ children }: { children: ReactNode }) => {
  const language = useAppStore((state) => state.language)
  return (
    <Layout
      className={cn(
        // 'app-content',
        // 'usable-header',
        // // {/* // 'usable-header': null, // 'usable-header': !$route.meta.hideHeader,  // 待处理 */}
        // language,
        // 'h5'
        styles['app-content'],
        styles['usable-header'],
        // {/* // 'usable-header': null, // 'usable-header': !$route.meta.hideHeader,  // 待处理 */}
        styles[language]
        // styles['h5']
      )}
    >
      <Header />
      {children}
      <Footer />
      {/* 底部边栏和侧边栏 */}
      <Tabbar />
      <Navbar />
      <FloatButton.BackTop className="!mb-[22px]" />
    </Layout>
  )
}

export default memo(MobileLayout)
