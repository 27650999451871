import { useState, FC, useEffect, useRef, memo } from 'react'
import { Modal, Button } from 'antd'
import { useTranslation } from '@/lib/locales/client'
import styles from './_common_css/ValidDataPopup.module.scss'
import { px2Scalepx } from '@/hooks/useRem'
import ACInput from '@/components/base/acInput'
import validtor from '@/hooks/validtor'
import { phoneSendCodeByName, sendCodeByEmail, sendCodeByName, sendCodeByPhone } from '@/api/account'

type IProps = {
  updateValue?: (val: boolean) => void
  visible?: boolean // false
  purpose: number // true
  validData: any // object
  loginName: any | string // 为了兼容未登录之前获取不到用户信息
  isUseToLogin: boolean // false 登录二次验证兼容
  verify?: any
}

const ValidDataPopup: FC<IProps> = (props) => {
  // const emits = defineEmits(["update:visible", "showLost2faPopup", "toLogin"])
  const { t } = useTranslation()
  // const [dialogFlag, setDialogFlag] = useState(false)
  useEffect(() => {
    // props.updateValue(props.visible)
  }, [props.visible])

  // const emits = defineEmits(["verify"])

  // const dialogFlag = ref(true)
  // const code = ref("")
  // const errMsg = ref("")
  const codeInputRef = useRef<any>(null)
  const [code, setCode] = useState('')
  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    codeInputRef.current?.sendCode()
  }, [])

  function handleVerify() {
    if (errMsg) return
    // 待处理
    // emits("verify", code.value)
  }

  function checkCode() {
    const reg = /^[A-Za-z0-9]{6}$/
    const val = code
    if (!val) {
      setErrMsg(t('user.lostPop.phone_register_33'))
      return
    }

    if (!reg.test(val)) {
      setErrMsg(t('user.lostPop.phone_register_34'))
      return
    }
    setErrMsg('')
  }

  async function startVerify() {
    const params: any = {
      loginName: props.loginName,
      purpose: props.purpose
    }
    if (props.validData.verifyType === 'email') {
      if (props.isUseToLogin && validtor.email(params.loginName)) {
        params.email = params.loginName
        delete params.loginName
        await sendCodeByEmail(params)
      } else {
        await sendCodeByName(params)
      }
    } else {
      if (!props.isUseToLogin) {
        await phoneSendCodeByName(params)
      } else {
        await sendCodeByPhone(params)
      }
    }
    codeInputRef.current?.startCount()
  }

  return (
    <Modal
      open={props.visible}
      centered
      footer={null}
      keyboard={false}
      width={px2Scalepx(420)} // 待处理
      zIndex={2001}
      className={styles['valid_email_dialog']}
      // onCancel={$emit('close')} // 待处理
    >
      {props.validData.verifyType === 'email' ? (
        <div className="valid-data-popup-title">
          <div className="title">{t('user.lostPop.haveSendCodeEmail')}</div>
          <div className="send-to">{props.validData.email}</div>
        </div>
      ) : (
        <div className="valid-data-popup-title">
          <div className="title">{t('user.lostPop.haveSendCodePhone')}</div>
          <div className="send-to">{`+${props.validData.phonePrefix} ${props.validData.number}`}</div>
        </div>
      )}
      <div className="verification-label">{t('user.loginSafe.safeVerify')}</div>
      <ACInput
        ref={codeInputRef}
        value={code}
        placeholder={t('user.lostPop.phone_register_32')}
        needClear={false}
        isAllow
        isClick
        noChinese
        onlyNumber
        // 待处理
        // input={checkCode}
        // codeSend={startVerify}
      />
      <p className="err">{errMsg}</p>
      <Button type="primary" disabled={!!errMsg || !code} onClick={handleVerify}>
        {t('exclusion.btn')}
      </Button>
    </Modal>
  )
}

export default memo(ValidDataPopup)
