import { useState, FC, useEffect, memo } from 'react'
import ACInput from '@/components/base/acInput'
import { useTranslation } from '@/lib/locales/client'
import styles from './_common_css/verify2fa.module.scss'
import { px2Scalepx } from '@/hooks/useRem'
import { Modal, Button } from 'antd'

type IProps = {
  updateValue: (val: boolean) => void
  toLogin: any
  visible: boolean // false
}

const Verify2fa: FC<IProps> = (props) => {
  // const emits = defineEmits(["update:visible", "showLost2faPopup", "toLogin"])
  const { t } = useTranslation()
  // const [dialogFlag, setDialogFlag] = useState(false)
  const [code, setCode] = useState('')
  const [errMsg, setErrMsg] = useState('')

  // const emits = defineEmits(["update:visible"])
  useEffect(() => {
    // props.updateValue(props.visible)
  }, [props.visible])

  // const code = ref("")
  // const errMsg = ref("")

  function checkCode() {
    const reg = /^[A-Za-z0-9]{6}$/
    const val = code
    if (!val) {
      setErrMsg(t('user.lostPop.phone_register_33'))
      return
    }

    if (!reg.test(val)) {
      setErrMsg(t('user.lostPop.phone_register_34'))
      return
    }
    setErrMsg('')
  }

  function handleLogin() {
    if (errMsg) return
    // 待处理
    // emits("toLogin", code.value)
  }

  return (
    <Modal
      open={props.visible}
      centered
      footer={null}
      keyboard={false}
      width={px2Scalepx(400) + 'px'}
      className={styles['authenticationVerify']}
      zIndex={2001}
    >
      <h4>{t('user.lostPop.v2fTitle')}</h4>
      <ACInput
        needClear={false}
        notSpecial
        notSpace
        max-length={6}
        placeholder={t('user.lostPop.phone_register_32')}
        value={code}
        // blur={checkCode}  // 待处理
      />
      <p className="err">{errMsg}</p>
      <Button className="submitBtn" disabled={Boolean(errMsg)} type="primary" onClick={handleLogin}>
        {t('bets.confirm')}
      </Button>
      <p
        className="lost2fa"
        // onClick={emits('showLost2faPopup')} // 待处理
      >
        {t('user.lostPop.phone_register_8')}?
      </p>
    </Modal>
  )
}

export default memo(Verify2fa)
