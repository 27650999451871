// H5端左侧栏
'use client'
import { useState, useEffect, useMemo, memo } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { CSSTransition } from 'react-transition-group'
// import { useRouter } from 'next/router'
import { useRouter } from 'next/navigation'
// import Config from "@/config/sidebar"
// import Langs from "@/config/langs"
// import { numberToKUnit } from "@/lib/filter"
// import { getLevelColor } from "@/config/level"
import { jumpURL } from '@/lib/jumpURL'
import ConfigBase from '@/config/base'
import { track } from '@/hooks/useTrack'
import styles from '../_common_css/sidebar.module.scss'
import { useShallow } from 'zustand/react/shallow'
// com
import QueryLang from '@/components/base/queryLang'
import GameProvideSearch from '@/components/game/gameProvideSearch'

const NavPage = () => {
  const { t } = useTranslation()
  const {
    mainTheme,
    isShowSideNav,
    globalPublickKey,
    setIsShowSideNav,
    setIsShowAddToHome,
    updateCmsTemplate,
    setIsShowNotification
  } = useAppStore(
    useShallow((state) => ({
      mainTheme: state.mainTheme,
      isShowSideNav: state.isShowSideNav,
      globalPublickKey: state.globalPublickKey,
      setIsShowSideNav: state.setIsShowSideNav,
      setIsShowAddToHome: state.setIsShowAddToHome,
      updateCmsTemplate: state.updateCmsTemplate,
      setIsShowNotification: state.setIsShowNotification
    }))
  )
  // accountTransferRecord 待处理
  const { userInfo, kycAuditStatus, vipInfo, userLogout, isLogin } = useUserStore(
    useShallow((state) => ({
      userInfo: state.userInfo,
      kycAuditStatus: state.kycAuditStatus,
      vipInfo: state.vipInfo,
      userLogout: state.userLogout,
      isLogin: state.isLogin
    }))
  )
  const openLogin = useAuthStore((state) => state.openLogin)
  const router = useRouter()
  // const searchParams = useSearchParams()
  // const { lng } = useParams()
  const [menuTopEntrance, setMenuTopEntrance] = useState([])
  // const [menuData, setMenuData] = useState([])
  const [menuListAll, setMenuListAll] = useState([])
  const [menuList7, setMenuList7] = useState([])
  const [menuList8, setMenuList8] = useState([])
  const [bettingTopEntrance, setBettingTopEntrance] = useState<any>()

  // 原项目 直接合并1-6的菜单，这里单独处理下，减少重复代码，待观察
  // const allList = [menuList1, menuList2, menuList3, menuList4, menuList5, menuList6]
  const updateMenuList = (menuData) => {
    let allMenuList = [...Array(5)].fill(null) // 初始化6个空数组
    for (let i = 0; i < allMenuList.length; i++) {
      allMenuList[i] = menuData.filter(
        (item) => item.templateComment && item.templateComment.includes(`menuList${i + 1}`)
      )
    }
    return allMenuList
  }

  const queryImage = useMemo(() => {
    return menuList7[0]?.mediaLibrary[0]?.fullFilePath || ''
  }, [])

  const isCompleteVerify = useMemo(() => {
    return (
      !!userInfo.firstNameJapanese &&
      !!userInfo.lastNameJapanese &&
      !!userInfo.mobile &&
      !!userInfo.email &&
      kycAuditStatus === 2
    )
  }, [])

  const progress = useMemo(() => {
    let num = vipInfo.isMaxLevel ? 100 : vipInfo.progress || 0
    num = num > 100 ? 100 : num < 0 ? 0 : num
    return Math.abs(num)
  }, [])

  // const isActiveIcon = (item) => {
  //   return item.name === language
  // }
  const getPopupContainer = () => {
    return document.querySelector('.nav_page_wrap')
  }
  const onOpenAddToHome = () => {
    setIsShowSideNav(false)
    setIsShowAddToHome(true)
  }
  const logoutUser = () => {
    setIsShowSideNav(false)
    userLogout()
  }
  const getSubMenuCode = (str) => {
    if (str && str.includes('【')) {
      const matchData = str.match(/【([^】]+)】/)
      return matchData?.[1] || ''
    } else {
      return ''
    }
  }

  const openSearch = () => {
    setIsShowSideNav(false)
    // const params = new URLSearchParams(searchParams.toString())
    // params.set('sort', sortOrder)
    // window.history.pushState(null, '', `?${params.toString()}`)
    // window.history.pushState(null, '', `${lng}/search-game`)

    router.push('/search-game')
    // router.push(
    //   {
    //     pathname: '/searchGame'
    //   },
    //   undefined,
    //   { shallow: true }
    // )
  }

  const clickUser = () => {
    if (isLogin) {
      if (isCompleteVerify) {
        router.push('/account')
      } else {
        router.push('/account/verification')
      }
      setIsShowSideNav(false)
    }
  }
  const toPage = (path) => {
    if (path) {
      setIsShowSideNav(false)
      if (path === '/account/deposit') track('RechargeClick')
      jumpURL(path)
    }
  }

  const isShowTransferBtn = useMemo(() => {
    // && accountTransferRecord 待处理
    return !userInfo?.transferStatus && userInfo?.userSource === 'QC_TRANSFER'
  }, [])

  useEffect(() => {
    if (globalPublickKey) {
      // if (globalPublickKey && !isBigScreen) {
      queryNavigation()
    }
  }, [globalPublickKey])

  useEffect(() => {
    if (isShowSideNav) {
      document.body.classList.add('nav-page-no-scroll')
    } else {
      document.body.classList.remove('nav-page-no-scroll')
    }
  }, [isShowSideNav])

  const queryNavigation = () => {
    updateCmsTemplate({
      moduleCodeList: ['headerNavigation', 'menuTopEntrance', 'bettingTopEntrance'],
      themeCode: mainTheme === 'red' ? ConfigBase.themeCode : undefined
    }).then((data) => {
      // console.log('获取侧栏数据', data)
      const dataList = data
        .filter((item) => item.moduleCode === 'headerNavigation')
        .map((item) => {
          const subMenuCode = getSubMenuCode(item.templateComment)
          return {
            ...item,
            subMenuCode,
            subMenuList: [],
            isShowSubMenu: false
          }
        })
      const MenuTopFilter = data
        .filter((item, index) => item.moduleCode === 'menuTopEntrance')
        .filter((item, index) => index < 2)
      setMenuTopEntrance(MenuTopFilter)
      const betTop = data.filter((item, index) => item.moduleCode === 'bettingTopEntrance')?.[0]
      // console.log('读取图片', betTop)
      setBettingTopEntrance(betTop)
      const subMenuCodeList = dataList.map((item) => item.subMenuCode).filter((item) => item)
      updateCmsTemplate({
        moduleCodeList: subMenuCodeList,
        themeCode: mainTheme === 'red' ? ConfigBase.themeCode : undefined
      }).then((secondCmsData) => {
        // 原代码改成map方式， 待观察
        const secondMenu = dataList.map((item) => ({
          ...item,
          subMenuList:
            item.subMenuCode && secondCmsData.filter((item) => item.moduleCode === item.subMenuCode)
        }))
        // for (let menu of secondMenu) {
        //   const subMenuCode = menu.subMenuCode
        //   if (subMenuCode) {
        //     const subMenuList = data.filter((item) => item.moduleCode === subMenuCode)
        //     menu.subMenuList = subMenuList
        //   }
        // }
        // setMenuData(secondMenu)
        // 更新全列表代码
        const allMenuList = updateMenuList(secondMenu)
        setMenuListAll(allMenuList)
        // 特殊处理 menu7和8 显示语言和退出
        setMenuList7(
          secondMenu.filter((item) => item.templateComment && item.templateComment.includes('menuList7'))
        )
        setMenuList8(
          secondMenu.filter((item) => item.templateComment && item.templateComment.includes('menuList8'))
        )
      })
    })
  }

  const formatImg = (item) => {
    let src = ''
    if (item.isPrivate) {
      src = `/static/images/common/${item.icon}`
    } else if (item.mediaLibrary[0]) {
      src = item.mediaLibrary[0].fullFilePath
    }
    return src
  }

  const clickHandle = (menu) => {
    // console.log('点击菜单', menu)
    if (menu.templateAction === '/livechat') {
      onOpenChat()
      setIsShowSideNav(false)
      return
    }
    if (menu.templateAction === '/Add To Home Screen') {
      onOpenAddToHome()
      setIsShowSideNav(false)
      return
    }
    if (menu.subMenuCode && menu.subMenuList && menu.subMenuList.length > 0) {
      menu.isShowSubMenu = !menu.isShowSubMenu
      return
    }

    const path = menu.templateAction

    if (path === 'showNotification') {
      setIsShowSideNav(false)
      setIsShowNotification(true)
      return
    }

    if (!path) return
    if (!isLogin) {
      const pathList = ['/account', '/sports-bet']
      if (pathList.includes(path)) {
        openLogin()
        setIsShowSideNav(false)
        return
      }
    }

    jumpURL(path)
    setIsShowSideNav(false)
  }

  // 切换语言 待处理
  // const changeLanguageHandle = (item) => {
  //   changeLanguage(item.name)
  // }

  const onOpenChat = () => {
    window.Intercom('show')
  }

  return (
    <>
      <CSSTransition timeout={220} in={isShowSideNav} unmountOnExit classNames={styles['slide-right']}>
        <div className={styles['menu-container']}>
          <div className={'nav_page_wrap'}>
            <div className={cn('menu-list-container', !isLogin && 'no-login')}>
              <div className={'close-box'}>
                <img
                  className={'close_btn'}
                  src="/static/images/home/memberDetail/close.svg"
                  alt=""
                  onClick={() => setIsShowSideNav(false)}
                />
              </div>
              {bettingTopEntrance && (
                <div
                  onClick={() => toPage(bettingTopEntrance.templateAction)}
                  className={'betting-top-entrance'}
                >
                  <img src={bettingTopEntrance?.mediaLibrary[0].fullFilePath} alt="" />
                </div>
              )}
              {menuTopEntrance.length > 0 && (
                <div className={'menu-list-top-entrance'}>
                  {menuTopEntrance.map((entrance) => (
                    <div
                      key={entrance.templateId}
                      style={{
                        backgroundImage: entrance.mediaLibrary[0]
                          ? `url(${entrance.mediaLibrary[0].fullFilePath})`
                          : ''
                      }}
                      onClick={() => toPage(entrance.templateAction)}
                      className={'menu-entrance-item'}
                    ></div>
                  ))}
                </div>
              )}

              <div className={'navpage-seach'} onClick={openSearch}>
                <div className={'mask'}></div>
                {/* 待处理 */}
                <GameProvideSearch inputText={t('home.game.barInput')} />
              </div>
              {menuListAll
                .filter((item) => item.length > 0)
                .map((menuList, index) => (
                  <div key={index} className={'menu-list-entrance'}>
                    {menuList.map((menu) => (
                      <div
                        className={cn(
                          'menu-list-entrance-item',
                          menu.mediaLibrary[0] && 'has-icon',
                          menu.subMenuCode && menu.subMenuList && menu.subMenuList.length > 0 && 'has-list'
                        )}
                        key={menu.templateId}
                      >
                        <div onClick={() => clickHandle(menu)} className={'menu-list-entrance-item-content'}>
                          {menu.mediaLibrary[0] && (
                            <img src={menu.mediaLibrary[0].fullFilePath} className={'img'} />
                          )}
                          <span>{menu.title}</span>
                          {menu.mediaLibrary[1] && (
                            <img
                              src={menu.mediaLibrary[1].fullFilePath}
                              className={'img'}
                              style={{ marginLeft: '5px' }}
                            />
                          )}

                          {menu.subMenuCode && menu.subMenuList && menu.subMenuList.length > 0 && (
                            <img
                              className={'menu-arrow'}
                              src={`/static/images/theme-red/i-${menu.isShowSubMenu ? 'minus' : 'plus'}.png`}
                            />
                          )}
                        </div>

                        {menu.subMenuCode && menu.subMenuList && menu.subMenuList.length > 0 && (
                          <div className={'sub-menu'}>
                            <div className={'menu-list'}>
                              <CSSTransition
                                timeout={220}
                                in={menu.subMenuList.length > 0}
                                unmountOnExit
                                classNames={'fade'}
                              >
                                {menu.subMenuList
                                  .filter((item) => menu.isShowSubMenu)
                                  .map((subMenu) => (
                                    <div
                                      key={subMenu.templateId}
                                      onClick={() => clickHandle(subMenu)}
                                      className={'sub-menu-item'}
                                    >
                                      {subMenu.title}
                                      {(subMenu.title === 'Baccarat' || subMenu.title === 'バカラ') && (
                                        <img
                                          className={'jackpot'}
                                          src="/static/images/theme-red/meau_jackpot.png"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  ))}
                              </CSSTransition>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}

              {menuList7.length > 0 && (
                <QueryLang getPopupContainer={getPopupContainer} queryImage={queryImage} />
              )}

              {isLogin && menuList8.length > 0 && (
                <div onClick={logoutUser} className={'log-out'}>
                  <img src={menuList8[0]?.mediaLibrary[0]?.fullFilePath} alt="" className={'icon'} />
                  <span>{t('common.account.logOut')}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export default memo(NavPage)
