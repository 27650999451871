'use client'
import { memo, useRef, useState, useLayoutEffect, useEffect } from 'react'
import { useSetState } from 'ahooks'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { useTranslation } from '@/lib/locales/client'
import { cn } from '@/lib/utils'
import { Form, Input, Button } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { useRouter, usePathname } from 'next/navigation'
// import Forms from '../../_datasets/login'
import { useClickAway } from 'ahooks'
import Validtor from '@/hooks/validtor'
import useCountDown from '@/hooks/useCountDown'
import { CSSTransition } from 'react-transition-group'
/* encrypt */
import { Decrypt, Encrypt } from '@/hooks/encrypt'
import { logApi } from '@/api/auth'
import { sendCodeByPhone } from '@/api/account'
import { useShallow } from 'zustand/react/shallow'
import styles from '../_common_css/login.module.scss'
// com
// import ACInput from '@/components/base/acInput'
import SliderCaptcha from '@/components/common/sliderCaptcha'
import LockedDialog from '../../_components/lockedDialog'
// import ValidDataPopup from '../../_components/ValidDataPopup'
import CountrySelect from '@/components/base/countrySelect'
import { openGame } from '../../_components/startGame'

/* init */
const P_B_KEY = 'J1PTY42LFGZAYBSS'
const P_REM_STR = 'Q_C_I_W'
const P_REM_STR2 = 'Q_C_I_W_N'
// login菜单切换
const loginMenuList = [
  { label: 'user.authDialog.logPW', type: 'PWLogin' },
  { label: 'user.authDialog.logCode', type: 'CodeLogin' }
]
//  邮箱添加自动补全后缀
// const complateEmailEndList = ['@gmail.com', '@outlook.com', '@yahoo.com', '@icloud.com']

const Login = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const pathName = usePathname()
  const rawPath = pathName.replace(/en|ja(\/)?/g, '')
  const {
    commonConfig,
    limitLogin,
    countryCurInfo,
    updateLimitLoginDialog
    // changeLanguage,
    // language,
    // setDialogType
  } = useAppStore(
    useShallow((state) => ({
      commonConfig: state.commonConfig,
      limitLogin: state.limitLogin,
      countryCurInfo: state.countryCurInfo,
      updateLimitLoginDialog: state.updateLimitLoginDialog
      // changeLanguage: state.changeLanguage,
      // language: state.language,
      // setDialogType: state.setDialogType
    }))
  )
  const { logRegVisible, closeModal } = useAuthStore(
    useShallow((state) => ({
      // openRegister: state.openRegister,
      logRegVisible: state.logRegVisible,
      closeModal: state.closeModal
    }))
  )
  const { setWebToken, updateUserInfo, setSeonSession, userInfo, fingerPrintID } = useUserStore(
    useShallow((state) => ({
      setWebToken: state.setWebToken,
      updateUserInfo: state.updateUserInfo,
      setSeonSession: state.setSeonSession,
      userInfo: state.userInfo,
      fingerPrintID: state.fingerPrintID
    }))
  )
  const [loginModalType, setLoginModalType] = useState('PWLogin')
  const [form] = Form.useForm()
  const { textRef, start, flag } = useCountDown(60, t('user.authDialog.send'), '')
  const [remberLog, setRemberLog] = useState(true)
  const [showSliderCaptchaPopup, setShowSliderCaptchaPopup] = useState(false)
  const [lockVisibility, setLockVisibility] = useState(false)
  // const [showVerifyCodePopup, setShowVerifyCodePopup] = useState(false)
  // const [verifyNum, setVerifyNum] = useState('')
  // const [validData, setValidData] = useSetState({ verifyType: '', email: '', phonePrefix: '', number: null })
  const [verifyParams, setVerifyParams] = useSetState({
    // 包含验证码的pwbd
    // authenticationCode: '',
    // grantType: '',
    // phone: '',
    captcha: '',
    captchaId: '',
    captchaType: null
  })
  const selectAreaRef = useRef(null) // 选择国家搜索
  const [isOpenCountry, setIsOpenCountry] = useState(false)

  // 监听地区选择器 开关
  useEffect(() => {
    isOpenCountry ? selectAreaRef.current?.focus() : selectAreaRef.current?.blur()
  }, [isOpenCountry])

  // 点地区选择器面板以外的事件
  useClickAway(
    () => setIsOpenCountry(false), // 当选中地区选择面板以外时，关闭该面板,
    () => document.getElementById('country-select')
  )

  const focusHandle = (evt) => {
    // console.log('输入聚焦', evt)
    // 获取焦点时 判断地区选择是否有打开
    isOpenCountry ? selectAreaRef.current?.focus() : selectAreaRef.current?.blur()
  }
  // const completeList = useMemo(() => {
  //   const emailValue = form.getFieldValue('namePhoneEmail')
  //   // console.log('email 值', emailValue)
  //   if (emailValue) {
  //     // let list: any = complateEmailEndList
  //     return complateEmailEndList
  //       .map((evt) => {
  //         let autoCompleteEmail =
  //           emailValue.indexOf('@') != -1
  //             ? `${emailValue.slice(0, emailValue.indexOf('@'))}${evt}`
  //             : `${emailValue}${evt}`
  //         if (autoCompleteEmail.includes(emailValue)) {
  //           return {
  //             html: `<span style="color: #fc005b">${emailValue}</span>${autoCompleteEmail.replace(emailValue, '')}`,
  //             value: autoCompleteEmail
  //           }
  //         } else {
  //           return {
  //             html: '',
  //             value: ''
  //           }
  //         }
  //       })
  //       .filter((e) => e.value !== '' && e.value !== emailValue)
  //     // return list
  //   } else {
  //     return []
  //   }
  // }, [form.getFieldValue('namePhoneEmail')])
  // }, [inputValue])

  // useEffect(() => {
  useLayoutEffect(() => {
    if (logRegVisible && loginModalType === 'PWLogin') {
      try {
        // 待观察， 原用nextTick
        // nextTick(() => {
        let _ulockPW = localStorage.getItem(P_REM_STR)
        let _ulockUN = localStorage.getItem(P_REM_STR2)
        _ulockPW = JSON.parse(_ulockPW)
        _ulockUN = JSON.parse(_ulockUN)
        /*  */
        _ulockPW = decrypt(_ulockPW) || ''
        _ulockUN = self.atob(_ulockUN) || ''
        if (_ulockPW) {
          form.setFieldsValue({ userName: _ulockUN, password: _ulockPW })
          // formState[loginModalType].userName = _ulockUN
          // formState[loginModalType].password = _ulockPW
        }
        // })
      } catch (e) {
        console.log(e)
      }
    }
  }, [logRegVisible])

  // 切换两种不同的登录认证方式
  // async function continueLogin(val) {
  //   setVerifyNum(val)
  //   await onFinish(form.getFieldsValue())
  // }

  // async function finishVerify(val) {
  //   setVerifyNum(val)
  //   // loginIn(1)  // 待处理
  //   await onFinish(form.getFieldsValue(), 1)
  // }

  // 切换不同的登陆方式 pwd, code
  function chooseType(item) {
    setLoginModalType(item.type)
    onReset()
  }

  const contactCS = () => {
    window.Intercom('show')
  }

  // 读取用户环境语言, 应该是登录完成后 拿用户存储的语言跳转
  function setLangQuery() {
    const { lang } = userInfo || {}
    // if (language !== lang) changeLanguage(lang)
    // 直接url跳转不同语言
    // console.log('跳转用户语言环境', lang)
    const loc = lang === 'en-US' ? 'en' : 'ja'
    router.push(`/${loc}/`)
  }

  // 记住密码
  function handleRember(params) {
    const nameRem = self.btoa(params.loginName)
    const pawRem = encryptParams(params.password)
    try {
      if (remberLog) {
        localStorage.setItem(P_REM_STR, JSON.stringify(pawRem))
        localStorage.setItem(P_REM_STR2, JSON.stringify(nameRem))
      } else {
        localStorage.removeItem(P_REM_STR)
        localStorage.removeItem(P_REM_STR2)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /* 忘记密码 */
  function handForgot() {
    // 待观察 H5需先跳转路径后才能关闭登录窗口，否则跳转失效
    closeModal()
    router.push('/resetPassword')
  }

  /* ---------------验证相关-start----------- */
  async function captchaCallback(params) {
    // setShowSliderCaptchaPopup(false)
    // setVerifyParams(params)
    await accountLogin(params)
    // await onFinish(form.getFieldsValue())
  }

  // 发送验证号
  function sendPhoneVerify(evt) {
    evt.stopPropagation()
    if (!flag || !form.getFieldValue('namePhoneEmail')) return
    sendCodeByPhone({
      purpose: 2,
      phonePrefix: '00' + countryCurInfo.code,
      phone: form.getFieldValue('namePhoneEmail')
    }).then((res: any) => {
      if (res.success) start() // 开始60秒倒计时
    })
  }

  /* 记住密码-加解密 */
  function encryptParams(data) {
    return Encrypt(data, P_B_KEY)
  }
  function decrypt(data) {
    try {
      return Decrypt(data, P_B_KEY)
    } catch (error) {
      return ''
    }
  }

  // 设置谷歌埋点
  // function onSetGoogle() {
  //   window.dataLayer.push({
  //     ClientID: fingerPrintID,
  //     event: 'form.started'
  //   })
  // }

  // 账密登录
  async function accountLogin(verifyData = {}) {
    const { userName, password } = form.getFieldsValue(['userName', 'password'])
    // verifyType 0 正常登录  1 邮箱或手机二次验证
    // if (Number(verifyType === 0)) {
    //   setValidData({ verifyType: '' })
    // }
    // 判断类型是用户名还是邮箱, 2邮箱, 1账号
    const sendType = Validtor.email(form.getFieldValue('userName')) ? 2 : 1
    // const sliderVerifyData = verifyParams.captchaId ? verifyParams : {}

    // 请求参数封装
    const params = {
      ...verifyData,
      loginName: sendType === 1 ? userName.toLowerCase() : userName,
      password,
      loginType: sendType,
      grantType: 'web_password'
    }

    // 加验证码登录, 二次手机邮箱验证登录不需要传password
    // if (verifyType === 1 && validData.verifyType) {
    //   // params.authenticationCode = verifyNum
    //   params.grantType = validData.verifyType === 'email' ? 'email2fa' : 'phone2fa'
    //   if (validData.verifyType === 'phone') {
    //     params.phone = params.loginName
    //   }
    // } else {
    //   params.password = password
    // }

    // 请求登录接口
    const res: any = await logApi({
      merchantCode: commonConfig.merchantCode,
      ...params
    })
    if (res && res.success) {
      // console.log('登录数据?', res)
      // 设置用户信息
      setWebToken(res.data)
      await updateUserInfo(res.data.loginName, 1)
      closeModal()
      setSeonSession()
      if (location.href.includes('/sports-bet')) {
        openGame(0, {
          platformCode: 'HUB88',
          gameId: window.location.href.includes('uat') ? 'btsg_sportsbetting' : 'btsg_sportbetting'
        })
      }

      // 待处理 体育页面如果重新路由，暂时不重载，待观察
      // const { pageReload } = inject('RELOAD')
      if (rawPath === '/fb') {
        router.refresh()
        // if (location.href.includes('/fb')) {
        // pageReload()
      }

      // 埋点插件 待处理
      window.dataLayer.push({
        UsernameID: res.data.loginName,
        SocialChannelID: '',
        event: 'login.success'
      })

      /* 缓存 */
      loginModalType === 'PWLogin' && handleRember(params)
      setLangQuery() // 切换语言
    } else {
      switch (res.code) {
        //  应该是给注册用的， 这里没有可以填邮箱验证码的地方
        // case 101808:
        //   setValidData({
        //     verifyType: 'email', // email || phone 类型
        //     email: res.data // email || phone
        //   })
        //   setShowVerifyCodePopup(true)
        //   break
        // case 101809:
        //   setValidData({
        //     verifyType: 'phone', // email || phone 类型
        //     phonePrefix: '',
        //     number: res.data // phone
        //   })
        //   setShowVerifyCodePopup(true)
        //   break
        case 100701: // 再次登录时需要弹出滑块验证
          // this.$refs.sliderCaptcha && this.$refs.sliderCaptcha.refresh()
          setShowSliderCaptchaPopup(true)
          break
        case 100700: // 已被锁定账号
          setLockVisibility(true)
          break
      }
    }
  }

  // 手机登录
  async function phoneLogin() {
    const { namePhoneEmail, codeNum } = form.getFieldsValue(['namePhoneEmail', 'codeNum'])
    const phoneParams = {
      phone: namePhoneEmail,
      grantType: 'phone2fa',
      authenticationCode: codeNum,
      phonePrefix: '00' + countryCurInfo.code
    }
    const result: any = await logApi({ merchantCode: commonConfig.merchantCode, ...phoneParams })
    if (result && result.success) {
      // console.log('返回请求token', result)
      setWebToken(result.data)
      await updateUserInfo(result.data.loginName, 1)
      closeModal()
      window.dataLayer.push({
        UsernameID: result.data.loginName,
        SocialChannelID: '',
        event: 'login.success'
      })
    } else {
      switch (result.code) {
        case 100700: // 已锁定账号，稍后再试
          setLockVisibility(true)
          break
      }
    }
  }

  // 开始登录操作
  const onFinish = async (values: any) => {
    // console.log('Received values of form: ', values)
    // console.log('开始提交', limitLogin, loginModalType)
    if (limitLogin) {
      updateLimitLoginDialog(true)
      return
    }
    if (loginModalType === 'PWLogin') {
      await accountLogin()
    } else {
      await phoneLogin()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const onReset = () => {
    form.resetFields()
  }

  return (
    <>
      <div className={cn(styles['user-login-modal'], styles['h5'])}>
        <div className={'menu-list'}>
          {loginMenuList.map((item, index) => (
            <span
              key={index}
              className={cn(loginModalType == item.type && 'active-menu')}
              onClick={() => chooseType(item)}
            >
              {t(item.label)}
            </span>
          ))}
        </div>
        {/* content */}
        <Form
          form={form}
          validateTrigger="onBlur"
          layout="vertical"
          // initialValues={{ remember: loginModalType === 'PWLogin' ? true : false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {loginModalType === 'PWLogin' ? (
            <>
              <Form.Item
                name="userName"
                className="mb-[26px]"
                required={true}
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: async (_rule, names) => {
                      if (!names) {
                        return Promise.reject(new Error(t('common.validtor.usenameEpty')))
                      } else if (names) return Promise.resolve()
                    }
                  },
                  { pattern: /[^\u4E00-\u9FA5]/g, message: t('common.validtor.nameErr') } // noChinese
                ]}
              >
                <Input
                  placeholder={t('user.authDialog.userNamePlac')}
                  minLength={4}
                  maxLength={32}
                  allowClear
                />
              </Form.Item>
              {/* 密码 */}
              <Form.Item
                name="password"
                className="mb-[26px]"
                required={true}
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: async (_rule, names) => {
                      if (!names) {
                        return Promise.reject(new Error(t('common.validtor.passwordEpty')))
                      }
                      if (!Validtor.password(names)) {
                        return Promise.reject(new Error(t('common.validtor.passwordError')))
                      }
                      return Promise.resolve()
                    }
                  },
                  { pattern: /[^\u4E00-\u9FA5]/g, message: t('common.validtor.passwordError') } // noChinese
                ]}
              >
                <Input.Password
                  placeholder={t('user.authDialog.authPassword')}
                  minLength={4}
                  maxLength={36}
                  allowClear
                  iconRender={(visible) =>
                    visible ? (
                      <EyeTwoTone style={{ marginLeft: '10px' }} />
                    ) : (
                      <EyeInvisibleOutlined style={{ marginLeft: '10px' }} />
                    )
                  }
                />
              </Form.Item>

              {/* 记住账密 */}
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <div className={'rember-account'}>
                  <div className={'rember-check'} onClick={() => setRemberLog(!remberLog)}>
                    <div className={cn('check-img', remberLog && 'active')}></div>
                    <span>{t('user.authDialog.rememberAcc')}</span>
                  </div>
                  <span onClick={handForgot}>{t('user.authDialog.forgotPS')}</span>
                </div>
              </Form.Item>
            </>
          ) : (
            <>
              {/* 验证码登录 */}
              <Form.Item
                name="namePhoneEmail"
                className="mb-[26px]"
                required={true}
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: async (_rule, names) => {
                      if (!names) {
                        return Promise.reject(new Error(t('common.validtor.phoneEpty')))
                      } else {
                        return Promise.resolve()
                      }
                    }
                  }
                ]}
              >
                <Input
                  placeholder={t('user.authDialog.codePlace')}
                  type="number"
                  onFocus={focusHandle}
                  prefix={
                    <CountrySelect
                      domId={'country-select'}
                      ref={selectAreaRef}
                      isOpenCountry={isOpenCountry}
                      setIsOpenCountry={setIsOpenCountry}
                    />
                  }
                />
              </Form.Item>
              <Form.Item
                name="codeNum"
                className="mb-[6px]"
                required={true}
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: async (_rule, names) => {
                      if (!names || names.length > 6) {
                        return Promise.reject(new Error(t('common.validtor.codeError')))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <>
                  <Input
                    placeholder={t('user.authDialog.authCodePlace')}
                    maxLength={6}
                    onChange={(e) => form.setFieldsValue({ codeNum: e.target.value.replace(/\D/gi, '') })}
                    suffix={
                      <div className={'right-send'} onClick={sendPhoneVerify}>
                        {textRef}
                      </div>
                    }
                  />
                </>
              </Form.Item>
              <Form.Item name="send-tips" noStyle>
                {/* 未收到验证码-联系客服  判断 待处理*/}
                {/* {!props.hideContact && ( */}
                <p className={cn('send-tips', '!relative mb-[32px]')} onClick={contactCS}>
                  {t('user.authDialog.sendTips')}
                </p>
                {/* )} */}
              </Form.Item>

              {/* 这个像自动填写邮件后缀 待处理 */}
              {/* <CSSTransition
                  timeout={220}
                  unmountOnExit
                  in={completeList.length > 0}
                  classNames={'fade'}
                >
                  <div className={'email-drop-dwon'}>
                    {completeList.map((item) => (
                      <div
                        className={'chose-item'}
                        key={item.value}
                        onClick={() => setInputEmail(item.value)}
                        dangerouslySetInnerHTML={{ __html: item.html }}
                      ></div>
                    ))}
                  </div>
                </CSSTransition> */}
            </>
          )}

          {/* 按钮提交 */}
          <Form.Item name="loginSumbit">
            <Button
              block
              className={cn('auth-btn-submit', form.getFieldValue('codeNum') && 'btn-active', 'h-auto')}
              type="primary"
              htmlType="submit"
            >
              {t('user.authDialog.dLogin')}
            </Button>
          </Form.Item>
        </Form>

        {/* 滑动验证模块 */}
        {showSliderCaptchaPopup && (
          <SliderCaptcha
            use={2}
            fullTxt={t('user.uLogin')}
            loginName={'qc' + form.getFieldValue('userName')}
            callback={captchaCallback}
            close={() => setShowSliderCaptchaPopup(false)}
          />
        )}

        {/* 邮箱验证 */}
        {/* {showVerifyCodePopup && (
          <ValidDataPopup
            isUseToLogin
            purpose={2}
            loginName={form.getFieldValue('userName')}
            validData={validData}
            verify={finishVerify}
          />
        )} */}

        {/* 账号锁定弹窗 */}
        <LockedDialog visible={lockVisibility} />
      </div>
    </>
  )
}

export default memo(Login)
