import { useState, useEffect, memo } from 'react'
import { cn } from '@/lib/utils'
import Configbase from '@/config/base'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { jumpURL } from '@/lib/jumpURL'
import { Autoplay, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/scrollbar'
import styles from '../_common_css/footPayment.module.scss'
import { useShallow } from 'zustand/react/shallow'

const FootPayment = () => {
  const { isBigScreen, globalPublickKey, updateCmsTemplate } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      globalPublickKey: state.globalPublickKey,
      updateCmsTemplate: state.updateCmsTemplate
    }))
  )
  const openLogin = useAuthStore((state) => state.openLogin)
  const isLogin = useUserStore((state) => state.isLogin)
  // const modules = [Autoplay, Scrollbar]
  const [footerpaymentData, setFooterpaymentData] = useState([])
  const [footerpaytypeData, setFooterpaytypeData] = useState([])
  const [footerfiatData, setFooterfiatData] = useState([])
  const [footergamesupplierData, setFootergamesupplierData] = useState([])

  const getData = () => {
    return updateCmsTemplate({
      moduleCodeList: ['footerpayment', 'footerpaytype', 'footerfiat', 'footergamesupplier'],
      themeCode: Configbase.themeCode
    }).then((res: any) => {
      if (res && res.length > 0) {
        setFooterpaymentData(res.filter((item: any) => item.moduleCode === 'footerpayment'))
        setFooterpaytypeData(res.filter((item: any) => item.moduleCode === 'footerpaytype'))
        setFooterfiatData(res.filter((item: any) => item.moduleCode === 'footerfiat'))
        setFootergamesupplierData(res.filter((item: any) => item.moduleCode === 'footergamesupplier'))
      }
    })
  }

  useEffect(() => {
    if (globalPublickKey) {
      getData()
    }
  }, [globalPublickKey])

  return (
    <>
      <div className={cn(styles['footpayment-warp'], styles[!isBigScreen && 'h5'])}>
        <div className={'footerpayment'}>
          {footerpaymentData.map((item, index) => (
            <img
              key={index}
              src={item.mediaLibrary[0]?.fullFilePath}
              onClick={() => (isLogin ? jumpURL(item.templateAction) : openLogin())}
            />
          ))}
        </div>
        <div className={'footerpaytype'}>
          {footerpaytypeData.map((item, index) => (
            <img
              key={index}
              src={item.mediaLibrary[0]?.fullFilePath}
              onClick={() => (isLogin ? jumpURL(item.templateAction) : openLogin())}
            />
          ))}
        </div>
        <div className={'footerfiat'}>
          {footerfiatData.map((item, index) => (
            <img
              key={index}
              src={item.mediaLibrary[0]?.fullFilePath}
              onClick={() => (isLogin ? jumpURL(item.templateAction) : openLogin())}
            />
          ))}
        </div>
        {footergamesupplierData.length < (isBigScreen ? 6 : 5) ? (
          <div className={'footergamesupplier'}>
            {footergamesupplierData.map((item, index) => (
              <img
                key={index}
                src={item.mediaLibrary[0]?.fullFilePath}
                onClick={() => jumpURL(item.templateAction)}
              />
            ))}
          </div>
        ) : (
          <section className={cn('footergamesupplier', 'footergamesupplier-swiper')}>
            <Swiper
              modules={[Autoplay, Scrollbar]}
              slidesPerView={isBigScreen ? 5 : 4}
              grabCursor
              spaceBetween={isBigScreen ? 6 : 2.5}
              scrollbar={{
                el: '.swiper-scrollbar'
              }}
              autoplay={{
                delay: 3000
              }}
            >
              {footergamesupplierData.map((item, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={item.mediaLibrary[0]?.fullFilePath}
                    onClick={() => jumpURL(item.templateAction)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className={'swiper-scrollbar'}></div>
          </section>
        )}
      </div>
    </>
  )
}

export default memo(FootPayment)
