import dayjs from 'dayjs'
import { useState, useEffect, memo } from 'react'
import { getGameList } from '@/api/game'
import { openGame } from '@/components/game/startGame'
import { useUserStore } from '@/stores/user'
import { applyFreeSpin } from '@/api/activity'
import { useAppStore } from '@/stores/app'
import { cn, formatGameImg } from '@/lib/utils'
import styles from '../_common_css/freeSpin.module.scss'
import { Skeleton } from 'antd'
import { useTranslation } from '@/lib/locales/client'
import { useShallow } from 'zustand/react/shallow'

type IProps = {
  className?: string
}

const FreeSpin: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { commonConfig, setIsShowNotification } = useAppStore(
    useShallow((state) => ({
      commonConfig: state.commonConfig,
      setIsShowNotification: state.setIsShowNotification
    }))
  )
  const { freeSpinList, getBonusTaskList } = useUserStore()
  const [listData, setListData] = useState([])
  const [spinning, setSpinning] = useState(true)

  useEffect(() => {
    const gameIds = freeSpinList.map((item) => item.gameId)
    if (gameIds.length === 0) {
      setSpinning(false)
      return
    }
    getGameList({
      gameIds,
      pageSize: 999,
      hideLoading: true
    }).then((res: any) => {
      setSpinning(false)
      if (res.success) {
        const arr = res.data?.data || []
        arr.forEach((item) => {
          freeSpinList.forEach((val) => {
            if (item.gameId === val.gameId && item.platformCode === val.providerCode) {
              // --- 待观察
              setListData([...val, ...item])
            }
          })
        })

        listData.sort((a, b) => new Date(b.useExpiredDate).getTime() - new Date(a.useExpiredDate).getTime())
      }
    })
  }, [])

  function playGame(val) {
    if (val.bizCheckStatus === 4) {
      const params = {
        activityCode: val.activityCode,
        id: val.id
      }
      applyFreeSpin(params).then((res) => {
        if (res && res.data) {
          setIsShowNotification(false)
          openGame(0, val, val.providerCurrency)
        }
        getBonusTaskList()
      })
    } else {
      setIsShowNotification(false)
      openGame(0, val, val.providerCurrency)
    }
  }

  return (
    <>
      <div className={cn(styles['free_spin'], props.className)}>
        <Skeleton loading={spinning} title={false} paragraph={{ rows: 4 }} active>
          {listData.map((item) => (
            <div key={item.id} className={'free_spin_list'}>
              <div className={'left'}>
                <img
                  className={'box'}
                  src={formatGameImg(item, commonConfig)} // --- 待处理
                />
                <div className={'info'}>
                  <p>{item.gameName}</p>
                  <p className={'time'}>{dayjs(item.useExpiredDate).format('YYYY/MM/DD HH:mm:ss')}</p>
                </div>
              </div>
              <div className={'right'}>
                <span className={'round'}>{item.roundNum}</span>
                {t('bonus.times')}
                <span className={'btn'} onClick={() => playGame(item)}>
                  {t('common.game.play')}
                </span>
              </div>
            </div>
          ))}
        </Skeleton>
      </div>
    </>
  )
}

export default memo(FreeSpin)
