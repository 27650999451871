import { useState, useEffect, memo } from 'react'
import { useRouter, usePathname } from 'next/navigation'
import { useAppStore } from '@/stores/app'
import { cn } from '@/lib/utils'
import { openGame } from '@/components/game/startGame'
import { useAuthStore } from '@/stores/authControl'
import { useUserStore } from '@/stores/user'
import Config from '@/config/base'
import { CSSTransition } from 'react-transition-group'
import styles from '../_common_css/headerNav.module.scss'
import { useShallow } from 'zustand/react/shallow'
import Link from 'next/link'
/**com */
import GameProvideSearch from '@/components/game/gameProvideSearch'

// PC端菜单栏
const HeaderNav = () => {
  const { globalPublickKey, updateCmsTemplate, mainTheme, setIsShowNotification, language } = useAppStore(
    useShallow((state) => ({
      globalPublickKey: state.globalPublickKey,
      updateCmsTemplate: state.updateCmsTemplate,
      mainTheme: state.mainTheme,
      setIsShowNotification: state.setIsShowNotification,
      language: state.language
    }))
  )
  const openLogin = useAuthStore((state) => state.openLogin)
  const isLogin = useUserStore((state) => state.isLogin)
  const filterData = ['Classic', 'Premier']
  const router = useRouter()
  const pathname = usePathname()
  const rawPath = pathname.replace(/en|ja(\/)?/g, '')
  const [navList, setNavList] = useState([])
  const [IsshowSubMenu, setIsshowSubMenu] = useState(false)
  // const [tabIndex, setTabIndex] = useState(0)  // 待观察

  useEffect(() => {
    if (globalPublickKey) {
      queryNavigation()
    }
  }, [globalPublickKey])

  const queryNavigation = () => {
    updateCmsTemplate({
      moduleCodeList: ['headerNavigation', 'webInfoConfig'],
      themeCode: mainTheme === 'red' ? Config.themeCode : undefined
    }).then((res: any) => {
      const dataList = res.filter((item: any) => item.moduleCode === 'headerNavigation')
      const newNavList = dataList.map((item: any) => {
        return {
          ...item,
          color: item?.colorList?.length > 0 ? item.colorList[0]?.color : undefined
        }
      })
      setNavList(newNavList)
    })
  }

  // const isLink = (path: string) => {
  //   const pathList = ['/account', '/sports-bet', 'showNotification']
  //   return !pathList.includes(path)
  // }

  const handNav = (nav: any) => {
    console.log('路径', nav)
    const NTemp = nav.templateAction // /promo
    if (NTemp === 'showNotification') {
      setIsShowNotification(true)
      return
    }
    if (NTemp && NTemp.indexOf('http') > -1) {
      window.open(NTemp)
      return
    }
    if (!isLogin) {
      const pathList = ['/account']
      if (pathList.includes(NTemp)) {
        openLogin()
        return
      }
    }
    if (NTemp === '/sports-bet' || NTemp === '/fb') {
      setIsshowSubMenu(true)
      return
    }
    // router.refresh()
    router.push(NTemp)

    // window.location.href = (language === 'ja-JP' && NTemp.indexOf('/ja') === -1 ? '/ja' : '') + NTemp
  }

  const isHideSubMenu = (i: any, isHideState: boolean) => {
    if (i.templateAction === '/fb' || i.templateAction === '/sports-bet') {
      isHideState ? setIsshowSubMenu(false) : setIsshowSubMenu(true)
    }
  }
  const getBigImg = (item: any) => {
    const mediaLibrary = item.mediaLibrary?.filter((item: any) => {
      return item.resourceType === 1
    })
    const img = mediaLibrary[0]?.fullFilePath
    return img
  }
  const getSmallImg = (item: any) => {
    const mediaLibrary = item.mediaLibrary?.filter((item: any) => {
      return item.resourceType === 2
    })

    const img = mediaLibrary?.length ? mediaLibrary[0]?.fullFilePath : ''
    return img ? JSON.stringify(img) : ''
  }

  const change = (item: any, index: any) => {
    // setTabIndex(index)
    item === 'Classic' ? router.push('/fb') : router.push('/sports-bet')
  }

  return (
    <>
      <ul className={styles['header-nav-modal']}>
        {navList.map((item, index) => (
          <Link
            className={cn('nav-item', item.templateAction === rawPath && 'active-nav')}
            key={index}
            href="#"
            onClick={(event) => {
              event.preventDefault()
              handNav(item)
            }}
            onMouseOver={() => isHideSubMenu(item, false)}
            onMouseOut={() => isHideSubMenu(item, true)}
          >
            <div
              className={'nav-item-active-bg'}
              style={{ backgroundImage: `url(${getSmallImg(item)})` }}
            ></div>
            <img src={getBigImg(item)} className={'nav-img'} alt={item.title || ''} />
            <span
              style={{ color: mainTheme === 'red' ? item.color : undefined }}
              className={cn('nav-label', 'font-semibold')}
            >
              {item.title}
            </span>
            <CSSTransition
              timeout={220}
              unmountOnExit
              in={(item.templateAction === '/sports-bet' || item.templateAction === '/fb') && IsshowSubMenu}
              classNames={'fade'}
            >
              <div className={'cu-box'}>
                {filterData.map((unit, index) => (
                  <p
                    className={'cu-label'}
                    key={index}
                    onClick={(event) => {
                      event.stopPropagation()
                      change(unit, index)
                    }}
                  >
                    {unit}
                  </p>
                ))}
              </div>
            </CSSTransition>
          </Link>
        ))}
        {/* <GameProvideSearch className={'game-provide-search'} /> */}
        {/* {children} */}
        {/* <slot></slot> */}
      </ul>
    </>
  )
}

export default memo(HeaderNav)
