import { useState, FC, useEffect, useMemo, useRef, memo } from 'react'
import { useAuthStore } from '@/stores/authControl'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { isMobile } from '@/lib/utils'
import { Md5 } from 'ts-md5'
import { oauthTokenApi } from '@/api/account'
import styles from './_common_css/supportThird.module.scss'
import { useTranslation } from '@/lib/locales/client'
import { message as AntdMessage } from '@/components/base/antdNotice'
import { useShallow } from 'zustand/react/shallow'

type IProps = {
  isCustomizeIcon?: boolean // false
  isHome?: boolean //false
}

const SupportThird: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { thirdRmCode, setWebToken, updateUserInfo, setSeonSession } = useUserStore(
    useShallow((state) => ({
      thirdRmCode: state.thirdRmCode,
      setWebToken: state.setWebToken,
      updateUserInfo: state.updateUserInfo,
      setSeonSession: state.setSeonSession
    }))
  )
  const { limitLogin, updateLimitLoginDialog, commonConfig } = useAppStore(
    useShallow((state) => ({
      limitLogin: state.limitLogin,
      updateLimitLoginDialog: state.updateLimitLoginDialog,
      commonConfig: state.commonConfig
    }))
  )
  const closeModal = useAuthStore((state) => state.closeModal)

  const inviteCode = useMemo(() => {
    return localStorage.getItem('agent-info-local') || thirdRmCode || ''
  }, [])

  const windowChangeTimer = useRef<any>(null)
  const [elseLoginType, setElseLoginType] = useState('google')

  useEffect(() => {
    return () => {
      clearInterval(windowChangeTimer.current)
    }
  }, [windowChangeTimer.current])

  // 登录/注册支持的第三方渠道
  const thirdSupport = ['google', 'twitter', 'line']
  /* com */
  let myThirdNewWindow: any = '' // 只允许新开一个的窗口

  const thirdLoginClick = (type) => {
    if (limitLogin) {
      updateLimitLoginDialog(true)
      return
    }
    // 其他第三方登录
    const qid = isMobile() ? commonConfig.appid : commonConfig.appidForPc
    const aid = Md5.hashStr(Date.now() + Math.random().toString().split('.')[1].slice(0, 6))
    const url = `/_api_/auth/oauth/authorization/${type}?qid=${qid}&aid=${aid}&merchantCode=${
      commonConfig.merchantCode
    }&referCode=${inviteCode || ''}`
    if (!myThirdNewWindow) {
      getData()
    } else {
      myThirdNewWindow && myThirdNewWindow.close()
    }
    myThirdNewWindow = isMobile()
      ? window.open(url)
      : window.open(url, 'newwindow', 'width=700,height=600,top=150,left=500')
  }

  const getData = () => {
    if (windowChangeTimer.current) {
      clearInterval(windowChangeTimer.current)
    }
    // 获取新窗口的url对应的code等值(利用同域名下localStorage共享)
    windowChangeTimer.current = setInterval(async () => {
      try {
        let elseLoginData: any = window.localStorage.getItem('elseLoginData')
        if (elseLoginData) {
          // 初始化
          setElseLoginType(JSON.parse(elseLoginData))
          window.localStorage.removeItem('elseLoginData')
          windowChangeTimer.current && clearInterval(windowChangeTimer.current)
          // this.showElseLoginPop = false
          myThirdNewWindow.close()
          myThirdNewWindow = ''
          // 使用回传的数据
          if (!elseLoginData.error && elseLoginData.code) {
            Object.keys(elseLoginData).forEach((el) => {
              // 处理传递给后台的URL数据
              elseLoginData[el] = decodeURIComponent(elseLoginData[el].replace('#_=_', ''))
            })
            // 第三方登录（包括谷歌自动登录）
            loginIn({
              grantType: 'third_party',
              code: elseLoginData.code,
              state: elseLoginData.state,
              referCode: inviteCode || ''
            })
          } else {
            AntdMessage.error(t('user.authDialog.thirdLoginFailedText'))
          }
        }
      } catch (error) {
        console.error(error)
      }
    }, 300)
  }
  // 第三方登录（包括谷歌自动登录）
  const loginIn = async (params) => {
    setWebToken('') // 避免token还有值
    const res: any = await oauthTokenApi(params)
    if (res && res.success) {
      setWebToken(res.data)
      // this.SET_INVITECODE('') // 重置推荐码为空
      sessionStorage.setItem('inviteCode', '') // 使用session缓存inviteCode，关闭页面再进入后清空
      const { loginName } = res.data
      updateUserInfo(loginName, 1)
      closeModal()
      setSeonSession()
    }
  }

  // ThirdSupport: Forms.ThirdSupport,
  // thirdWindow: ref(),
  // timerId: ref(null),
  // agentName: ref(""),
  // agentCode: ref("")

  return (
    <div className={styles['support-third-modal']}>
      <div className="support-third">
        {!props.isCustomizeIcon && (
          <div className="third-top">
            <div className="line"></div>
            <span className="third-title">{t('user.authDialog.dThird')}</span>
            <div className="line"></div>
          </div>
        )}

        <div className="third-list">
          {thirdSupport.map((item, key) => (
            <img
              className="third-open"
              onClick={() => thirdLoginClick(item)}
              key={key}
              src={
                props.isCustomizeIcon
                  ? `/static/images/home/${item}.png`
                  : `/static/images/user/third-auth/${item}.png`
              }
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default memo(SupportThird)
