// 底部导航
'use client'
import { memo } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { useRouter, usePathname } from 'next/navigation'
import { cn } from '@/lib/utils'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { track } from '@/hooks/useTrack'
import styles from '../_common_css/tabbar.module.scss'
import { useShallow } from 'zustand/react/shallow'

const Tabbar = () => {
  const { t } = useTranslation()
  const { isBigScreen, isShowSideNav, setIsShowSideNav, queryCommonConfig } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      isShowSideNav: state.isShowSideNav,
      setIsShowSideNav: state.setIsShowSideNav,
      queryCommonConfig: state.queryCommonConfig
    }))
  )
  const { availableBonusTask, isLogin, availableMission } = useUserStore(
    useShallow((state) => ({
      availableBonusTask: state.availableBonusTask,
      isLogin: state.isLogin,
      availableMission: state.availableMission
    }))
  )
  const openLogin = useAuthStore((state) => state.openLogin)
  const router = useRouter()
  const pathName = usePathname()
  const rawPath = pathName.replace(/en|ja(\/)?/g, '')
  const tabList = [
    {
      label: 'common.tabber.menu',
      icon: 'menu',
      onClick() {
        setIsShowSideNav(!isShowSideNav)
      }
    },
    {
      label: 'common.tabber.game',
      icon: 'game',
      path: '/pachinko',
      onClick() {
        router.push(`/pachinko`)
      }
    },
    {
      label: 'common.tabber.wallet',
      icon: 'wallet',
      path: '/account/deposit',
      onClick() {
        if (!isLogin) {
          openLogin()
        } else {
          track('RechargeClick')
          router.push('/account/deposit')
          queryCommonConfig()
        }
      }
    },
    {
      label: 'common.tabber.promo',
      icon: 'promo',
      path: '/promo',
      onClick() {
        if (isLogin) {
          router.push('/reward')
        } else {
          router.push('/promo')
        }
      }
    },
    {
      label: 'common.tabber.account',
      icon: 'account',
      path: '/account',
      onClick() {
        router.push('/account')
      }
    }
  ]

  const isActive = (path) => {
    return (
      rawPath === path ||
      (path === '/pachinko' &&
        ['/favoritegames', '/pachinko', '/slots', '/fish', '/live-casino', '/sports-bet'].indexOf(rawPath) >
          -1) ||
      (path === '/promo' && ['/reward', '/promo'].indexOf(rawPath) > -1)
    )
  }

  return (
    <>
      {/* {!isBigScreen && ( */}
      <footer className={styles['footer-fix-menu']}>
        <ul>
          {tabList.map((item, key) => (
            <li onClick={item.onClick} key={key} className={cn(isActive(item.path) && 'active')}>
              <a className={cn(item.icon === 'wallet' && 'wallet')}>
                {item.icon === 'wallet' ? (
                  <img src="/static/images/tabbar/wallet.png" />
                ) : (
                  <img
                    src={`/static/images/tabbar/${isActive(item.path) ? item.icon + '_icon.png' : item.icon + '.svg'}`}
                  />
                )}
                {t(item.label)}
                {availableBonusTask + availableMission > 0 && item.icon === 'promo' && isLogin && (
                  <span>
                    {availableBonusTask + availableMission > 99 ? 99 : availableBonusTask + availableMission}
                  </span>
                )}
              </a>
            </li>
          ))}
        </ul>
      </footer>
      {/* )} */}
    </>
  )
}

export default memo(Tabbar)
