/* reg-menu */
export const regTypeList = () => {
  const list = [
    { label: 'user.authDialog.regEmail', type: 'emailReg' },
    { label: 'user.authDialog.regPhone', type: 'phoneReg' }
  ]
  return list
}

export default {
  // 注册表格行列
  RegisterForms: {
    emailReg: [
      {
        key: 'emailReg',
        name: 'emailReg',
        title: 'user.authDialog.EmailPlace',
        minLength: 4,
        maxLength: 320,
        placeholder: 'user.authDialog.EmailPlace',
        rules: [{ required: true, message: 'Please input activity email', trigger: 'blur' }],
        inputType: '', // 输入类型
        needEmail: true
      },
      {
        key: 'userName',
        name: 'userName',
        title: 'user.authDialog.authCodePlace',
        minLength: 4,
        maxLength: 320,
        placeholder: 'user.lostPop.userName',
        rules: [{ require: true }],
        inputType: '' // 输入类型
      },
      {
        key: 'password',
        name: 'password',
        title: 'user.authDialog.authPassword',
        minLength: 6,
        maxLength: 32,
        autocomplete: 'new-password',
        placeholder: 'user.authDialog.authPassword',
        rules: [{ required: true, message: 'Please input Activity password', trigger: 'blur' }], // 校验规则
        inputType: 'password' // 输入类型
      },
      {
        key: 'rmCode',
        name: 'recommedCode',
        title: 'user.authDialog.refCodePlace',
        maxLength: 40,
        rules: [{ require: false }],
        needClear: false,
        placeholder: 'user.authDialog.refCodePlace',
        inputType: '' // 输入类型
      }
    ],
    phoneReg: [
      {
        key: 'phoneReg',
        name: 'phoneReg',
        title: 'user.authDialog.phonePlace',
        minLength: 4,
        maxLength: 20,
        needCountry: true,
        onlyNumber: true,
        placeholder: 'user.authDialog.phonePlace',
        rules: [{ required: true, message: 'Please input activity phone', trigger: 'blur' }],
        inputType: 'number' // 输入类型
      },
      {
        key: 'code',
        name: 'codeNum',
        title: 'user.authDialog.authCodePlace',
        maxLength: 6,
        placeholder: 'user.authDialog.authCodePlace',
        rules: [{ require: true }],
        isClick: true,
        needClear: false,
        onlyNumber: true
      },
      {
        key: 'rmCode',
        name: 'recommedCode',
        title: 'user.authDialog.refCodePlace',
        maxLength: 20,
        rules: [{ require: false }],
        needClear: false,
        placeholder: 'user.authDialog.refCodePlace',
        inputType: '' // 输入类型
      }
    ]
  },
  // 登录/注册支持的第三方渠道
  ThirdSupport: ['google', 'twitter', 'line']
}
