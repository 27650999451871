'use client'
import { useState, useEffect, useRef, memo } from 'react'
import { cn } from '@/lib/utils'
import { useAppStore } from '@/stores/app'
import styles from './_common_css/globalMessage.module.scss'
import { useShallow } from 'zustand/react/shallow'

/**
 * 全局消息通知
 * * @Author: Hone
 * * @Date: 2024-12-01
 * @returns
 */

const GlobalMessage = () => {
  const { isBigScreen, SET_GLOBAL_MSG, globalMsg } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      SET_GLOBAL_MSG: state.SET_GLOBAL_MSG,
      globalMsg: state.globalMsg
    }))
  )
  const { count = 5 } = globalMsg
  console.log('globalMsg', globalMsg.flag, globalMsg.count)

  const [time, setTime] = useState(count)
  const timer = useRef(null)

  const send = () => {
    setTime(count) // 初始赋值给
    // 2.开启定时器
    timer.current = setInterval(() => {
      setTime((timer) => timer - 1)
    }, 1000)
  }

  // 启动
  useEffect(() => {
    if (globalMsg.flag) {
      send()
    }
  }, [globalMsg.flag])

  useEffect(() => {
    if (time === 0) {
      SET_GLOBAL_MSG({ flag: false })
      clearInterval(timer.current) // 2.清除定时器
    }
  }, [time])

  return (
    <>
      {globalMsg.flag && (
        <div className={styles['global-message-modal']}>
          <div
            className={cn('msg-img', globalMsg.type)}
            style={{
              backgroundImage: `url(/static/images/common/messgae/${globalMsg.type}.png)`
            }}
          ></div>
          <p className={cn(!isBigScreen && 'mobile', 'content')}>{globalMsg.content}</p>
          <span className={'count-time'}>{time}S</span>
        </div>
      )}
    </>
  )
}

export default memo(GlobalMessage)
