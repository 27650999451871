// 选择语言
'use client'
import { useMemo, FC, memo } from 'react'
import { cn } from '@/lib/utils'
import Config from '@/constants/catalog'
import { Popover, Button, Select } from 'antd'
// import { useAppStore } from '@/stores/app'
import { languages, Lng } from '@/lib/locales/i18n'
import { localeItems } from '@/lib/locales/settings'
import styles from './_common_css/queryLang.module.scss'
import { useRouter, usePathname, useParams } from 'next/navigation'
import { Trans } from 'react-i18next/TransWithoutContext'
import Link from 'next/link'

type IProps = {
  getPopupContainer?: () => void
  queryImage?: string
  className?: string // 传入的样式
}

const QueryLang: FC<IProps> = (props) => {
  // const { isBigScreen, changeLanguage, language } = useAppStore()
  const pathname = usePathname()
  const router = useRouter()
  const { lng }: { lng: Lng } = useParams()
  // 路径进行特殊处理 path="/second-page"
  const path = pathname.replace(/en|ja(\/)?/g, '')

  // const addClass = useMemo(() => {
  //   return !isBigScreen ? 'mobile' : ''
  // }, [])

  // // 语言字段转换
  // let currentLang = useMemo(() => {
  //   return localeItems.filter((item) => language === item.name)[0] || localeItems[0]
  // }, [])

  // const imgaeData = useMemo(() => {
  //   return props.queryImage || 'static/images/footer/language.svg'
  // }, [])

  // // 切换语言, 1修改url地址语言，2修改缓存的语言
  // const changeLanguageHandle = (item: { nickname: any; name: any }, key: any) => {
  //   console.log('修改的语言', item, key)
  //   // changeLanguage(item.name)
  // }

  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
    // i18n.changeLanguage(value) // 修改语言（但无需手动）
    // changeLanguage(value) // 更新本地语言缓存
    router.replace(`${value}/${path}`)
  }

  return (
    <>
      <Select
        // defaultValue="English"
        value={lng === 'ja' ? localeItems[1].title : localeItems[0].title}
        style={{ width: 110 }}
        onChange={handleChange}
        options={[
          { value: localeItems[0].key, label: localeItems[0].title },
          { value: localeItems[1].key, label: localeItems[1].title }
        ]}
      />
      {/* <Trans i18nKey="languageSwitcher" t={t}>
        Switch from <strong>{props.lang}</strong> to:{' '}
      </Trans>
      {languages
        .filter((l) => props.lang !== l) // 先过滤掉自己
        .map((l, index) => {
          return (
            <span key={l}>
              {index > 0 && ' or '}
              <Link href={`/${l}`}>{l}</Link>
            </span>
          )
        })} */}

      {/* <Popover
        trigger="click"
        className={cn(styles[addClass], styles['query-lang-pop'], props.className)}
        placement={!isBigScreen ? 'topLeft' : 'top'}
        overlayClassName={styles[`queryPop ${addClass}`]}
        getPopupContainer={props.getPopupContainer}
        content={
          <>
            <ul className={styles['langs-box']}>
              {LangsArr.map((item, key) => (
                <li
                  key={key}
                  className={styles[currentLang.nickname === item.nickname && 'active']}
                  onClick={() => changeLanguageHandle(item, key)}
                >
                  {item.title}
                </li>
              ))}
            </ul>
          </>
        }
      >
        <Button style={{ backgroundImage: 'url(' + imgaeData + ')' }}>{currentLang.title}</Button>
      </Popover> */}
    </>
  )
}

export default memo(QueryLang)
