import { useState, useMemo, memo } from 'react'
import { cn } from '@/lib/utils'
import { Popover, Button } from 'antd'
import { useTranslation } from '@/lib/locales/client'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import Config from '@/config/sidebar'
import { useRouter } from 'next/navigation'
import styles from '../_common_css/avatarPopover.module.scss'
import { track } from '@/hooks/useTrack'
import Link from 'next/link'

const AvatorPopOver = () => {
  const { t } = useTranslation()
  const unreadMsgCount = useAppStore((state) => state.unreadMsgCount)
  const userLogout = useUserStore((state) => state.userLogout)
  const router = useRouter()

  const [clicked, setClicked] = useState(false)
  const navList = useMemo(() => {
    return Config.accountSlider[2].children.filter((item) => !item.hiddenTop)
  }, [])

  const handleClickChange = (open: boolean) => {
    setClicked(open)
  }

  const toPage = (path) => {
    setClicked(false)
    if (path === '/account/deposit') track('RechargeClick')
    router.push(path)
  }

  const logoutUser = () => {
    setClicked(false)
    userLogout()
  }

  return (
    <>
      <Popover
        trigger="click"
        placement="bottomRight"
        overlayClassName={styles.avatarPop}
        open={clicked}
        onOpenChange={handleClickChange}
        content={
          <>
            <div className={'links-box user-center-popover'}>
              <div className={'amount-inner'}>
                <div className={'collect_box'}>
                  <p>{t('common.account.personalCenter')}</p>
                  <div>
                    <p className={'multimode'} onClick={() => toPage('/account/multimode')}></p>
                    <p className={'heart'} onClick={() => toPage('/account/favorite')}></p>
                  </div>
                </div>
              </div>
              <ul className={'links-list'}>
                <li>
                  {navList.map((unit, idx) => (
                    <Link key={'c' + idx} className={'font-bold'} href="#" onClick={() => toPage(unit.path)}>
                      <img
                        className={'founds-icon'}
                        src={`/static/images/myAccount/common/${unit.icon}.svg`}
                      />
                      <span className={unit.className}>{t(unit.title)}</span>
                    </Link>
                  ))}
                </li>
                {Config.headSlider.map((item: any, key) => (
                  <li key={key}>
                    {item.map((unit, idx) => (
                      <Link key={'c' + key + idx} href="#" onClick={() => toPage(unit.path)}>
                        {item.key !== 'found' && (
                          <img src={`/static/images/myAccount/common/${unit.icon}.svg`} />
                        )}
                        <span className={unit.className}>{t(unit.title)}</span>
                      </Link>
                    ))}
                  </li>
                ))}
                <li>
                  <Button className={'logout-btn'} onClick={logoutUser}>
                    {t('common.account.logOut')}
                  </Button>
                </li>
              </ul>
            </div>
          </>
        }
      >
        <div
          className={cn('user-avatar', unreadMsgCount > 0 && 'active')}
          id="userAvatar"
          onClick={() => {
            setClicked(!clicked)
          }}
        ></div>
      </Popover>
    </>
  )
}

export default memo(AvatorPopOver)
