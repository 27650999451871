import { useMemo, memo } from 'react'
import { useTranslation } from '@/lib/locales/client'
import LangsText from '@/constants/catalog'
import { jumpURL } from '@/lib/jumpURL'
import { Collapse } from 'antd'
import type { CollapseProps } from 'antd'
import { Lng } from '@/lib/locales/i18n'
import { useParams } from 'next/navigation'
import styles from '../_common_css/footerLinks.module.scss'

const FooterLinks = () => {
  const { t } = useTranslation()
  const callapseGroup = useMemo(() => LangsText.H5Links, [])
  const { lng }: { lng: Lng } = useParams()
  const getJaUrl = (path: any) => {
    // console.log(language === 'ja-JP', path)
    if (lng === 'ja' && path && path.indexOf('/ja') === -1 && path.indexOf('http') === -1) {
      return '/ja' + path
    }
    return path
  }

  //  待排查是否有用
  // useEffect(() => {
  //   if (language === 'en-US') {
  //     const tempDataList = [...dataList]
  //     tempDataList.forEach((item: any) => {
  //       if (item.title === 'Support') {
  //         if (item.children.every((val: any) => val.link !== '/language')) {
  //           item.children.push({ title: 'クイーンカジノ日本語版', link: '/language' })
  //         }
  //       }
  //     })
  //     setDataList(tempDataList)
  //   }
  // }, [])

  const items: CollapseProps['items'] = callapseGroup.map((item, index) => {
    return {
      key: index,
      label: item.title,
      className: 'list',
      showArrow: false, // 下拉箭头有样式问题，待跟进解决, 先关闭
      children: item.content.map((unit, idx) => (
        <p key={idx} className={styles['footer-links-list']}>
          <a
            onClick={(event) => {
              event.stopPropagation()
              jumpURL(unit.link)
            }}
            href={getJaUrl(unit.link)}
          >
            {/* 待观察 */}
            {t(unit.title)}
          </a>
        </p>
      ))
    }
  })

  return (
    <>
      <Collapse accordion items={items} />
    </>
  )
}
export default memo(FooterLinks)
