'use client'
import { memo, useRef, useState, useMemo, useLayoutEffect } from 'react'
import { useSetState } from 'ahooks'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/authControl'
import { useTranslation } from '@/lib/locales/client'
import { cn } from '@/lib/utils'
import { Form } from 'antd'
import { useRouter } from 'next/navigation'
import Forms, { loginTypeList } from '../../_datasets/login'
import Validtor from '@/hooks/validtor'
/* encrypt */
import { Decrypt, Encrypt } from '@/hooks/encrypt'
import { logApi } from '@/api/auth'
import { sendCodeByPhone } from '@/api/account'
import { openGame } from '../../_components/startGame'
import { useShallow } from 'zustand/react/shallow'
import styles from '../_common_css/login.module.scss'
// com
import ACInput from '@/components/base/acInput'
import SliderCaptcha from '@/components/common/sliderCaptcha'
import Lost2faPopup from '../../_components/lost2faPopup'
import Verify2fa from '../../_components/verify2fa'
import LockedDialog from '../../_components/lockedDialog'
import ValidDataPopup from '../../_components/ValidDataPopup'

/* init */
const P_B_KEY = 'J1PTY42LFGZAYBSS'
const P_REM_STR = 'Q_C_I_W'
const P_REM_STR2 = 'Q_C_I_W_N'

const Login = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const {
    isBigScreen,
    commonConfig,
    limitLogin,
    countryCurInfo,
    updateLimitLoginDialog,
    changeLanguage,
    language,
    setDialogType
  } = useAppStore(
    useShallow((state) => ({
      isBigScreen: state.isBigScreen,
      commonConfig: state.commonConfig,
      limitLogin: state.limitLogin,
      countryCurInfo: state.countryCurInfo,
      updateLimitLoginDialog: state.updateLimitLoginDialog,
      changeLanguage: state.changeLanguage,
      language: state.language,
      setDialogType: state.setDialogType
    }))
  )
  const { openRegister, logRegVisible, closeModal } = useAuthStore(
    useShallow((state) => ({
      openRegister: state.openRegister,
      logRegVisible: state.logRegVisible,
      closeModal: state.closeModal
    }))
  )
  const { setWebToken, updateUserInfo, setSeonSession, userInfo, fingerPrintID } = useUserStore(
    useShallow((state) => ({
      setWebToken: state.setWebToken,
      updateUserInfo: state.updateUserInfo,
      setSeonSession: state.setSeonSession,
      userInfo: state.userInfo,
      fingerPrintID: state.fingerPrintID
    }))
  )
  const [loginModalType, setLoginModalType] = useState('PWLogin')
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const [formState, setFormState] = useSetState({
    // ...Forms.LoginForms
    password: '',
    userName: '',
    verifyCode: ''
  })

  /* forms */
  // let startEnter = ref(false)
  const loginMenuList = loginTypeList()
  const [remberLog, setRemberLog] = useState(true)
  const [showSliderCaptchaPopup, setShowSliderCaptchaPopup] = useState(false)
  const [lostVisibility, setLostVisibility] = useState(false)
  const [verifyVisibility, setVerifyVisibility] = useState(false)
  const [lockVisibility, setLockVisibility] = useState(false)
  const [showVerifyCodePopup, setShowVerifyCodePopup] = useState(false)
  const [verifyNum, setVerifyNum] = useState('')
  const [validData, setValidData] = useSetState({ verifyType: '', email: '', phonePrefix: '', number: null })
  const [verifyParams, setVerifyParams] = useSetState({
    authenticationCode: '',
    grantType: '',
    phone: ''
  })

  // 待处理
  // const { pageReload } = inject('RELOAD')

  let userName = async (_rule, value) => {
    let _name = formState.userName
    if (!_name) {
      return Promise.reject(t('common.validtor.usenameEpty'))
    }
    return Promise.resolve()
  }

  let password = async (_rule, value) => {
    let _pw = formState.password
    if (!_pw) {
      return Promise.reject(t('common.validtor.passwordEpty'))
    }
    if (!Validtor.password(_pw)) {
      return Promise.reject(t('common.validtor.passwordError'))
    }
    return Promise.resolve()
  }

  let namePhoneEmail = async (_rule, value) => {
    if (!formState.userName) {
      return Promise.reject(t('common.validtor.phoneEpty'))
    } else {
      return Promise.resolve()
    }
  }
  let codeNum = async (_rule, value) => {
    let _code = formState.verifyCode
    if (!_code || _code.length < 6) {
      return Promise.reject(t('common.validtor.codeError'))
    }
    return Promise.resolve()
  }

  const rules = {
    userName: [{ required: true, validator: userName, trigger: ['blur', 'change'] }],
    password: [{ required: true, validator: password, trigger: ['blur', 'change'] }],
    namePhoneEmail: [{ required: true, validator: namePhoneEmail, trigger: ['blur', 'change'] }],
    codeNum: [{ required: true, validator: codeNum, trigger: ['blur', 'change'] }]
  }

  const btnDisable = useMemo(() => {
    const { userName, password, verifyCode } = formState
    const _code = loginModalType === 'PWLogin' ? Validtor.password(password) : verifyCode?.length === 6
    return _code && userName
  }, [])

  const sendType = useMemo(() => {
    return Validtor.email(formState.userName) ? 2 : 1
  }, [])

  useLayoutEffect(() => {
    if (logRegVisible && loginModalType === 'PWLogin') {
      try {
        // 待观察， 原用nextTick
        // useLayoutEffect(() => {
        // nextTick(() => {
        let _ulockPW = localStorage.getItem(P_REM_STR)
        let _ulockUN = localStorage.getItem(P_REM_STR2)
        _ulockPW = JSON.parse(_ulockPW)
        _ulockUN = JSON.parse(_ulockUN)
        /*  */
        _ulockPW = decrypt(_ulockPW) || ''
        _ulockUN = self.atob(_ulockUN) || ''
        if (_ulockPW) {
          formState.userName = _ulockUN
          formState.password = _ulockPW
        }
        // })
      } catch (e) {
        console.log(e)
      }
    }
  }, [logRegVisible])

  function continueLogin(val) {
    setVerifyNum(val)
    loginIn()
  }

  function finishVerify(val) {
    setVerifyNum(val)
    loginIn(1)
  }

  function chooseType(item) {
    setLoginModalType(item.type)
    formRef.current?.resetFields()
    formState.password = ''
    formState.userName = ''
  }

  async function accountLogin(verifyType = 0) {
    console.log('accountLogin ?', verifyType)
    // verifyType 0 正常登录  1 邮箱或手机二次验证
    if (Number(verifyType === 0)) {
      console.log('verifyType ? ', verifyType)
      setValidData({ verifyType: '' })
    }
    // const { userName, password } = formState

    // hard code
    const username = 'qcfata22001'
    const password = 'qcfata22001'
    const params = {
      loginName: sendType === 1 ? username.toLowerCase() : username,
      password,
      loginType: sendType
    }

    // --- 待处理
    // const params = {
    //   ...verifyParams,
    //   loginName: sendType === 1 ? userName.toLowerCase() : userName,
    //   password,
    //   loginType: sendType
    // }

    // 2FA登录
    // if (verifyVisibility) {
    //   params.authenticationCode = verifyNum
    //   params.grantType = 'google2fa'
    // }

    // // 加验证码登录, 二次手机邮箱验证登录不需要传password
    // if (verifyType === 1 && validData.verifyType) {
    //   params.authenticationCode = verifyNum
    //   params.grantType = validData.verifyType === 'email' ? 'email2fa' : 'phone2fa'
    //   if (validData.verifyType === 'phone') {
    //     params.phone = params.loginName
    //   }
    // } else {
    //   params.password = password
    // }

    const res: any = await logApi({
      grantType: 'web_password',
      merchantCode: commonConfig.merchantCode,
      ...params
    })
    if (res && res.success) {
      console.log('登录数据?', res)
      // 设置用户信息
      setWebToken(res.data)
      await updateUserInfo(res.data.loginName, 1)
      closeModal()
      setSeonSession()
      if (location.href.includes('/sports-bet')) {
        //window.BTDomInit.kill()
        openGame(0, {
          platformCode: 'HUB88',
          gameId: window.location.href.includes('uat') ? 'btsg_sportsbetting' : 'btsg_sportbetting'
        })
      }

      // if (location.href.includes('/fb')) {
      //   // 待处理
      //   // pageReload()
      // }
      // 埋点插件 待处理
      // window.dataLayer.push({
      //   UsernameID: res.data.loginName,
      //   SocialChannelID: '',
      //   event: 'login.success'
      // })
      /* 缓存 */
      loginModalType === 'PWLogin' && handleRember(params)
      setLangQuery() // 切换语言
    } else {
      switch (res.code) {
        case 100807: // 弹出2FA
          // verifyVisibility.value = true
          setVerifyVisibility(true)
          break
        case 101808:
          setValidData({
            verifyType: 'email', // email || phone 类型
            email: res.data // email || phone
          })
          setShowVerifyCodePopup(true)
          break
        case 101809:
          setValidData({
            verifyType: 'phone', // email || phone 类型
            phonePrefix: '',
            number: res.data // phone
          })
          setShowVerifyCodePopup(true)
          break
        case 100701: // 再次登录时需要弹出滑块验证
          // this.$refs.sliderCaptcha && this.$refs.sliderCaptcha.refresh()
          setShowSliderCaptchaPopup(true)
          break
        case 100700: // 已锁定账号，稍后再试
          setLockVisibility(true)
          break
      }
    }
  }

  async function phoneLogin() {
    const { verifyCode, userName } = formState
    const params = {
      phone: userName,
      grantType: 'phone2fa',
      authenticationCode: verifyCode,
      phonePrefix: '00' + countryCurInfo.code
    }
    // console.log('请求token参数', commonConfig, params)
    const res: any = await logApi({ merchantCode: commonConfig.merchantCode, ...params })
    if (res && res.success) {
      console.log('返回请求token', res)
      setWebToken(res.data)
      await updateUserInfo(res.data.loginName, 1)

      closeModal()
      window.dataLayer.push({
        UsernameID: res.data.loginName,
        SocialChannelID: '',
        event: 'login.success'
      })
    } else {
      switch (res.code) {
        case 100700: // 已锁定账号，稍后再试
          setLockVisibility(true)
          break
      }
    }
  }

  async function loginIn(verifyType = 0) {
    // console.log('开始提交', limitLogin, btnDisable, loginModalType)
    if (limitLogin) {
      updateLimitLoginDialog(true)
      return
    }
    // if (!btnDisable) return --- 待处理
    if (loginModalType === 'PWLogin') {
      await accountLogin(verifyType)
    } else {
      phoneLogin()
    }
  }

  // 读取用户环境语言
  function setLangQuery() {
    const { lang } = userInfo || {}
    // if (language !== lang) changeLanguage(lang)
    // 直接url跳转不同语言
    console.log('跳转用户语言环境', lang)
    router.push(`/`)
    // router.push(`/${lang}/`)
  }

  function handleRember(params) {
    const nameRem = self.btoa(params.loginName)
    const pawRem = encryptParams(params.password)

    try {
      if (remberLog) {
        localStorage.setItem(P_REM_STR, JSON.stringify(pawRem))
        localStorage.setItem(P_REM_STR2, JSON.stringify(nameRem))
      } else {
        localStorage.removeItem(P_REM_STR)
        localStorage.removeItem(P_REM_STR2)
      }
    } catch (err) {
      console.log(err)
    }
  }
  /* 忘记密码 */
  function handForgot() {
    const router = useRouter()
    if (isBigScreen) {
      closeModal()
      setDialogType({ forget: true })
    } else {
      // --- 待处理 H5需先跳转路径后才能关闭登录窗口，否则跳转失效
      // router.push('/resetPassword').then(() => {
      //   closeModal()
      // })
    }
  }
  /* ---------------验证相关-start----------- */
  function captchaCallback(params) {
    setShowSliderCaptchaPopup(false)
    // verifyParams.value = params
    setVerifyParams(params)
    loginIn()
  }

  const loginInputRef = useRef(null)
  function startVerify() {
    if (!formState.userName) return
    sendCodeByPhone({
      purpose: 2,
      phonePrefix: '00' + countryCurInfo.code,
      phone: formState.userName
    }).then((res: any) => {
      if (res.success) loginInputRef.current[1].startCount()
    })
  }
  /* 记住密码-加解密 */
  function encryptParams(data) {
    return Encrypt(data, P_B_KEY)
  }
  function decrypt(data) {
    try {
      return Decrypt(data, P_B_KEY)
    } catch (error) {
      return ''
    }
  }
  // 设置谷歌埋点
  function onSetGoogle() {
    window.dataLayer.push({
      ClientID: fingerPrintID,
      event: 'form.started'
    })
  }

  return (
    <>
      <div className={styles['user-login-modal']}>
        {/* <div className={cn(styles['user-login-modal'], styles[!isBigScreen && 'h5'])}> */}
        <div className={'menu-list'}>
          {loginMenuList &&
            loginMenuList.map((item, index) => (
              <span
                key={index}
                className={cn(loginModalType == item.type && 'active-menu')}
                onClick={() => chooseType(item)}
              >
                {t(item.label)}
              </span>
            ))}
        </div>
        {/* content */}
        <Form ref={formRef} form={form} validateTrigger="onBlur" layout="vertical">
          {Forms.LoginForms[loginModalType].map((item) => (
            <Form.Item
              // label={t(item.title)}
              key={item.key}
              name={item.name}
              rules={item.require}
              className={'code-mb-50'}
              // className={cn(item.key === 'verifyCode' && 'code-mb-50')}
            >
              <ACInput
                ref={loginInputRef}
                value={formState[item.key]}
                placeholder={t(item.placeholder)}
                isClick={item.isClick}
                needClear={item.needClear}
                inputType={item.inputType}
                needCountry={item.needCountry}
                maxLength={item.maxLength}
                isAllow
                noChinese
                codeSend={startVerify}
                onlyNumber={item.onlyNumber}
                updateValue={setFormState}
              />
            </Form.Item>
          ))}
        </Form>
        {/* 记住账密 */}
        {loginModalType === 'PWLogin' && (
          <div className={'rember-account'}>
            <div className={'rember-check'} onClick={() => setRemberLog(!remberLog)}>
              <div className={cn('check-img', remberLog && 'active')}></div>
              <span>{t('user.authDialog.rememberAcc')}</span>
            </div>
            <span onClick={handForgot}>{t('user.authDialog.forgotPS')}</span>
          </div>
        )}
        {/* 按钮提交 */}
        <div className={cn('auth-btn-submit', btnDisable && 'btn-active')} onClick={() => loginIn()}>
          {t('user.authDialog.dLogin')}
        </div>
        {isBigScreen && (
          <p className={'to-register ab-postion'}>
            {t('user.authDialog.toRegist1')}
            <span className={'blue-color'} onClick={() => openRegister(true)}>
              {t('user.authDialog.toRegist2')}
            </span>
          </p>
        )}

        {/* 滑动验证模块 */}
        {showSliderCaptchaPopup && (
          <SliderCaptcha
            use={2}
            fullTxt={t('user.uLogin')}
            loginName={'qc' + formState.userName}
            callback={() => captchaCallback}
            close={() => setShowSliderCaptchaPopup(false)}
          />
        )}

        {/* 2FA丢失弹窗提示  */}
        <Lost2faPopup updateValue={(val) => setLostVisibility(val)} visible={lostVisibility} />

        {/* 2FA验证 */}
        <Verify2fa
          updateValue={(val) => setVerifyVisibility(val)}
          visible={verifyVisibility}
          toLogin={continueLogin}
        />

        {/* 邮箱验证 */}
        {showVerifyCodePopup && (
          <ValidDataPopup
            isUseToLogin
            purpose={2}
            loginName={formState.userName}
            validData={validData}
            verify={finishVerify}
          />
        )}

        {/* 账号锁定弹窗 */}
        <LockedDialog visible={lockVisibility} />
      </div>
    </>
  )
}

export default memo(Login)
