import { useState, useEffect, useRef } from 'react'

export default function useCountDown(num = 120, startText: string, endText: string) {
  const timerID = useRef<any>(null)
  const [time, setTime] = useState<number>(0)
  const [flag, setFlag] = useState<boolean>(true)
  const [textRef, setTextRef] = useState('')

  useEffect(() => {
    setTextRef(startText)
  }, [])

  useEffect(() => {
    function setTiming(newTime) {
      if (newTime < 1) {
        clearInterval(timerID.current)
        setFlag(true)
        timerID.current = null
        setTextRef(startText)
      } else {
        setFlag(false)
        setTextRef(`${time}s ${endText}`)
      }
    }
    setTiming(time)

    if (time) {
      timerID.current = setInterval(() => {
        setTime((time) => time - 1)
      }, 1000)
    }

    return () => {
      clearInterval(timerID.current)
      timerID.current = null
    }
  }, [time])

  const start = () => {
    if (!flag) return
    setTime(num)
  }

  return {
    textRef,
    flag,
    start
  }
}
