import { useMemo, memo } from 'react'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/lib/locales/client'
import { useAppStore } from '@/stores/app'
import siteLinks from '@/config/site_links'
import Config from '@/constants/followUs'
import LangsText from '@/constants/catalog'
import { jumpURL } from '@/lib/jumpURL'
import { Lng } from '@/lib/locales/i18n'
import { usePathname, useParams } from 'next/navigation'
import styles from '../_common_css/footerLinks.module.scss'
import { useShallow } from 'zustand/react/shallow'

const FooterLinks = () => {
  const { t } = useTranslation()
  const { serviceCMSHaveData, serviceIsShow, language } = useAppStore(
    useShallow((state) => ({
      language: state.language,
      serviceCMSHaveData: state.serviceCMSHaveData,
      serviceIsShow: state.serviceIsShow
    }))
  )
  // const FollowUsObj = useMemo(() => Config.FollowUs, [])
  const linksPath = useMemo(() => Object.values(siteLinks), [])
  const dataList = useMemo(() => LangsText.links, [])
  const pathName = usePathname()
  const { lng }: { lng: Lng } = useParams()
  const onOpenChat = () => {
    window.Intercom('show')
  }

  const getJaUrl = (path: any) => {
    // console.log(language === 'ja-JP', path)
    if (lng === 'ja' && path && path.indexOf('/ja') === -1 && path.indexOf('http') === -1) {
      return '/ja' + path
    }
    return path
  }

  //  待排查是否有用
  // useEffect(() => {
  //   if (language === 'en-US') {
  //     const tempDataList = [...dataList]
  //     tempDataList.forEach((item: any) => {
  //       if (item.title === 'Support') {
  //         if (item.children.every((val: any) => val.link !== '/language')) {
  //           item.children.push({ title: 'クイーンカジノ日本語版', link: '/language' })
  //         }
  //       }
  //     })
  //     setDataList(tempDataList)
  //   }
  // }, [])

  return (
    <>
      {dataList.map((item, key) => (
        <div key={key} className={cn(styles[language], styles['links-group'])}>
          <h3>{item.title}</h3>
          <ul>
            {item.children.map((unit, index) => (
              <li key={'cd' + index} className={cn(pathName === linksPath[key][index] && 'active')}>
                <a
                  onClick={(event) => {
                    event.preventDefault()
                    jumpURL(linksPath[key][index])
                  }}
                  href={getJaUrl(linksPath[key][index])}
                  className={cn('list', unit.ischild && '_active')}
                >
                  {unit.title}
                  {linksPath[key][index] === '/contact' && !serviceIsShow && serviceCMSHaveData && (
                    <span
                      className={'chat'}
                      onClick={(event) => {
                        event.stopPropagation()
                        onOpenChat()
                      }}
                    >
                      {t('common.service')}
                    </span>
                  )}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <article className={cn(styles[language], styles['footer-social'], styles['links-group'])}>
        <h3>{t('common.footer.followUS')}</h3>
        <div>
          {Config.FollowUs.map((item, key) => (
            <a target="_blank" key={key} href={getJaUrl(item.path)} title={item.name}>
              {/* {item.name.toLowerCase()} */}
              {item.name && (
                <img src={`/static/images/footer/${item.name.toLowerCase()}_pc.svg`} alt={item.name} />
              )}
            </a>
          ))}

          <div className={cn('spcoal-btn-list', language)}>
            <div onClick={() => jumpURL('https://blog.queen-casino.com/')} className={'spcoal-btn'}>
              <img src="/static/images/footer/blog-button.png" alt="" />
              {t('common.footer.blog')}
            </div>
            <div onClick={() => jumpURL('/videoList')} className={'spcoal-btn'}>
              <img className={'img1'} src="/static/images/footer/video.png" alt="" />
              {t('common.footer.streams')}
            </div>
            <div onClick={() => jumpURL('https://queen-casino.info/')} className={'spcoal-btn'}>
              <img src="/static/images/footer/news-button.png" alt="" />
              {t('common.footer.news')}
            </div>
          </div>
        </div>
      </article>
    </>
  )
}
export default memo(FooterLinks)
