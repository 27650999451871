/* eslint-disable no-invalid-regexp */
/* eslint-disable no-useless-escape */
export default {
  validtorHandle: (value: any, bool: boolean, text: string) => {
    if (!value || bool) {
      return Promise.reject(text)
    } else {
      return Promise.resolve()
    }
  },
  code: (value: any) => {
    return value.length < 6
  },
  username: (value: any) => {
    return /^[a-zA-Z0-9]{4,18}$/.test(value)
  },
  usernameOnlyRegister: (value: string) => {
    return /^(?=.*[a-zA-Z])[a-zA-Z0-9]{6,12}$/.test(value)
  },
  email(value: string) {
    return /^([A-Za-z0-9_\-\.]{2,100})\@([A-Za-z0-9_\-\.]{2,50})\.([A-Za-z]{2,4})$/.test(value)
  },
  newEmail(value: string) {
    return /^([A-Za-z0-9_\-\.]{2,100})\@([A-Za-z0-9_\-\.]{2,50})\.([A-Za-z]{2,4})$/.test(value)
  },
  password(value: any) {
    return /^[a-zA-Z0-9\!\@\#\$\%\^\&\、\￥\*\(\)\-\_\+\=\?\/\.\>\,\<\\]{6,32}$/.test(value)
  },
  name(value: string) {
    if (value.trim().length === 0 || value.trim().length < 2) {
      return false
    }
    const pHiragana = "[\\u3041-\\u3096\\u309D-\\u309F]|\\uD82C\\uDC01|\\uD83C\\uDE00"
    const pKatakana =
      "[\\u30A1-\\u30FA\\u30FD-\\u30FF\\u31F0-\\u31FF\\u32D0-\\u32FE\\u3300-\\u3357\\uFF66-\\uFF6F\\uFF71-\\uFF9D]|\\uD82C\\uDC00"
    const pHan =
      "[\\u2E80-\\u2E99\\u2E9B-\\u2EF3\\u2F00-\\u2FD5\\u3005\\u3007\\u3021-\\u3029\\u3038-\\u303B\\u3400-\\u4DB5\\u4E00-\\u9FD5\\uF900-\\uFA6D\\uFA70-\\uFAD9]|[\\uD840-\\uD868\\uD86A-\\uD86C\\uD86F-\\uD872][\\uDC00-\\uDFFF]|\\uD869[\\uDC00-\\uDED6\\uDF00-\\uDFFF]|\\uD86D[\\uDC00-\\uDF34\\uDF40-\\uDFFF]|\\uD86E[\\uDC00-\\uDC1D\\uDC20-\\uDFFF]|\\uD873[\\uDC00-\\uDEA1]|\\uD87E[\\uDC00-\\uDE1D]"
    const rx = new RegExp("^(" + pHiragana + "|" + pKatakana + "|" + pHan + ")+$|^[A-z\\s]+$")
    return rx.test(value)
  },
  phone(value: string) {
    // return /^(070|080|090)\s{1}\d{4}\s{1}\d{4}$/.test(value)
    return /^[0-9]{6,20}$/.test(value)
  },
  usernameOrEmail(value: string) {
    const [usernameReg, emailReg] = [
      /^[a-zA-Z][a-zA-Z0-9]{3,19}$/,
      /^([A-Za-z0-9_\-\.]{2,14})\@([A-Za-z0-9_\-\.]{2,14})\.([A-Za-z]{2,4})$/
    ]
    return usernameReg.test(value) || emailReg.test(value)
  },
  onlyEmail(value: string) {
    const [emailReg] = [/^([A-Za-z0-9_\-\.]{2,30})\@([A-Za-z0-9_\-\.]{2,14})\.([A-Za-z]{2,15})$/]
    return emailReg.test(value)
  },
  // 无效号码，属于网络运营商
  interReg(val: string) {
    if (!val) return
    return (
      val.startsWith("162") ||
      val.startsWith("165") ||
      val.startsWith("167") ||
      val.startsWith("170") ||
      val.startsWith("171")
    )
  },
  // 无效号码，属于物联网号段
  thingsReg(val: string) {
    if (!val) return
    return (
      val.startsWith("1400") ||
      val.startsWith("1410") ||
      val.startsWith("1440") ||
      val.startsWith("146") ||
      val.startsWith("148")
    )
  },
  // 大陆号段
  phoneRegular(value: string) {
    const [phoneReg] = [
      /^(13[0-3|5-9]\d{8}|134[0-8]\d{7}|14[579]\d{8}|15[0-3|5-9]\d{8}|17\d{9}|18[0-9]\d{8}|19[1-3|5-9]\d{8}|16\d{9})$/
    ]
    return phoneReg.test(value)
  },
  specialSymbol() {
    return /[`~!@#_$%^&*()=|{}':;',\\[\\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？+-]/g
  }
}
