'use client'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from '@/lib/locales/client'
import { cn } from '@/lib/utils'
import { useRouter } from 'next/navigation'
import { useAuthStore } from '@/stores/authControl'
import { useAppStore } from '@/stores/app'
// import { useUser } from '@/store/user'
// import Forms from './model/data-register'
import { px2rem } from '@/hooks/useRem'
/* com */
import BaseDialog from '@/components/base/baseDialog'
import Login from './_components/login'
import Register from './_components/register'
import LeftModel from './_components/leftModel'
// import SupportThird from '../_components/supportThird'
import { useShallow } from 'zustand/react/shallow'
import styles from './_common_css/page.module.scss'

export default function AuthDesktopLayout() {
  const { t } = useTranslation()
  // const isBigScreen = useAppStore((state) => state.isBigScreen)
  const { closeModal, logRegVisible, logRegType, openRegister, openLogin } = useAuthStore(
    useShallow((state) => ({
      closeModal: state.closeModal,
      logRegVisible: state.logRegVisible,
      logRegType: state.logRegType,
      openRegister: state.openRegister,
      openLogin: state.openLogin
    }))
  )
  // const { setWebToken, updateUserInfo, setSeonSession, userInfo, fingerPrintID } = useUserStore()
  const router = useRouter()
  const lineTimer = useRef(null)
  // const lineTimer = useState(null)
  // useEffect(() => {
  // props.updateValue(props.modelValue)
  // }, [props.modelValue])
  const handlClose = () => {
    closeModal()
    clearInterval(lineTimer.current)
    lineTimer.current = null
  }

  const dialogWidth = useMemo(() => {
    return px2rem(792, true)
  }, [])

  // const isFullScreen = useMemo(() => {
  //   return !isBigScreen
  // }, [])

  return (
    <>
      <BaseDialog visible={logRegVisible} width={dialogWidth}>
        <div className={styles['user-auth-model']} style={{ width: dialogWidth }}>
          <LeftModel />
          <div className={cn('right-auth', logRegType)}>
            {/* 待观察 */}
            {/* {!(!isBigScreen && logRegType === 'register') && ( */}
            <div className={'righ-top'}>
              <span
                className={cn(logRegType === 'register' && 'top-active', 'top-sign')}
                onClick={() => openRegister(true)}
              >
                {t('user.authDialog.dReg')}
              </span>
              <span className={cn(logRegType === 'login' && 'top-active', 'top-sign')} onClick={openLogin}>
                {t('user.authDialog.dLogin')}
              </span>
            </div>
            {/* )} */}
            {logRegType === 'login' ? <Login /> : <Register />}
          </div>

          {logRegType === 'register' ? (
            <img
              src="/static/images/saveAccount/close.svg"
              alt=""
              onClick={handlClose}
              className={'auth-close'}
            />
          ) : (
            <img
              src="/static/images/saveAccount/close-grey.png"
              onClick={handlClose}
              className={'auth-close'}
            />
          )}
        </div>
      </BaseDialog>
    </>
  )
}
