/* 登录menu列表 */
export const loginTypeList = () => {
  const list = [
    { label: 'user.authDialog.logPW', type: 'PWLogin' },
    { label: 'user.authDialog.logCode', type: 'CodeLogin' }
  ]
  return list
}

//   emailReg: [{ required: true, validator: emailReg, trigger: ['blur'] }],
//   codeNum: [{ required: true, validator: codeNum, trigger: ['blur', 'change'] }],
//   userName: [{ required: true, validator: checkUserName, trigger: ['change'] }],
//   phoneReg: [{ required: true, validator: phoneReg, trigger: ['change'] }],
//   password: [{ required: true, validator: password, trigger: ['change'] }]

export default {
  LoginForms: {
    PWLogin: [
      {
        key: 'userName',
        name: 'userName',
        title: 'user.authDialog.userNamePlac',
        minLength: 4,
        maxLength: 320,
        require: [{ require: true }],
        placeholder: 'user.authDialog.userNamePlac',
        inputType: '' // 输入类型
      },
      {
        key: 'password',
        name: 'password',
        title: 'user.authDialog.authPassword',
        minLength: 6,
        maxLength: 32,
        require: [{ require: true }],
        placeholder: 'user.authDialog.authPassword',
        inputType: 'password' // 输入类型
      }
    ],
    CodeLogin: [
      {
        key: 'userName',
        name: 'namePhoneEmail',
        title: 'user.authDialog.codePlace',
        minLength: 4,
        maxLength: 320,
        needCountry: true,
        require: [{ require: true }],
        placeholder: 'user.authDialog.codePlace',
        inputType: 'number' // 输入类型
      },
      {
        key: 'verifyCode',
        name: 'codeNum',
        title: 'user.authDialog.authCodePlace',
        maxLength: 6,
        require: [{ require: true }],
        isClick: true,
        onlyNumber: true,
        needClear: false,
        placeholder: 'user.authDialog.authCodePlace',
        inputType: 'number' // 输入类型
      }
    ]
  }
}
