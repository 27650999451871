import { useState, useEffect, memo } from 'react'
import SupportThird from '../../_components/supportThird'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { useAuthStore } from '@/stores/authControl'
import { useAppStore } from '@/stores/app'
import { jumpURL } from '@/lib/jumpURL'
import Config from '@/config/base'
import styles from '../_common_css/leftModel.module.scss'
import { useShallow } from 'zustand/react/shallow'

const LeftModel = () => {
  const { closeModal, logRegVisible } = useAuthStore(
    useShallow((state) => ({
      closeModal: state.closeModal,
      logRegVisible: state.logRegVisible
    }))
  )
  const updateCmsTemplate = useAppStore((state) => state.updateCmsTemplate)
  /* data--start */
  let [bannerList, setBannerList] = useState([])
  /* data--end */
  useEffect(() => {
    getBanner()
  }, [])

  useEffect(() => {
    // 待处理，有新旧值问题
    // (val, oldV) => {
    //   if (!bannerList.value.length && val && val !== oldV) getBanner()
    // }
  }, [logRegVisible])

  const swiperHandle = (item: any) => {
    if (item.templateAction) {
      closeModal()
      jumpURL(item.templateAction)
    }
  }
  /* banner */
  const getBanner = () => {
    updateCmsTemplate({
      themeCode: Config.themeCode,
      moduleCodeList: ['logRegBanner']
    }).then((data: any) => {
      setBannerList(data)
    })
  }
  return (
    <>
      <div className={styles['left-auth']}>
        <div className="swiper-box">
          <Swiper className="d-swiper" loop modules={[Pagination]} pagination={{ clickable: true }}>
            {bannerList.map((item, key) => (
              <SwiperSlide key={key}>
                <img
                  onClick={() => swiperHandle(item)}
                  src={item.mediaLibrary[0].fullFilePath}
                  alt=""
                  className="swiper-img"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* 第三方登录 */}
        <SupportThird />
      </div>
    </>
  )
}

export default memo(LeftModel)
