'use client'
import { useMemo, useRef, ReactNode, FC, useEffect, memo, useState, useLayoutEffect } from 'react'
import { createCache, extractStyle, StyleProvider } from '@ant-design/cssinjs'
// import { createRoot } from 'react-dom/client';
import { useServerInsertedHTML } from 'next/navigation'
import { ConfigProvider, App } from 'antd'
import { usePathname } from 'next/navigation'
// import ThemeProvider from './ThemeProvider'
import jaJP from 'antd/lib/locale/ja_JP'
import enUS from 'antd/lib/locale/en_US'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/authControl'
import { useShallow } from 'zustand/react/shallow'
import { antdTheme, color } from '@/assets/styles/theme/red'
import Config from '@/config/base'
import { getUrlParameter } from '@/lib/utils'
import Ws from '@/hooks/ws'
import 'dayjs/locale/en'
dayjs.locale('en')
import dayjs from 'dayjs'
import { useRem } from '@/hooks/useRem'
import { trackFristDeposit } from '@/hooks/useTrack'
import { Decrypt } from '@/hooks/encrypt'
// import { default as dynamicFunction } from 'next/dynamic'
// *api
import { cmsElementsInfo } from '@/api/base'
import { getExchangeRate } from '@/api/wallet'
import { logApi } from '@/api/auth'
import { agentAccessRecord } from '@/api/agent'
// *com
import RegSuccess from '@/app/[lng]/(auth)/_components/registerSuccess'
import GlobalLoading from '@/components/base/globalLoading'
import UserDialog from '@/app/[lng]/(auth)'
import AntdNotice from '@/components/base/antdNotice'
import GlobalMesg from '@/components/base/globalMessage'
import { useUserStore } from '@/stores/user'
import { useGameStore } from '@/stores/game'

type IProps = {
  children: ReactNode
  lang: string
}

// let websocket  // 待处理
const ComponentsRegistry: FC<IProps> = ({ children, lang }) => {
  const cache = useMemo(() => createCache(), [])
  const isServerInserted = useRef(false)
  const pathName = usePathname()
  const rawPath = pathName.replace(/en|ja(\/)?/g, '')
  // const metadata = { ...pathMetaData[path] }
  // const routes = Object.keys(pathMetaData || {}) // 获取定义的路由 ["/", "account", ...]
  // console.log('meta头', routes)
  const {
    mainTheme,
    isBigScreen,
    language,
    globalLoading,
    globalPublickKey,
    commonConfig,
    setIsBigScreen,
    acccesslimit,
    SET_LANGUAGE,
    queryCommonConfig,
    updateCmsTemplate,
    SET_MEMEBER_DETAIL,
    SET_MEMEBER_DIALOG
  } = useAppStore(
    useShallow((state) => ({
      mainTheme: state.mainTheme,
      isBigScreen: state.isBigScreen,
      language: state.language,
      globalLoading: state.globalLoading,
      globalPublickKey: state.globalPublickKey,
      commonConfig: state.commonConfig,
      setIsBigScreen: state.setIsBigScreen,
      acccesslimit: state.acccesslimit,
      SET_LANGUAGE: state.SET_LANGUAGE,
      queryCommonConfig: state.queryCommonConfig,
      SET_MEMEBER_DETAIL: state.SET_MEMEBER_DETAIL,
      updateCmsTemplate: state.updateCmsTemplate,
      SET_MEMEBER_DIALOG: state.SET_MEMEBER_DIALOG
    }))
  )
  const {
    isLogin,
    currency,
    getBonusTaskList,
    getVipInfo,
    getSignDetail,
    getMissionTagsList,
    getDepositActivity,
    updateJpyToUsd,
    SET_CURRENCY_RATE,
    updatePageVisible,
    updateUserInfo,
    setWebToken
  } = useUserStore(
    useShallow((state) => ({
      isLogin: state.isLogin,
      currency: state.currency,
      getBonusTaskList: state.getBonusTaskList,
      getVipInfo: state.getVipInfo,
      getSignDetail: state.getSignDetail,
      getMissionTagsList: state.getMissionTagsList,
      getDepositActivity: state.getDepositActivity,
      SET_CURRENCY_RATE: state.SET_CURRENCY_RATE,
      updateJpyToUsd: state.updateJpyToUsd,
      updatePageVisible: state.updatePageVisible,
      updateUserInfo: state.updateUserInfo,
      setWebToken: state.setWebToken
    }))
  )
  const { regSucInfo, logRegVisible, openRegister, openLogin } = useAuthStore(
    useShallow((state) => ({
      regSucInfo: state.regSucInfo,
      logRegVisible: state.logRegVisible,
      openRegister: state.openRegister,
      openLogin: state.openLogin
    }))
  )
  const { getFavoriteGameList, getMemberDetail, queryWebGameProvider } = useGameStore(
    useShallow((state) => ({
      getFavoriteGameList: state.getFavoriteGameList,
      getMemberDetail: state.getMemberDetail,
      queryWebGameProvider: state.queryWebGameProvider
    }))
  )
  useServerInsertedHTML(() => {
    // 避免 css 重复插入
    if (isServerInserted.current) return
    isServerInserted.current = true
    return <style id="antd" dangerouslySetInnerHTML={{ __html: extractStyle(cache, true) }} />
  })

  const antdLocale = useMemo(() => {
    return lang === 'ja' ? jaJP : enUS
  }, [])

  // 初始化
  useEffect(() => {
    // 更新多语言相关
    SET_LANGUAGE(lang === 'en' ? 'en-US' : 'ja-JP') // 兼容旧语言状态
    acccesslimit() // 访问限制 放这里会多次渲染， 考虑移动到其它页面
    window.addEventListener('resize', setIsBigScreenHandle, false)
    // - 待观察
    // websocket = new Ws()
    // websocket.init()
    // 待处理 超时
    // queryWebGameProvider()
    if (isLogin) {
      updateUserInfo()
    }
    if (globalPublickKey) {
      onGetCMSData()
    }
    // 销毁监听事件
    return () => {
      window.removeEventListener('resize', setIsBigScreenHandle)
    }
  }, [])

  // 计算像素大小
  useEffect(() => {
    useRem(isBigScreen)
  }, [isBigScreen])

  // 初始化hooks
  const setIsBigScreenHandle = () => {
    setIsBigScreen(window.innerWidth > 999)
  }

  // 样式主题相关
  // useEffect(() => {
  //   changeTheme()
  // }, [mainTheme, isBigScreen])

  // const changeTheme = () => {
  //   if (mainTheme === 'red') {
  //     Object.keys(color).forEach((key: any) => {
  //       const colorData = color as any
  //       const value = colorData[key] as string
  //       window.document.documentElement.style.setProperty(`--${key}`, value)
  //     })
  //   }
  //   window.document.body.setAttribute('main-theme', mainTheme)
  //   window.document.body.setAttribute('main-theme-equipment', isBigScreen ? 'pc' : 'h5')
  //   window.document.body.setAttribute('main-lang', lang || 'en')
  //   // window.document.body.setAttribute('main-lang', cookie.i18n === 'en-US' ? 'en' : 'ja')
  // }

  // 原项目入口 脚本

  // 爆奖弹窗分享链接
  const shareDialogFnc = async () => {
    const { zmkm = '' } = getUrlParameter(window.location.search)
    if (!zmkm) return
    const obj = Decrypt(zmkm, Config.cryptoKey)
    SET_MEMEBER_DETAIL({
      type: 'detail',
      data: JSON.parse(obj)
    })
    getMemberDetail(() => {
      SET_MEMEBER_DIALOG(true)
    })
  }

  const updateBodyClass = (addClass, removeClass) => {
    removeClass ? window.document.body.classList.remove(removeClass) : ''
    addClass ? window.document.body.classList.add(addClass) : ''
  }
  useEffect(() => {
    updateBodyClass(rawPath, '')
    // updateBodyClass(_this.$route.name, "")
  }, [])

  // 待观察 nextTick
  useLayoutEffect(() => {
    let link = window.location.href
    const { agent = '', invite = '', freelogin = '', freename = '' } = getUrlParameter(link)
    //无料站自动登录
    if (freelogin && freename && !isLogin) {
      logApi({
        grantType: 'web_password',
        merchantCode: commonConfig.merchantCode,
        password: freelogin,
        loginName: freename,
        loginType: 3
      }).then((res) => {
        setWebToken(res.data)
        updateUserInfo(res.data.loginName)
      })
    }
    if ((agent || invite) && !link.includes('google') && !link.includes('line')) {
      const code = agent || invite
      if (code) {
        agentAccessRecord({ referralCode: code })
      }
      localStorage.setItem('agent-info-local', code)
    }
  }, [])

  const regInfoUpdate = (data) => {
    /*注册成功埋点 */
    try {
      window.dataLayer.push(data)
    } catch (error) {
      console.log(error, '埋点错误-三方注册')
    }
  }

  // 判断登录状态 去获取商户信息
  useEffect(() => {
    queryCommonConfig()
    if (isLogin) {
      getBonusTaskList()
      getFavoriteGameList()
      trackFristDeposit()
      getVipInfo()
      getSignDetail()
      getMissionTagsList()
      getCurrencyFn(isLogin ? currency?.currency : 'BTC')
      getDepositActivity()
      // websocket && websocket.send({ type: "connection_refresh" })  // 待处理
    }
  }, [isLogin])

  function getCurrencyFn(sourceCurrency, targetCurrency = 'USD') {
    let currency = sourceCurrency
    if (currency !== 'JPY') {
      currency += ';JPY'
    }
    getExchangeRate({
      tradeType: '01',
      targetCurrency,
      sourceCurrency: currency,
      isFindByCache: 0
    }).then((res) => {
      const { data } = res.data || {}
      if (res.success && data && data.length > 0) {
        data.forEach((item) => {
          if (item.sourceCurrency === sourceCurrency && item.targetCurrency === 'USD') {
            SET_CURRENCY_RATE(item)
          }
          if (item.sourceCurrency === 'JPY' && item.targetCurrency === 'USD') {
            updateJpyToUsd(item.realRate)
          }
        })
      }
    })
  }

  // // 路由拦截 非游戏路由清空游戏进厅数据 恢复非游戏状态
  // _this.$router.beforeEach((to, from, next) => {
  //   if (AppGlob.accessType === 2 && to.path !== "/403") {
  //     next("/403")
  //   } else if (to.path === "/slotGame" || to.path === "/sports-bet" || to.path === "/pachinko-game") {
  //     next()
  //   } else {
  //     if (store.isOpenGame) {
  //       next()
  //     } else {
  //       store.SET_GAME_DATA([])
  //       next()
  //     }
  //   }
  // })

  useEffect(() => {
    if (globalPublickKey) {
      onGetCmsElementsInfo(rawPath) // 路由 待处理
      // onGetCmsElementsInfo(_this.$route)
      onGetCMSData()
    }
  }, [globalPublickKey])

  // 待观察路由
  useEffect(() => {
    // (val, oldVal) => {  // 新旧值 待处理
    if (globalPublickKey) {
      onGetCmsElementsInfo(rawPath) // 注意这里 没有原值 的path
      // onGetCmsElementsInfo(val)
    }

    if (rawPath === '/register') {
      // if (val.path === "/register") {
      if (!isLogin) {
        openRegister()
      }
    } else if (rawPath === '/login') {
      // } else if (val.path === "/login") {
      if (!isLogin) {
        openLogin()
      }
    }
    if (window.timerRakeback) clearInterval(window.timerRakeback)
    // updateBodyClass(val?.name, oldVal?.name) // 是否为代理连接 --- 待处理
  }, [rawPath])

  // watch(
  //   () => _this.$route,
  //   (val, oldVal) => {
  //     if (AppGlob.globalPublickKey) {
  //       onGetCmsElementsInfo(val)
  //     }

  //     if (val.path === "/register") {
  //       if (!userStore.isLogin) {
  //         AuthStore.openRegister()
  //       }
  //     } else if (val.path === "/login") {
  //       if (!userStore.isLogin) {
  //         AuthStore.openLogin()
  //       }
  //     }
  //     if (window.timerRakeback) clearInterval(window.timerRakeback)
  //     updateBodyClass(val?.name, oldVal?.name) // 是否为代理连接
  //   },
  //   {
  //     immediate: true
  //   }
  // )

  type SEO = {
    title: string
    canonicalUrl: string
    keywords: string
    robots: string
    remark: string
  }
  // --- 待处理
  function setPageSeoFn(data?: SEO) {
    const title =
      language === 'ja-JP'
        ? 'QueenCasino クイーンカジノ | 日本No.1オンラインカジノ'
        : 'Queen Casino | Japan No.1 Online Casino'

    document.title = data?.title || title
    const querySelect: any = document.querySelector // 重新引用 去掉类型
    querySelect('link[rel="canonical"]').href = data?.canonicalUrl || ''
    getMeta('property', 'og:url', data?.canonicalUrl || '')
    querySelect('meta[name="keywords"]').content = data?.keywords || ''
    querySelect('meta[name="robots"]').content = data?.robots || ''
    querySelect('meta[property="og:title"]').content = data?.title || 'QueenCasino クイーンカジノ'
    const description =
      data?.remark ||
      'クイーンカジノは、アジア市場知名度ナンバーワンの"本格オンラインカジノサイト"です。定番のカジノゲームからオンラインパチンコ・パチスロまでオンラインで気軽に楽しめます。'

    function getMeta(name, value, description, attr = 'content', tagName = 'meta') {
      const head = document.querySelector('head')
      let descriptionDom = document.querySelector(`${tagName}[${name}="${value}"]`)

      if (!descriptionDom) {
        descriptionDom = document.createElement(tagName)
        descriptionDom.setAttribute(name, value)
        if (head) {
          const firstChild = head.firstChild
          head.insertBefore(descriptionDom, firstChild)
        }
      }
      descriptionDom.setAttribute(attr, description)
    }
    getMeta('name', 'description', description)
    getMeta('property', 'og:description', description)
  }

  const onGetCmsElementsInfo = (val: any = {}) => {
    if (val.href === undefined) return
    cmsElementsInfo({
      url: val.href
    }).then((res) => {
      try {
        const data = res?.data
        setPageSeoFn(data || {})
      } catch (e) {
        setPageSeoFn()
      }
    })
  }

  // // 全局CMS数据获取：客服入口
  const [homeTournamentData, setHomeTournamentData] = useState([])
  const [customerServiceData, setCustomerServiceData] = useState(null)
  const onGetCMSData = () => {
    updateCmsTemplate({
      moduleCodeList: ['homeTournament', 'customerService'],
      themeCode: Config.themeCode
    }).then((data) => {
      setHomeTournamentData(data.filter((item) => item.moduleCode === 'homeTournament'))
      setCustomerServiceData(data.filter((item) => item.moduleCode === 'customerService'))
      console.log(homeTournamentData, '>>>>>PC>>>>>')
    })
  }

  // ---  待处理
  // const pageReload = () => {
  //   updatePageVisible(false)
  // }

  // useLayoutEffect(() => {
  //   updatePageVisible(true)
  // }, [])

  // provide("RELOAD", {
  //   pageReload
  // })

  // const shadowRoot = someEle.attachShadow({ mode: 'open' });
  // const container = document.createElement('div');
  // shadowRoot.appendChild(container);
  // const root = createRoot(container);

  return (
    // layer
    <StyleProvider cache={cache} layer>
      <ConfigProvider
        locale={antdLocale}
        theme={antdTheme}
        // getPopupContainer={() => shadowRootContainer} // important
        //   getPopupContainer={node => {
        //   if (node) {
        //     return node.parentNode;
        //   }
        //   return document.body;
        // }}
      >
        {/* <ThemeProvider> */}
        <App>
          {globalLoading && <GlobalLoading />}
          {regSucInfo.flag && <RegSuccess />}
          {logRegVisible && <UserDialog />}
          {/* // let websocket  */}
          {children}
          {/* 弹窗 */}
          <AntdNotice />
          <GlobalMesg />
        </App>
        {/* </ThemeProvider> */}
      </ConfigProvider>
    </StyleProvider>
  )
}

export default memo(ComponentsRegistry)
