/**
 * 未避免国际化语言多文件配置有遗漏，统一在此配置；
 * 且配置同一数组内可能造成阅读障碍，so... 以key为标识区分单独模块以便阅读
 * 防止修改链接只改底部链接，导致网站地图链接不正确，提取出来单独设置
 * */
const siteLinks = {
//   "/live-casino?gameType=baccarat","/live-casino?gameType=roulette","/live-casino?gameType=black","/slots?gameType=slotsFeature"
  game: ["/pachinko?gameType=pachinko","/live-casino","/slots","/fish","/fb"], // 对应游戏页
  about: [
    "/static/about",
    "/static/termsAndConditions",
    "/static/generalTermsOfUse",
    "/static/sportRules",
    "/sitePolicies", // site policies 新页面待开发
    "/bonusRules",
  ],
  support: [
    "/help/guide",
    // "https://kui-pachi777.queen-casino.com/",
    "/help/deposit_method",
    "/help/about_withdrawal",
    "/faq",
    "/contact",
    "/sitemap",
    "https://onlinegaming-affiliate.com/",
    "/language"
  ],
  terms: [
  ],
  promo: ["/promo"] // 对应活动页
}

export default siteLinks
