'use client'
import { useEffect, useRef, useState, useMemo } from 'react'
import { useSetState, useRequest } from 'ahooks'
import { useTranslation } from '@/lib/locales/client'
import { useAuthStore } from '@/stores/authControl'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import Forms, { regTypeList } from '../../_datasets/register'
import parsePhoneNumberFromString from 'libphonenumber-js'
import Validtor from '@/hooks/validtor'
import Config from '@/config/base'
import { jumpURL } from '@/lib/jumpURL'
import { playerCreateAccount, sendCodeByPhone } from '@/api/account'
import 'swiper/css'
import 'swiper/css/pagination'
import { cn } from '@/lib/utils'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Form } from 'antd'
import styles from '../_common_css/register.module.scss'
import { message as AntdMessage } from '@/components/base/antdNotice'
import { webpHandle } from '@/hooks/aboutWebp'
import { useShallow } from 'zustand/react/shallow'
/**com */
import ACInput from '@/components/base/acInput'
import SliderCaptcha from '@/components/common/sliderCaptcha'

export default function Register() {
  const { t } = useTranslation()
  const { countryCurInfo, limitLogin, updateLimitLoginDialog, updateCmsTemplate } = useAppStore(
    useShallow((state) => ({
      countryCurInfo: state.countryCurInfo,
      limitLogin: state.limitLogin,
      updateLimitLoginDialog: state.updateLimitLoginDialog,
      updateCmsTemplate: state.updateCmsTemplate
    }))
  )
  const { setThirdRmCode, setWebToken, updateUserInfo } = useUserStore(
    useShallow((state) => ({
      setThirdRmCode: state.setThirdRmCode,
      setWebToken: state.setWebToken,
      updateUserInfo: state.updateUserInfo
    }))
  )
  const { closeModal, regSucInfo } = useAuthStore(
    useShallow((state) => ({
      closeModal: state.closeModal,
      regSucInfo: state.regSucInfo
    }))
  )
  // const route = useRoute()
  let [formState, setFormState] = useSetState({
    ...Forms.RegisterForms,
    ThirdSupport: Forms.ThirdSupport,
    emailReg: '',
    phoneReg: '',
    userName: '',
    password: '',
    code: '',
    rmCode: ''
  })
  const [form] = Form.useForm()
  const [hasRmCode, setHasRmCode] = useState(false)
  const [registerMenuList, setRegisterMenuList] = useState([])
  const [slideShowFlag, setSlideShowFlag] = useState(false)
  const [registerModalType, setRegisterModalType] = useState('emailReg')
  const [checkedReg, setCheckedReg] = useState(true)
  const [bannerList, setBannerList] = useState([])
  const [verifyParams, setVerifyParams] = useSetState({
    captchaType: ''
  })
  const formRef = useRef(null)
  const [nameRepeat, setNameRepeat] = useState(false)
  const [emailRepeat, setEmailRepeat] = useState(false)
  const acInputRef = useRef(null)

  useEffect(() => {
    const regType = regTypeList()
    setRegisterMenuList(regType)
  }, [])

  // 增加验证
  // useEffect(() => {
  //   form.validateFields(['nickname']);
  // }, [checkNick, form]);

  useEffect(() => {
    const code = localStorage.getItem('agent-info-local')
    if (code) {
      setFormState({ rmCode: code })
      setHasRmCode(true)
    } else {
      setHasRmCode(false)
    }
    // 读取注册的顶部图片 开始
    getBanner()
  }, [])

  const getBanner = async () => {
    const data = await updateCmsTemplate({
      themeCode: Config.themeCode,
      moduleCodeList: ['logRegBanner']
    })
    console.log('data', data)
    setBannerList(data)
  }

  /* 注册发送验证码 */
  const startVerify = () => {
    console.log('dadaf', formState, acInputRef)
    if (!formState.phoneReg) return
    sendCodeByPhone({
      purpose: 1,
      phonePrefix: '00' + countryCurInfo.code,
      phone: formState.phoneReg
    }).then((res: any) => {
      if (res.success) {
        AntdMessage.success(t('authDialog.sendSuc'))
        acInputRef.current.startCount()
      }
    })
  }

  const handleClick = (e) => {
    if (e.target.className === 'le_fh') return
    setCheckedReg(!checkedReg)
  }

  /* 表单校验 */
  let emailReg = async (_rule, value) => {
    if (!value) {
      return Promise.reject(t('common.validtor.emailEpty'))
    }
    if (!Validtor.email(value)) {
      return Promise.reject(t('common.validtor.emailError'))
    }
    if (emailRepeat) {
      return Promise.reject(t('user.lostPop.emailRepeat'))
    }
    return Promise.resolve()
  }

  let phoneReg = async (_rule, value) => {
    if (!value) {
      return Promise.reject(t('common.validtor.phoneEpty'))
    }
    let _short = countryCurInfo.countryCode || 'JP'
    let _phVerify = parsePhoneNumberFromString(value, _short)
    if (_short === 'CN' && !Validtor.phoneRegular(value)) {
      return Promise.reject(t('common.validtor.phoneVerify'))
    }
    if (!!_phVerify && !_phVerify.isValid()) {
      return Promise.reject(t('common.validtor.phoneVerify'))
    }

    return Promise.resolve()
  }
  let password = async (_rule, value) => {
    if (!value) {
      return Promise.reject(t('common.validtor.passwordEpty'))
    }
    if (!Validtor.password(value)) {
      return Promise.reject(t('common.validtor.passwordError'))
    }
    return Promise.resolve()
  }

  let checkUserName = async (_rule, value) => {
    if (!value) {
      return Promise.reject(t('common.validtor.usenameEpty'))
    }
    if (!Validtor.username(value)) {
      return Promise.reject(t('common.validtor.nameErr'))
    }
    if (nameRepeat) {
      return Promise.reject(t('user.lostPop.nameRepeat'))
    }
    return Promise.resolve()
  }
  let codeNum = async (_rule, value) => {
    let _code = formState.code
    if (!_code || _code.length < 6) {
      return Promise.reject(t('common.validtor.codeError'))
    }
    return Promise.resolve()
  }

  const btnDisable = useMemo(() => {
    const { emailReg, phoneReg, userName, password, code } = formState
    let flag
    if (registerModalType === 'emailReg' && emailReg && userName && password) {
      flag = Validtor.email(emailReg) && Validtor.username(userName) && Validtor.password(password)
    } else if (phoneReg && code && code.length === 6) {
      let _short = countryCurInfo.countryCode || 'JP'
      let _phVerify = parsePhoneNumberFromString(phoneReg, _short)
      flag = !!_phVerify && _phVerify.isValid()
    }
    return flag && checkedReg
  }, [])

  useEffect(() => {
    if (nameRepeat) {
      setNameRepeat(false)
    }
  }, [formState.userName])

  useEffect(() => {
    //需求，输入框里有rmcode 第三方注册也要获取到
    if (formState.rmCode) {
      setThirdRmCode(formState.rmCode)
    }
  }, [formState.rmCode])

  useEffect(() => {
    if (emailRepeat) {
      setEmailRepeat(false)
    }
  }, [formState.emailReg])

  const rules = {
    emailReg: [{ required: true, validator: emailReg, trigger: ['blur'] }],
    codeNum: [{ required: true, validator: codeNum, trigger: ['blur', 'change'] }],
    userName: [{ required: true, validator: checkUserName, trigger: ['change'] }],
    phoneReg: [{ required: true, validator: phoneReg, trigger: ['change'] }],
    password: [{ required: true, validator: password, trigger: ['change'] }]
  }

  const hasAgent = (index) => {
    return false
  }

  const register = async () => {
    if (limitLogin) {
      updateLimitLoginDialog(true)
      return
    }
    if (!checkedReg) {
      AntdMessage.warning(t('user.termsChecked'))
      return
    }
    if (!btnDisable) return
    try {
      // 如果未验证滑块
      if (!verifyParams?.captchaType) {
        // slideShowFlag.value = true
        setSlideShowFlag(true)
        return
      }
      const _status = registerModalType === 'emailReg'
      const _address = _status ? formState.emailReg : formState.phoneReg
      // const affiliateId = route.query['affiliate_id'] || ''  // 待处理
      const btag = window.sessionStorage.getItem('btag')
      const stag = window.sessionStorage.getItem('stag')

      let params
      if (_status) {
        params = {
          ...verifyParams,
          password: formState.password,
          loginName: 'qc' + formState.userName.toLowerCase(),
          referCode: formState.rmCode?.toLowerCase(),
          email: _address,
          btag: '',
          affiliateId: ''
        }
      } else {
        //手机
        params = {
          ...verifyParams,
          affiliateId: '',
          btag: '',
          mobileCode: formState.code,
          mobileNo: formState.phoneReg,
          phonePrefix: '00' + countryCurInfo.code,
          referCode: formState.rmCode?.toLowerCase()
        }
      }
      const res: any = await playerCreateAccount(params)
      if (res.success) {
        if (_status) {
          setWebToken(res.data.tokenInfo)
          await updateUserInfo(res.data.loginName, 1)
          closeModal()
          window.dataLayer.push({
            UsernameID: res.data.loginName,
            SocialChannelID: '',
            event: 'register.success'
          })
        } else {
          //手机
          setWebToken(res.data.tokenInfo)
          closeModal()
          regSucInfo.flag = true
        }
      }
      window.sessionStorage.setItem('inviteCode', '') // 使用session缓存inviteCode，关闭页面再进入后清空
    } catch (error) {
      // 登录名已存在
      if (Number(error.code == 100010)) {
        setNameRepeat(true)
        formRef.current.validateFields(['userName'])
      }

      if (Number(error.code === 100919)) {
        setEmailRepeat(true)
        formRef.current.validateFields(['emailReg'])
      }
    }
    setVerifyParams({})
  }

  const chooseType = (item) => {
    setRegisterModalType(item.type)
    formRef.current?.resetFields()
    setFormState({ password: '', userName: '' })
  }

  const captchaCallback = (params) => {
    setSlideShowFlag(false)
    setVerifyParams(params)
    register()
  }

  const swiperHandle = (item) => {
    // console.log('item', item)
    if (item.templateAction) {
      closeModal()
      jumpURL(item.templateAction)
    }
  }

  const formStateChange = (e) => {
    console.log('eeee', e)
  }

  return (
    <>
      <article className={cn(styles['register-page'], styles['h5'])}>
        <div className={'swiper-box'}>
          <Swiper className={'d-swiper'} loop={bannerList && bannerList.length && bannerList.length > 1}>
            {bannerList.map((item, key) => (
              <SwiperSlide key={key}>
                {item.mediaLibrary.length > 0 && (
                  <img
                    onClick={() => swiperHandle(item)}
                    src={webpHandle(item.mediaLibrary[0].fullWebpFilePath, item.mediaLibrary[0].fullFilePath)}
                    alt=""
                    className={'swiper-img'}
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* 菜单 */}
        <div className={'menu-list'}>
          {registerMenuList.map((item, key) => (
            <span
              key={key}
              className={cn(registerModalType == item.type && 'active-menu')}
              onClick={() => chooseType(item)}
            >
              {t(item.label)}
            </span>
          ))}
        </div>
        {/* 表单 */}
        <div className={'content'}>
          <Form ref={formRef} form={form} validateTrigger="onBlur" layout="vertical">
            {Forms.RegisterForms[registerModalType].map((item, key) => (
              //  label={t(item.title)}
              <Form.Item key={item.key} name={item.name} rules={item.require}>
                <ACInput
                  ref={acInputRef}
                  value={formState[item.key]}
                  placeholder={t(item.placeholder)}
                  isAllow
                  maxLength={item.maxLength}
                  inputType={item.inputType}
                  needCountry={item.needCountry}
                  needClear={item.needClear}
                  showPhoneTips={item.showPhoneTips}
                  autoComplete={item.autocomplete}
                  readonly={hasAgent(key)}
                  noChinese
                  onlyNumber={item.onlyNumber}
                  needEmail={item.needEmail}
                  codeSend={startVerify}
                  isClick={item.isClick}
                  hideContact={item.hideContact}
                  disabled={item.key === 'rmCode' && hasRmCode}
                  onChange={formStateChange}
                  // closeEmail={form.validateFields(['emailReg'])}
                  updateValue={setFormState}
                  prefix={item.key === 'userName' && <div className={'qc-prefix'}>QC</div>}
                ></ACInput>
              </Form.Item>
            ))}
          </Form>

          {/* terms */}
          <div className={'terms-option'} onClick={(e) => handleClick(e)}>
            {checkedReg ? <div className={cn('check-img', 'active')} /> : <div className={'check-img'} />}
            <div
              className={'terms-tips'}
              // dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('user.authDialog.termsTips')) }}
              dangerouslySetInnerHTML={{ __html: t('user.authDialog.termsTips') }}
              // dangerouslySetInnerHTML={{ __html: t.raw('user.authDialog.termsTips') }}
            />
          </div>

          {/* 按钮提交 */}
          <div
            // , isBigScreen && 'pc-reg'
            className={cn('auth-btn-submit', btnDisable && 'btn-active')}
            onClick={register}
          >
            {t('user.authDialog.dReg')}
          </div>
        </div>

        {/* 滑动验证模块 */}
        {slideShowFlag && (
          <SliderCaptcha
            use={1}
            fullTxt={t('user.tips')}
            loginName={'qc' + formState.userName}
            callback={() => captchaCallback}
            close={() => setSlideShowFlag(false)}
          />
        )}
      </article>
    </>
  )
}
