// 所有语言等，配置在该文件内
const list = {
  links: [
    {
      title: 'Games',
      children: [
        {
          title: 'Pachinko'
        },
        {
          title: 'Live Casino'
        },
        {
          title: 'Slots'
        },
        {
          title: 'Bounty Fisher'
        },
        {
          isTitle: true,
          title: 'Sport'
        }
      ]
    },
    {
      title: 'About',
      children: [
        {
          title: 'About Queen Casino'
        },
        {
          title: 'Terms & Conditions'
        },
        {
          title: 'Campaign Term of Use'
        },
        {
          title: 'Sports Betting Rules'
        },
        {
          title: 'Site Policies'
        },
        {
          title: 'Bonus and Quest Rules'
        }
      ]
    },
    {
      title: 'Info',
      children: [
        {
          title: 'Guide',
          link: '/help/guide'
        },
        // {
        //   title: "Pachinko Beginner's Guide",
        //   link: "https://kui-pachi777.queen-casino.com/",
        //   Unshow: true
        // },
        {
          title: 'About Deposit',
          link: '/help/deposit_method'
        },
        {
          title: 'About Withdrawal',
          link: '/help/deposit_method'
        },
        {
          title: 'FAQ',
          link: '/faq'
        },
        {
          title: 'Contact Us',
          link: '/contact'
        },
        {
          title: 'Sitemap',
          link: '/sitemap'
        },
        {
          title: 'Affiliate',
          link: 'https://onlinegaming-affiliate.com/'
        }
      ]
    }
  ],
  H5Links: [
    {
      title: 'Games',
      content: [
        // children: [
        {
          title: 'Pachinko',
          link: '/pachinko?gameType=pachinko'
        },
        {
          title: 'Live Casino',
          link: '/live-casino'
          // content: [
          //   {
          //     title: "Baccarat",
          //     link: "/live-casino?gameType=baccarat"
          //   },
          //   {
          //     title: "Roulette",
          //     link: "/live-casino?gameType=roulette"
          //   },
          //   {
          //     title: "Black Jack",
          //     link: "/live-casino?gameType=black"
          //   }
          // ]
        },
        {
          title: 'Slots',
          link: '/slots'
          // content: [
          //   {
          //     title: "Popular games",
          //     link: "/slots?gameType=slotsPopuler"
          //   },
          //   // {
          //   //   title: "Feature Buy-in",
          //   //   link: "/slots?gameType=slotsFeature"
          //   // },
          // ]
        },
        {
          title: 'Bounty Fisher',
          link: '/fish'
        },
        {
          title: 'Sport',
          link: '/fb?gameType=sport'
        }
      ]
    },
    {
      title: 'About',
      content: [
        {
          title: 'About Queen Casino',
          link: '/static/about'
        },
        {
          title: 'Terms & Conditions',
          link: '/static/termsAndConditions'
        },
        {
          title: 'Campaign Term of Use',
          link: '/static/generalTermsOfUse'
        },
        {
          title: 'Sports Betting Rules',
          link: '/static/sportRules'
        },
        {
          title: 'Site Policies', // 新页面待开发
          link: '/sitePolicies'
        },
        {
          title: 'Bonus and Quest Rules',
          link: '/bonusRules'
        }
      ]
    },
    {
      title: 'Info',
      content: [
        {
          title: 'Guide',
          link: '/help/guide'
        },
        // {
        //   title: "Pachinko Beginner's Guide",
        //   link: "https://kui-pachi777.queen-casino.com/"
        // },
        {
          title: 'About Deposit',
          link: '/help/about-deposit-withdrawal'
        },
        {
          title: 'About Withdrawal',
          link: '/help/about_withdrawal'
        },
        {
          title: 'FAQ',
          link: '/faq'
        },
        {
          title: 'Contact Us',
          link: '/contact'
        },
        {
          title: 'Sitemap',
          link: '/sitemap'
        },
        {
          title: 'Affiliate',
          link: 'https://onlinegaming-affiliate.com/'
        }
      ]
    }
  ]
}

export default list
