'use client'
import { memo } from 'react'
import { useAppStore } from '@/stores/app'
/** com */
import Desktop from './desktop'
import Mobile from './mobile'

const AuthLayout = () => {
  const isBigScreen = useAppStore((state) => state.isBigScreen)
  return <>{isBigScreen ? <Desktop /> : <Mobile />}</>
}

export default memo(AuthLayout)
