export const color = {
  // 原项目
  main: '#CC004A',
  secondary: '#16C0D5',
  'color-1': '#FC005B',
  'color-2': '#8F00FF',
  'color-3': '#FF7A00',
  'color-4': '#FF0000',
  'color-5': '#00BF9E',
  'color-6': '#EEA180',
  'color-7': '#A66532',
  'main-width': '1000px',
  'html-font-size': '19.2px',
  'main-line': 'linear-gradient(90deg, #D9004E 0%, #75002A 100%)',
  'secondary-line-1': 'linear-gradient(180deg, #EEA180 0%, #9E5849 100%)',
  'secondary-line-2': 'linear-gradient(180deg, #00C0FC 0%, #00628C 100%)',
  'secondary-line-3': 'linear-gradient(180deg, #7E00FC 0%, #28005B 100%)'
}

// 以下是antd主题使用, 上面的主题样式会一比一转成变量的形式给antd使用
export const antdTheme = {
  token: {
    // 原项目迁移变量
    colorPrimary: '#52c41a',
    baseFont: '#bdbdbd',
    baseBg: '#fff',
    ftRed: '#e70016',
    footerFont: '#888888',
    colorRed: '#e74c3c',
    colorBlack: '#262626',
    themeColor: '#CC004A', // 主题高亮色
    // baseFontRed: "#f44336",
    baseHeightWidth: '95px',
    baseBodyMainWidth: '1280px',
    baseBodyContentWidth: '1200px',
    mobileHeaderHeight: '50px', // H5顶部高度
    footerHeight: '60px',
    acFooterMaxWidth: '1420px', // 页面内容最大宽度暂参考原本项目尺寸1420；
    isBigScreen: '768px',
    acAccountSliderWidth: '272px', // 我的账户侧边导航栏宽度
    referFriendColor: '#fc005b',
    acPrupleBg: 'linear-gradient(-180deg, #dbf9eb 0%, #bcece2 100%);',
    // 基本色调
    baseLinearGradient: 'linear-gradient(180deg, #1D9BEF 0%, #A5FFEE 108.33%)',
    baseLinearFont: '#003C48',
    baseHeaderBg: '#fff',
    baseH5HeadHeight: '50px',
    baseInfoBg: 'rgba(252, 0, 91, 0.05)',
    baseFontRed: '#FC005B',

    // H5底部色
    footerNavBg: '#fff',
    footerMavFont: '#fff',
    footerLinkFont: 'rgba(0, 0, 0, 0.65)',

    // H5左侧导航栏
    sliderLeftNavBg: '#bdbdbd', // 左侧导航背景
    sliderLeftNavFont: 'rgba(0, 0, 0, 0.85)', // 左侧导航文字颜色
    sliderLeftNavList: '#E7E7E7', // 左侧导航列表颜色

    // 静态说明页面配置- eg: 关于QC, 规则规约，底部内容
    static_index_title_bg: '#fff',
    static_index_dd_font: '#424242', // 静态页次标题背景
    helpHeaderBg: '#fff', // 帮助中心背景图片调整
    helpTableThBg: '#D2D2D2',
    helpTableThFont: 'rgba(0, 0, 0, 0.85)'
  }
}
